import { message } from 'antd';
import moment from "moment";
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { decode, encode, getUserSession } from '../../redux/actions/common';
import { getVersionBusiness, getVersionJWT } from '../../request/index';
import { storeUtil, validate } from '../../utility';
import { images } from '../../utility/const';
import './style.scss';



const Login=(props)=>{
    const [password, setPassword]=useState('')
    const [email, setEmail]=useState('')
    
    useEffect(()=>{
        const res=props.getUserSession()
        if(res.payload.token&&res.payload.userInfo){
            redirect(res.payload.userInfo.business_type)
        }
        // eslint-disable-next-line
    },[])


    const arrayToTree = (list: any[] = [], ancestry = null) => {
      return list
        .filter(item => item.ancestry == ancestry)
        .map(item => ({
          ...item,
          children: arrayToTree(list, `${item.id}`),
        }));
    }


    const redirect=(business_type)=>{
      const resources = storeUtil.get('resources')

      console.log(storeUtil.get("resourcs"))
        let uri=''
        // switch(business_type){
        //     case 'brand': uri='/brandCollections'; break;
    
        //     case 'media': uri='/mediaWorks'; break;
    
        //     case 'agency': uri='/agentBrands?type=allClientBrands'; break;

        //     // no default
        // }
        const matchResources = arrayToTree(resources).filter(r=>!r.top)
        console.log(matchResources)
        if(matchResources[0]){
          uri = matchResources[0].uri
        }
        if(uri){
            props.history.replace(uri)
        }
    }

    const goStart=()=>{
        if(!validate.email(email)){
            return message.warn({
                content:'Please enter a valid email address'
            })
        }
        // if(!validate.password(password)){
        //     return message.warn({
        //         content:'Password must be 6-digit combination of numbers and letters'
        //     })
        // }

        props.encode({email,password})
        .then(res=>{
            props.decode({token:res.token})
            .then(userInfo=>{
                redirect(userInfo.business_type)
            })
        })
    }

    const onChange=(e)=>{
        const target=e.target
        const name=target.name
        const value=target.value
       
        switch(name){
            case 'password': setPassword(value);break;

            case 'email': setEmail(value);break;

            // no default
        }
    }
    const getVersions = () => {
        getVersionBusiness().then((result) => {
            localStorage.setItem("versionBUtag", result.tag)
            let time = new Date(Number((result.timestamp + '000')));
            let timeVersionBUConvert = moment(time).format('DDMMYYYY');
            localStorage.setItem("versionBUtime", timeVersionBUConvert)
        })
        getVersionJWT().then((result) => {
            localStorage.setItem("versionJWTtag", result.tag)
            let timeVersionJWTConvert = moment(Number(result.timestamp + '000')).format('DDMMYYYY')
            localStorage.setItem("versionJWTtime", timeVersionJWTConvert)
        })
        fetch('/app/version.json')
        .then(response => response.json())
        .then(data => {
            localStorage.setItem("versionDBtag", data.tag)
            let timeVersionDBConvert =  moment(Number(data.timestamp + '000')).format('DDMMYYYY')
            localStorage.setItem("versionDBtime", timeVersionDBConvert)
        })
    }

    useEffect(() => {
        getVersions();
    }, [])

    return (
      <div className="sign-wraper login-wraper">
        <div className="sign-left"></div>

        <div className="sign-right">
          <div className="sign-right">
            <div className="logo-info">
              <div className="logo">
                <img alt="" src={images.logo} />
              </div>
              <div className="company-name">
                <img alt="" src={images.ASB} />
              </div>
            </div>
            <div className="sign-title">Sign In</div>
            <div className="form-wraper">
              <div className="form-input">
                <input
                  className="input-text"
                  autoComplete="off"
                  name="email"
                  type="text"
                  value={email}
                  onChange={onChange}
                  placeholder="Work Email"
                />
                <span className="input-required">*</span>
              </div>
              <div className="form-input">
                <input
                  type="password"
                  autoComplete="off"
                  name="password"
                  value={password}
                  onChange={onChange}
                  placeholder="Password"
                />
              </div>
            </div>
            <div style={{
                width: '100%',
                lineHeight: '30px'
            }}>
              <a href="/app/forgotPassword"> Forgot Password?</a>
            </div>
            <div className="sign-btn" onClick={goStart}>
              {' '}
              Start{' '}
            </div>
          </div>
          <div
            className="--version"
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
            }}
          >
            <div style={{ width: '45%', textAlign: 'center' }}>
              {`Business Version : ` +
                localStorage.getItem('versionBUtag') +
                ' ' +
                localStorage.getItem('versionBUtime')}{' '}
            </div>
            <div style={{ width: '45%', textAlign: 'center' }}>
              {`JWT Version : ` +
                localStorage.getItem('versionJWTtag') +
                ' ' +
                localStorage.getItem('versionJWTtime')}{' '}
            </div>
            <div style={{ width: '100%', textAlign: 'center' }}>
              {`Dashboard Version : ` +
                localStorage.getItem('versionDBtag') +
                ' ' +
                localStorage.getItem('versionDBtime')}{' '}
            </div>
          </div>
        </div>
      </div>
    );
}
  

const mapDispatchToProps={
    encode,
    decode,
    getUserSession
}

export default connect(null, mapDispatchToProps)(Login)
