import { useEffect, useRef } from "react";
    export function useIntervel(callback, delay = 30000) {
      const saveCallback = useRef();
      useEffect(() => {
        saveCallback.current = callback;
      }, [callback]);
    
      useEffect(() => {
        function tick() {
          saveCallback.current();
        }
        if (delay !== null) {
          const id = setInterval(tick, delay);
          return () => {
            clearInterval(id);
          };
        }
      }, [callback, delay]);
    }
    