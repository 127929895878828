import { DeleteOutlined } from '@ant-design/icons'
import { Button, Col, Form, message, Row, Select } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import CustomAnchor from '../../components/CustomAnchor'
import CustomAvatar from '../../components/CustomAvatar'
import FormInput from '../../components/FormInput'
import LayoutSimple from '../../components/Layout/simple'
import listCountry from '../../country'
import { decode, deleteJob, getBusinessProfile, updateBusinessProfile, updateDecode, updateJob, uploadAvatar } from '../../redux/actions/common'
import { storeUtil } from '../../utility'
import './style.scss'


const SelfProfile = (props) => {
    const { common: { userInfo } } = props
    const params = useParams();
    const history = props.history
    const [userProfile, setUserProfile] = useState({
        jobs: []
    })
    const [edited, setEdited] = useState(false)

    const [fixed, setFixed] = useState(false)
    const [form] = Form.useForm();
    const [focused, setFocused] = useState('')
    const extra = {
        email: 'Email show on your Profile page in mini program so user can get in touch. ',
        phone: '',
        country: '',
        city: 'explanation of this seciton, explanation of this seciton.',
        description: 'Your description appears on your Profile and next to your stories. Max 160 characters.',
        homepage: 'explanation of this seciton, explanation of this seciton.',
        weibo: 'explanation of this seciton, explanation of this seciton.',
        xiaohongshu: 'explanation of this seciton, explanation of this seciton.',
        title: 'explanation of this seciton, explanation of this seciton.',
        sub_title: 'explanation of this seciton, explanation of this seciton.',
        contact: 'i.e. +10 0000 0000',
    }

    const getUserProfile = useCallback(() => {
        props.getBusinessProfile(userInfo.business_id)
            .then(result => {
                form.setFieldsValue({ ...result, email: userInfo.email, jobs: result.jobs || [] })
                setUserProfile(result)
            })
            .catch(err => {
                setUserProfile({})
            })
    }, [])



    const onAnchorScroll = () => {
        const fixedTop = document.getElementById('anchors').offsetTop;

        window.onscroll = () => {
            let scrollTop = Math.max(document.body.scrollTop, document.documentElement.scrollTop)
            // console.log(fixedTop,scrollTop)
            //控制元素块A随鼠标滚动固定在顶部
            if ((scrollTop + 55) >= fixedTop && !fixed) {
                let content = document.getElementById('main-content')
                // console.log(content)
                content.scrollTop = 150
                // content.scrollIntoView({block: 'start', behavior: 'smooth'});
                setFixed(true)
            } else if (scrollTop < fixedTop){
                setFixed(false)
            }
        }
    }

    useEffect(() => {
        onAnchorScroll();
        getUserProfile();
    }, [])


    const updateDecode = () => {
        const params = {
            id: userInfo.id,
            data: {
                business_name: userProfile.name
            }
        }
        props.updateDecode(params)
    }

    const saveProfile = async () => {
        const result = await form.validateFields();
        const profile = form.getFieldValue();
        const params = {
          id: userProfile.profile_id,
          business_id: userInfo.business_id,
          data: {
            ...profile,
            logo: userInfo.avatar,
            jobs: profile.jobs.map(({ id, title, contact, description }) => ({
              id,
              title,
              contact,
              description,
            })),
          },
        };
        setEdited(false)
        props.updateBusinessProfile(params).then(() => {
            message.success({ content: "Edit Updated!" })
            userInfo.business_name = profile.name
            storeUtil.set('user', userInfo)
            history.replace(`/selfprofile/${userInfo.business_id}`);
        })
        updateDecode()
    }

    const renderButtons = () => {

        if (edited) {
            return (<div>
                <Button shape="round" style={{ borderColor: '#68C89E', color: '#68C89E', right: 15 }} onClick={saveProfile}>Save</Button>
                <Button type="default" style={{ borderColor: '#595959', color: '#595959', }} shape="round" onClick={() => { setEdited(false) }}>Cancel</Button>
            </div>)
        }


        return (<div style={{ width: '120%' }}>
            <Button shape="round" onClick={() => {
                history.push('/changePassword')
            }} style={{ borderColor: '#595959', color: '#595959', right: 15 }}>Change Password</Button>
            <Button type="default" style={{ borderColor: '#595959', color: '#595959', }} shape="round" onClick={() => {
                setEdited(true)
            }}>Edit My Profile</Button>
        </div>)
    }

    const avatartProps = {
        action: `/id/api/v1/users/upload-avatar`,
        headers: {
            Authorization: storeUtil.get("token") || ""
        },
        data: { business_user_mapping_id: userProfile.business_user_mapping_id },
        defaultImage: userProfile.avatar,
        onChange: (info) => {
            console.log(info)
            if(info.file.status === 'error'){
                message.error({ content: "Upload Error!" })
            }
            
            if(info.file.status === 'done'){
                console.log(userProfile);
                const params = {
                  id: userProfile.profile_id,
                  business_id: userInfo.business_id,
                  data: {
                    logo: info.file.response.avatar,
                  },
                };
                props.updateBusinessProfile(params).then(() => {
                    props.decode({ token: storeUtil.get('token') })
                })
            }   
        }
    }

    return (
      <LayoutSimple style={{ background: "#E2E2E2" }}>
        <div className="body">
          <Form
            form={form}
            layout="vertical"
            noStyle
            initialValues={{ ...userProfile, email: userInfo.email }}
            onValuesChange={(e) => {
              console.log(e);
            }}
          >
            {/* <FormInput required label="Email" placeholder="Input your email" /> */}
            {/* <div className="main-title">
                <h1 className="text">Before you start, please edit your profile</h1>
                <div className="rectangle"></div>
            </div> */}
            <div className="main-properties">
              <div className="photo">
                <CustomAvatar {...avatartProps} />
              </div>
              <div className="properties">
                <div className="nick-name">
                  {edited ? (
                    <Form.Item
                      name="name"
                      rules={[
                        { required: true, message: "Please input your name" },
                      ]}
                    >
                      <FormInput
                        placeholder="Input your name"
                        readOnly={!edited}
                      />
                    </Form.Item>
                  ) : (
                    userProfile.name
                  )}
                </div>
                <div className="follow-group">
                  <span className="label">
                    Following
                    <span className="number">0</span>
                  </span>
                  <span className="label">
                    Followers
                    <span className="number">0</span>
                  </span>
                </div>
              </div>
              <div className="btn">{renderButtons()}</div>
            </div>
            <div className={`main-anchor ${fixed ? "fixed" : ""}`} id="anchors">
              <CustomAnchor
                links={[
                  { id: "basic", title: "Basic Info" },
                  { id: "social", title: "Social Profiles" },
                  { id: "recruitment", title: "Recruitment" },
                ]}
              />
            </div>
            <div className={`main-content`} id="main-content">
              <div className="basic item-relative" id="basic">
                <div className="title">Basic Info</div>
                <div className="form-items">
                  <Row gutter={[16, 48]}>
                    <Col span={10}>
                      <Form.Item
                        name="email"
                        label="Email"
                        rules={[
                          {
                            required: true,
                            message: "Please input your email",
                          },
                        ]}
                        extra={`${focused === "email" ? extra["email"] : ""}`}
                      >
                        <FormInput
                          placeholder="Input your email"
                          readOnly={true}
                          onFocus={() => {
                            edited && setFocused("email");
                            console.log(extra);
                          }}
                          onBlur={() => {
                            setFocused("");
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={10} offset={2}>
                      <Form.Item
                        name="phone"
                        label="Phone Number"
                        rules={[
                          {
                            required: true,
                            message: "Please input your phone number",
                          },
                        ]}
                        extra={`${focused === "phone" ? extra["phone"] : ""}`}
                      >
                        <FormInput
                          placeholder="Input your phone number"
                          readOnly={!edited}
                          onFocus={() => {
                            edited && setFocused("phone");
                            console.log(extra);
                          }}
                          onBlur={() => {
                            setFocused("");
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[16, 48]}>
                    <Col span={10}>
                      <Form.Item
                        name="country"
                        label="Country"
                        rules={[
                          {
                            required: true,
                            message: "Please select your country",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Select your country"
                          disabled={!edited}
                          showSearch
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {listCountry.map((option, index) => (
                            <Select.Option key={index} {...option}>
                              {option.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={10} offset={2}>
                      <Form.Item
                        name="city"
                        label="City"
                        rules={[
                          { required: true, message: "Please input your city" },
                        ]}
                        extra={`${focused === "city" ? extra["city"] : ""}`}
                      >
                        <FormInput
                          placeholder="Input your city"
                          readOnly={!edited}
                          onFocus={() => {
                            edited && setFocused("city");
                            console.log(extra);
                          }}
                          onBlur={() => {
                            setFocused("");
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[16, 48]}>
                    <Col span={22}>
                      <Form.Item
                        name="description1"
                        label="Description"
                        rules={[
                          {
                            required: true,
                            message: "Please input your description",
                          },
                        ]}
                        extra={`${
                          focused === "description" ? extra["description"] : ""
                        }`}
                      >
                        <FormInput
                          placeholder="Input your description"
                          readOnly={!edited}
                          onFocus={() => {
                            edited && setFocused("description");
                            console.log(extra);
                          }}
                          onBlur={() => {
                            setFocused("");
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="social item-relative" id="social">
                <div className="title">Social Profiles</div>
                <div className="form-items">
                  <Row gutter={[16, 48]}>
                    <Col span={10}>
                      <Form.Item
                        name="homepage"
                        label="Official Website"
                        extra={`${
                          focused === "homepage" ? extra["homepage"] : ""
                        }`}
                      >
                        <FormInput
                          placeholder="Input your official website"
                          readOnly={!edited}
                          onFocus={() => {
                            edited && setFocused("homepage");
                            console.log(extra);
                          }}
                          onBlur={() => {
                            setFocused("");
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={10} offset={2}>
                      <Form.Item
                        name="weibo"
                        label="Weibo"
                        extra={`${focused === "weibo" ? extra["weibo"] : ""}`}
                      >
                        <FormInput
                          placeholder="Input your weibo"
                          readOnly={!edited}
                          onFocus={() => {
                            edited && setFocused("weibo");
                            console.log(extra);
                          }}
                          onBlur={() => {
                            setFocused("");
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[16, 48]}>
                    <Col span={10}>
                      <Form.Item
                        name="xiaohongshu"
                        label="Xiao Hong Shu"
                        extra={`${
                          focused === "xiaohongshu" ? extra["xiaohongshu"] : ""
                        }`}
                      >
                        <FormInput
                          placeholder="Input your xiao hong shu"
                          readOnly={!edited}
                          onFocus={() => {
                            edited && setFocused("xiaohongshu");
                            console.log(extra);
                          }}
                          onBlur={() => {
                            setFocused("");
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={10} offset={2}>
                      <Form.Item
                        name="official_account"
                        label="Wechat Official Account"
                        extra={`${
                          focused === "official_account"
                            ? extra["official_account"]
                            : ""
                        }`}
                      >
                        <FormInput
                          placeholder="Input your wechat official account"
                          readOnly={!edited}
                          onFocus={() => {
                            edited && setFocused("official_account");
                            console.log(extra);
                          }}
                          onBlur={() => {
                            setFocused("");
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="recruitment item-relative" id="recruitment">
                <div className="title">Recruitment</div>
                <Form.List name="jobs">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(
                        ({ key, name, fieldKey, ...restField }, index) => (
                          <div className="form-items">
                            <Row gutter={[16, 48]}>
                              <Col span={10}>
                                <Form.Item
                                  fieldKey={fieldKey}
                                  name={[name, "title"]}
                                  label="Job Title"
                                  extra={`${
                                    focused === "title" ? extra["title"] : ""
                                  }`}
                                >
                                  <FormInput
                                    placeholder="Input your job title"
                                    readOnly={!edited}
                                    onFocus={() => {
                                      edited && setFocused("title");
                                      console.log(extra);
                                    }}
                                    onBlur={() => {
                                      setFocused("");
                                    }}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={10}>
                                <Form.Item
                                  fieldKey={fieldKey}
                                  name={[name, "sub_title"]}
                                  label="Job Sub Title"
                                  extra={`${
                                    focused === "sub_title"
                                      ? extra["sub_title"]
                                      : ""
                                  }`}
                                >
                                  <FormInput
                                    placeholder="Input your job sub title"
                                    readOnly={!edited}
                                    onFocus={() => {
                                      edited && setFocused("sub_title");
                                      console.log(extra);
                                    }}
                                    onBlur={() => {
                                      setFocused("");
                                    }}
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row gutter={[16, 48]}>
                              <Col span={22}>
                                <Form.Item
                                  fieldKey={fieldKey}
                                  name={[name, "contact"]}
                                  label="Contact Info"
                                  extra={`${
                                    focused === "contact"
                                      ? extra["contact"]
                                      : ""
                                  }`}
                                >
                                  <FormInput
                                    placeholder="Input your contact info"
                                    readOnly={!edited}
                                    onFocus={() => {
                                      edited && setFocused("contact");
                                      console.log(extra);
                                    }}
                                    onBlur={() => {
                                      setFocused("");
                                    }}
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row gutter={[16, 48]}>
                              <Col span={22}>
                                <Form.Item
                                  fieldKey={fieldKey}
                                  name={[name, "description"]}
                                  label="Description"
                                >
                                  <FormInput
                                    type="textarea"
                                    rows={4}
                                    placeholder="A fashion buyer is responsible for the products that the company sells, making fashion buyer jobs quite important for the company and for the brand that they carry. They oversee the development of clothes which are targeted towards a particular market and price range. Depending on how big the company is, there might be one fashion buyer or a team of fashion buyers responsible for the clothes which the company would carry."
                                    readOnly={!edited}
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                            <div className="delete-btn">
                              {edited && (
                                <Button
                                  type="default"
                                  shape="circle"
                                  onClick={() => {
                                    const { jobs = [] } = form.getFieldsValue();
                                    const { id, profile_id } =
                                      jobs[index] || {};
                                    if (id) {
                                      props
                                        .deleteJob({ id, profile_id })
                                        .then((res) => {
                                          remove(name);
                                        });
                                    }
                                    remove(name);
                                  }}
                                  icon={<DeleteOutlined />}
                                  style={{
                                    borderColor: "#595959",
                                    color: "#000000",
                                  }}
                                />
                              )}
                            </div>
                          </div>
                        )
                      )}

                      <Form.Item>
                        {edited && (
                          <a
                            href="javascript:void(0)"
                            onClick={add}
                            style={{
                              borderBottom: "1px solid #000000",
                              fontSize: "14px",
                              color: "#000000",
                            }}
                          >
                            Add position
                          </a>
                        )}
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </div>
            </div>
          </Form>
        </div>
      </LayoutSimple>
    );
}

const mapStateToProps = (state) => {
    return {
        common: state.common
    }
}

const mapActionsToProps = {
    getBusinessProfile,
    uploadAvatar,
    updateBusinessProfile,
    updateDecode,
    decode,
    updateJob,
    deleteJob
}

export default connect(mapStateToProps, mapActionsToProps)(SelfProfile)
