import { message } from 'antd';
import moment from "moment";
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { forgetResetPassword } from '../../redux/actions/common';
import { getVersionBusiness, getVersionJWT } from '../../request/index';
import { validate } from '../../utility';
import { images } from '../../utility/const';
import './style.scss';



const ResetPassword=(props)=>{
    const [password, setPassword]=useState('')
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [resetPasswordToken] = useState(props.match.params.reset_password_token);

    const goStart=()=>{
        console.log(password,passwordConfirmation,resetPasswordToken)
        if (!password || !passwordConfirmation || !resetPasswordToken) {
          return message.warn({
            content: 'Please enter a valid password',
          });
        }

        if(password !== passwordConfirmation){
            return message.warn({
              content: 'Please enter a valid password confirmation',
            });
        }

         if(!validate.password(password)){
            return message.warn({
                content:'Password must be 6-digit combination of letters, numbers or special characters'
            })
        }


        props
          .forgetResetPassword({
            password,
            passwordConfirmation,
            resetPasswordToken,
          })
          .then((res) => {
            message.success({
              content: 'Password reset successfully',
            });
            setTimeout(()=>{
                props.history.replace(`/login`);
            },500)
          });
        // .catch(err=>{
        //     message.error({
        //       content: `${email} not found`,
        //     });
        // });
    }

    const onChange=(e)=>{
        const target=e.target
        const name=target.name
        const value=target.value
       
        switch (name) {
          case 'password':
            setPassword(value);
            break;
          case 'password_confirmation':
            setPasswordConfirmation(value);
            break;

          // no default
        }
    }

    const getVersions = () => {
      getVersionBusiness().then((result) => {
        localStorage.setItem('versionBUtag', result.tag);
        let time = new Date(Number(result.timestamp + '000'));
        let timeVersionBUConvert = moment(time).format('DDMMYYYY');
        localStorage.setItem('versionBUtime', timeVersionBUConvert);
      });
      getVersionJWT().then((result) => {
        localStorage.setItem('versionJWTtag', result.tag);
        let timeVersionJWTConvert = moment(
          Number(result.timestamp + '000')
        ).format('DDMMYYYY');
        localStorage.setItem('versionJWTtime', timeVersionJWTConvert);
      });
      fetch('/app/version.json')
        .then((response) => response.json())
        .then((data) => {
          localStorage.setItem('versionDBtag', data.tag);
          let timeVersionDBConvert = moment(
            Number(data.timestamp + '000')
          ).format('DDMMYYYY');
          localStorage.setItem('versionDBtime', timeVersionDBConvert);
        });
    };

    useEffect(() => {
      getVersions();
    }, []);

    return (
      <div className="sign-wraper login-wraper">
        <div className="sign-left"></div>

        <div className="sign-right">
          <div className="sign-right">
            <div className="logo-info">
              <div className="logo">
                <img alt="" src={images.logo} />
              </div>
              <div className="company-name">
                <img alt="" src={images.ASB} />
              </div>
            </div>
            <div className="sign-title">Reset Password</div>
            <div className="form-wraper">
              <div className="form-input">
                <input
                  className="input-text"
                  autoComplete="off"
                  name="password"
                  type="password"
                  value={password}
                  onChange={onChange}
                  placeholder="Password"
                />
                <span className="input-required">*</span>
              </div>
              <div className="form-input">
                <input
                  className="input-text"
                  autoComplete="off"
                  name="password_confirmation"
                  type="password"
                  value={passwordConfirmation}
                  onChange={onChange}
                  placeholder="Password Confirmation"
                />
                <span className="input-required">*</span>
              </div>
            </div>
            <div className="sign-btn" onClick={goStart}>
              {' '}
              Submit{' '}
            </div>
          </div>
          <div
            className="--version"
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
            }}
          >
            <div style={{ width: '45%', textAlign: 'center' }}>
              {`Business Version : ` +
                localStorage.getItem('versionBUtag') +
                ' ' +
                localStorage.getItem('versionBUtime')}{' '}
            </div>
            <div style={{ width: '45%', textAlign: 'center' }}>
              {`JWT Version : ` +
                localStorage.getItem('versionJWTtag') +
                ' ' +
                localStorage.getItem('versionJWTtime')}{' '}
            </div>
            <div style={{ width: '100%', textAlign: 'center' }}>
              {`Dashboard Version : ` +
                localStorage.getItem('versionDBtag') +
                ' ' +
                localStorage.getItem('versionDBtime')}{' '}
            </div>
          </div>
        </div>
      </div>
    );
}
  

const mapDispatchToProps = {
  forgetResetPassword,
};

export default connect(null, mapDispatchToProps)(ResetPassword);
