import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import InviteButton from '../../components/InviteButton'
import Layout from '../../components/Layout'
import listCountry from '../../country'
import { getAllBrandMedias, getMyBrandMedias } from '../../redux/actions/brand'
import { getAvatar } from '../../redux/actions/common'
import { createFollower } from '../../request'
import { locationJSON } from '../../utility'
import { images } from '../../utility/const'
import './style.scss'


const BrandMediaList = props => {
    const {common:{userInfo}, brand}=props
    const [mediaList, setMediaList]=useState([])
    const history=props.history
    const [mediaType, setMediaType]=useState()
    const [lastId, setLastId]=useState('')
    const [isDataEmpty, setIsDataEmpty]=useState(false)
    const limit = 8

    const getBrandMedias=()=>{
        const router=locationJSON(window.location)
        
        let media_type='allmedia'
        if(router.queryObject.type){
            media_type=router.queryObject.type
        }
        setMediaType(media_type)

        if (media_type === 'allmedia'){
            setMediaList([])
            getAllBrandMedias()
        } else{
            setMediaList([])
            getMyBrandMedias()
        }
    }

    const getAllBrandMedias = () => {
        setIsDataEmpty(false)
        props.getAllBrandMedias(lastId)
            .then(result=>{
                const lengthBrand = result.length;
                setMediaList([...mediaList, ...result])
                if (0 < limit < lengthBrand ) {
                    setIsDataEmpty(true)
                    setLastId(result[lengthBrand - 1].id)
                } else if (lengthBrand === 0) {
                    setIsDataEmpty(false)
                }
            })
            .catch(err=>{
                console.log(err)
                setMediaList([])
            })
    }

    const getMyBrandMedias = () => {
        setIsDataEmpty(false)
        props.getMyBrandMedias(lastId)
        .then(result=>{
            const lengthBrand = result.length;
            setMediaList([...mediaList, ...result])
            if (0 < limit <= lengthBrand ) {
                setIsDataEmpty(true)
                setLastId(result[lengthBrand - 1].id)
            } else if (lengthBrand === 0) {
                setIsDataEmpty(false)
            }
            
        })
        .catch(err=>{
            console.log(err)
            setMediaList([])
        })
    }

    const getCountry = (index) => {
        const parseIndex = parseInt(index)
        if (parseIndex || parseIndex === 0) {
            return listCountry[parseIndex].name
        } else {
            return ''
        }
    }

    useEffect(()=>{
        getBrandMedias()
        
        const listen=history.listen((arg)=>{
            if(arg.pathname==='/brandMedias'){
                getBrandMedias()
            }
        })
        return listen
    },[])

    const loadMoreMedia = () => {
        getBrandMedias();
    }

    const showProfile = (media) => {
        history.push(`/profile/${media.business_user_mapping_id}`)
    }

    const connectMedia= (media) =>{
        createFollower(userInfo.business_type, media.user_business_id, media.id)
        .then(res=>{
            const index = mediaList.findIndex(item => item.id === media.id)
            let dataTemp = [...mediaList]
            if (index !== -1) {
                dataTemp[index].status = 'Pending'
                setMediaList(dataTemp)
            }
        })
    }

    const getMedias = () => {
        return mediaList.map((media)=>(
            <div className="media" key={media.id}>
            <div className="media-avatar" onClick={() => showProfile(media)}>
                <img src={media?.avatar || images.avatar_default}></img>
            </div>
            <div className="media-info">
                <div className="info-main" onClick={() => showProfile(media)}>   
                    <div className="info-name">{media.name}</div>
                    <div className="info-desc">
                    {
                        media.publication ? (
                            <span>{ media.publication } · </span>
                        ) : null
                    }
                    {
                        media.city ? (
                            <span>{ media.city } · </span>
                        ) : null
                    }
                    <span>{ getCountry(media.country) }</span>
                    </div>
                </div>
                <div className="info2">
                    {
                        media.status==='Connected'?(
                            <div className="info-connect connected">
                                Connected
                                <i className="icon-right"></i>
                            </div>
                        ):media.status === 'Pending'?(
                            <div className="info-connect pending">Pending</div>
                        ):(
                            <div className="info-connect" onClick={()=>connectMedia(media)}>Connect</div>
                        )
                    }                                                                                    
                </div>
            </div>
        </div>
        ))
    }

    return <Layout rightButton={<InviteButton type="media">Invite Media</InviteButton>}>
        <div className="brand-medias">
            <div className="info">
                <div className="info-name">
                {mediaType==='allmedia'?'All Media':'My Media'}
                </div>
                <div className="info-desc">{mediaList.length} Media</div>
            </div>
            <div className="medias">
                {
                    getMedias()
                }
            </div>
            {
                isDataEmpty && mediaList.length >= limit ? ( 
                    <div className="button-load-mode">
                        <span onClick={() => loadMoreMedia()}>Unlock More Media</span>
                    </div>
                ) : null
            }
        </div>
    </Layout>
}

const mapStateToProps=(state)=>{
    return {
        common:state.common,
        brand:state.brand
    }
}

const mapActionsToProps={
    getAllBrandMedias,
    getMyBrandMedias,
    createFollower,
    getAvatar,
}

export default connect(mapStateToProps, mapActionsToProps)(BrandMediaList)
