import UploadAdapter from '@ckeditor/ckeditor5-adapter-ckfinder/src/uploadadapter'
import Autoformat from '@ckeditor/ckeditor5-autoformat/src/autoformat'
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold'
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic'
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote'
import CKFinder from '@ckeditor/ckeditor5-ckfinder/src/ckfinder'
import CloudServices from '@ckeditor/ckeditor5-cloud-services/src/cloudservices'
import EasyImage from '@ckeditor/ckeditor5-easy-image/src/easyimage'
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor'
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials'
import Heading from '@ckeditor/ckeditor5-heading/src/heading'
import Image from '@ckeditor/ckeditor5-image/src/image'
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption'
import ImageResizeEditing from '@ckeditor/ckeditor5-image/src/imageresize/imageresizeediting'
import ImageResizeHandles from '@ckeditor/ckeditor5-image/src/imageresize/imageresizehandles'
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle'
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar'
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload'
import Indent from '@ckeditor/ckeditor5-indent/src/indent'
import Link from '@ckeditor/ckeditor5-link/src/link'
import List from '@ckeditor/ckeditor5-list/src/list'
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph'
import PasteFromOffice from '@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import Table from '@ckeditor/ckeditor5-table/src/table'
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar'
import TextTransformation from '@ckeditor/ckeditor5-typing/src/texttransformation'
import { Button, Col, Form, Input, message, Row, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import FormInput from '../../components/FormInput'
import LayoutSimple from '../../components/Layout/simple'
import {
  createJob, getAllBusinesses, showJob,
  updateJob
} from "../../redux/actions/common"
import { storeUtil } from '../../utility'
import MyUploadAdapter from '../Events/components/MyUploadAdapter'
import './style.scss'






ClassicEditor.defaultConfig = {
    plugins: [Essentials,
        UploadAdapter,
        Autoformat,
        Bold,
        Italic,
        BlockQuote,
        CKFinder,
        CloudServices,
        EasyImage,
        Heading,
        Image,
        ImageCaption,
        ImageStyle,
        ImageToolbar,
        ImageUpload,
        Indent,
        Link,
        List,
        Paragraph,
        PasteFromOffice,
        Table,
        TableToolbar,
        TextTransformation,
        ImageResizeEditing,
        ImageResizeHandles],
    toolbar: {
        // eslint-disable-next-line no-sparse-arrays
        items: [
            'undo',
            'redo',
            'heading',
            'fontfamily', 'fontsize', '|',
            'alignment',                                                 // <--- ADDED
            'bold',
            'italic',
            'link', 'strikethrough',
            'bulletedList',
            'numberedList',
            '|',
            'outdent', 'indent',
            '|', 'uploadImage', ,
            'imageResize',
            // 'imageTextAlternative',
            'blockQuote',
            'resizeImage'
        ],
        viewportTopOffset: 30,
        shouldNotGroupWhenFull: true,
    },
    image: {
        toolbar: [
            'imageStyle:inline',
            'imageStyle:block',
            'imageStyle:side',
            '|',
            'toggleImageCaption',
            'autoImage'
        ],

    },
    // This value must be kept in sync with the language defined in webpack.config.js.
    language: 'en',
    height: '500',
    ckfinder: {
        uploadUrl: '/bl/api/v1/event_banners',
        headers: {
            Authorization: storeUtil.get("token") || ""
        },
    },
    enterMode: CKEditor.ENTER_BR,
    shiftEnterMode: CKEditor.ENTER_P

};


const EditJob = (props) => {
    const { common: { userInfo } } = props
    const params = useParams();
    const location = useLocation();
    const history = props.history
    const [job, setJob] = useState({
    })
    const [edited, setEdited] = useState(['new', 'edit'].includes(params.type))

    const [form] = Form.useForm();
    const [businesses, setBusinesses] = useState([])
    useEffect(() => {
        props.getAllBusinesses().then(res => {
            if (userInfo.roles.includes('admin')) {
                setBusinesses(res)
            } else {
                const [first, ...last] = res
                setJob({ ...job, profile_id: first.id, brand_image: first.logo });
            }
        })
        if (location.state) {
            props.showJob(location.state).then(res => {
                form.setFieldsValue({ ...res})
                setJob({...res})
            });
        }
    }, [])

    const saveEvent = async () => {
        await form.validateFields();
        const data = {
          job: {
            ...job,
            ...form.getFieldsValue(),
          },
          id: job.id,
        };

        switch (params.type) {
            case 'new':
                props.createJob(data).then(() => {
                    history.push(`/jobs?type=jobs`);
                    message.success({ content: "Create success job" });
                })
                break;
            case 'edit':
                props.updateJob(data).then(() => {
                    history.push(`/jobs?type=jobs`);
                    message.success({ content: "Create success job" })
                })
                break;
            default:
                break;
        }


    }

    const renderButtons = () => {
        return (
          <div>
            {
              edited && <Button
                shape="round"
                style={{ borderColor: "#68C89E", color: "#68C89E", right: 15 }}
                onClick={saveEvent}
              >
                Save
              </Button>
            }
            <Button
              type="default"
              style={{ borderColor: "#595959", color: "#595959" }}
              shape="round"
              onClick={() => {
                history.push(`/jobs?type=jobs`);
              }}
            >
              Cancel
            </Button>
          </div>
        );
    }

    const readyCK = (editor) => {
        editor.isReadOnly = !edited;
        if (edited) {
            editor.plugins.get("FileRepository").createUploadAdapter = function (loader) {
                return new MyUploadAdapter(loader, '/bl/api/v1/event_banners', {
                    Authorization: storeUtil.get("token") || ""
                });
            };

            editor.editing.view.document.on('enter', (evt, data) => {
                if (data.isSoft) {
                    editor.execute('enter');
                } else {
                    editor.execute('shiftEnter');
                }

                data.preventDefault();
                evt.stop();
                editor.editing.view.scrollToTheSelection();
            }, { priority: 'high' });
        }
    }

    return (
      <LayoutSimple>
        <div className="body">
          <Form
            form={form}
            layout="vertical"
            noStyle
            initialValues={{ ...job }}
          >
            <div className={`main-content`} id="main-content">
              <div className="basic item-relative" id="basic">
                <div className="title">Job Info</div>
                <div className="form-items">
                  <Row gutter={[16, 0]}>
                    <Col span={userInfo.roles.includes("admin") ? 10 : 22}>
                      <Form.Item
                        name="title"
                        label="Job Title"
                        rules={[
                          {
                            required: true,
                            message: "Please input your job title",
                          },
                        ]}
                      >
                        <FormInput
                          placeholder="Input your job title"
                          readOnly={!edited}
                        />
                      </Form.Item>
                    </Col>
                    {userInfo.roles.includes("admin") ? (
                      <Col span={10} offset={2}>
                        <Form.Item
                          name="profile_id"
                          label="Brand"
                          rules={[
                            {
                              required: true,
                              message: "Please select your brand",
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            placeholder="Select your brand"
                            filterOption={(input, option) => {
                              return (
                                option.label
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              );
                            }}
                            disabled={!edited}
                          >
                            {businesses.map((business, index) => (
                              <Select.Option
                                key={index}
                                value={business.profile_id}
                                label={business.name}
                                img={business.logo}
                              >
                                <div className="demo-option-label-item">
                                  <span role="img" aria-label="China">
                                    <img
                                      src={business.logo}
                                      style={{ height: 25 }}
                                    />
                                  </span>
                                  {business.name}
                                </div>
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    ) : null}
                  </Row>
                  <Row gutter={[16, 48]}>
                    <Col span={10}>
                      <Form.Item
                        name="sub_title"
                        label="Sub Title"
                        rules={[
                          {
                            required: true,
                            message: "Please input your sub title",
                          },
                        ]}
                      >
                        <Input
                          placeholder="input your sub title"
                          disabled={!edited}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={10} offset={2}>
                      <Form.Item
                        name="contact"
                        label="Contact"
                        rules={[
                          {
                            required: true,
                            message: "Please input your contact",
                          },
                        ]}
                      >
                        <Input
                          placeholder="input your contact"
                          disabled={!edited}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[16, 48]}>
                    <Col span={22}>
                      <Form.Item
                        name="address"
                        label="Address"
                        rules={[
                          {
                            required: true,
                            message: "Please input your address",
                          },
                        ]}
                      >
                        <Input.TextArea
                          placeholder="input your address"
                          disabled={!edited}
                          rows={2}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[16, 48]}>
                    <Col span={22}>
                      <Form.Item
                        name="description"
                        label="Description"
                        rules={[
                          {
                            required: true,
                            message: "Please input your description",
                          },
                        ]}
                      >
                        {/* <CKEditor
                          editor={ClassicEditor}
                          onReady={(editor) => readyCK(editor)}
                          data={form.getFieldValue("description")}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            form.setFieldsValue({
                              ...form.getFieldsValue(),
                              description: data,
                            });
                          }}
                        /> */}
                        <Input.TextArea
                          placeholder="input your contact"
                          disabled={!edited}
                          rows={8}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[16, 48]}>
                    <Col span={22}>
                      <Form.Item>{renderButtons()}</Form.Item>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </LayoutSimple>
    );
}

const mapStateToProps = (state) => {
    return {
        common: state.common
    }
}

const mapActionsToProps = {
  showJob,
  updateJob,
  createJob,
  getAllBusinesses,
};

export default connect(mapStateToProps, mapActionsToProps)(EditJob);
