import React, { useState, useEffect } from "react"
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom"
import {connect} from 'react-redux'
import {getUserSession} from './redux/actions/common'
import { Modal } from "antd";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import routes from './routes'

// load default style
import './assets/styles/normalize.css'
// load common style
import './assets/styles/common.scss'
// load antd style
import 'antd/dist/antd.css'
// component loadAnimation,NotFount
import LoadAnimation from './components/Loading'
import NotFound from './pages/404'
import Forbidden from './pages/403'


import { storeUtil } from './utility'

const App = (props) => {
  const [done, setDone] = useState(false)
  const parent=props
  useEffect(()=>{
    window.onload=()=>{
      setDone(true)
    }
  },[])  
  console.log('--------------- app render ----------------')

  return (
    <>
      <BrowserRouter basename="/app">
        <Switch>
          {
            [
              ...routes
            .map((router, index) =>(
                <Route
                  key={index}
                  exact={true}
                  path={router.path}
                  render={props => {
                    const resources = storeUtil.get('resources')
                    Object.defineProperty(window, 'history', {
                      value: {...window.history,...props}
                    });
                    const uri = `${props.match.path}${props.location.search}`
                    console.log(uri,resources)
                    if (router.auth) {
                      const res=parent.getUserSession()
                      if (res.payload.token&&res.payload.userInfo) {

                        if(!resources || !resources.map(r=>r.uri).includes(uri)){
                          return <Redirect to={{
                            pathname: '/403',
                            from: props.location
                          }} />
                        }else{
                          console.log(uri,resources)
                          return <router.component {...props} />
                        }
                      } else {
                        return <Redirect to={{
                          pathname: '/login',
                          from: props.location
                        }} />
                      }
                    } else {
                      return <router.component {...props} />
                    }
                  }}
                />
              )),
            <Route path="/403">
              <Forbidden></Forbidden>
            </Route>,
            <Route path="*">
              <NotFound></NotFound>
            </Route>
            ]
          }
        </Switch>
      </BrowserRouter>

      <LoadAnimation done={done} />
    </>
  )
}

const mapDispatchToProps={
  getUserSession,
}

export default connect(null, mapDispatchToProps)(App);