import * as request from '../../request'

export const getAllWorks = id => async (dispatch, getState) => {
    const response = await request.getAllStoryBoards(id)
    await dispatch({
        type: "getAllWorks",
        payload: response
    });
    return response;
}

export const getWorkDetail = id => async (dispatch, getState) => {
   // const state = getState()

    const response = await request.getStoryBoardDetail(id)
    await dispatch({
        type: "getWorkDetail",
        payload: response
    });
    return response;
}

export const getWorkLookDetail = id => async (dispatch, getState) => {
    const response = await request.getStoryBoardLook(id)
    await dispatch({
        type: "getWorkLookDetail",
        payload: response
    });
    return response;
}

export const updateWork = id => async (dispatch, getState) => {
    //const state = getState()

    const response = await request.updateStoryBoard(id)
    await dispatch({
        type: "updateWork",
        payload: response
    });
    return response;
}

export const getAllBrands = params => async (dispatch) => {
    const response = await request.getAllBrands(params)
    await dispatch({
        type: "getAllBrands",
        payload: response
    });
    return response;
}

export const getAllBrandsConnec = params => async (dispatch) => {
    const response = await request.getAllBrandsConnec(params)
    await dispatch({
        type: "getAllBrandsConnec",
        payload: response
    });
    return response;
}

export const getMyBrands = id => async (dispatch, getState) => {
    const response = await request.getMyBrands(id)
    await dispatch({
        type: "getMyBrands",
        payload: response
    });
    return response;
}

export const updateStoryBoardLook = (params) => async (dispatch, getState) => {
    const response = await request.updateStoryBoardLook(params)
    return response;
} 

export const deleteStoryBoard = (id) => async (dispatch, getState) => {
    const response = await request.deleteStoryBoard(id)
    return response;
}
