import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import './style.scss'
import LayoutSimple from '../../components/Layout/simple'
import {createRole,updateRole,showRole,getResources } from '../../redux/actions/common'
import { useParams,useLocation } from 'react-router-dom'
import FormInput from '../../components/FormInput'
import { Button,Form,Row,Col,Select,message } from 'antd'
import TreeTansfer from '../../components/TreeTransfer'

const EditRole = (props) => {
    const params = useParams();
    const location = useLocation();
    const history=props.history
    const [role,setRole] = useState({
    })
    const [edited,setEdited] = useState(['new','edit'].includes(params.type))

    const [form] = Form.useForm();
    const [resources,setResources] = useState([])
    useEffect(() => {
        props.getResources().then(res=>{
            setResources(res.data)
        })
        if(location.state){
            props.showRole(location.state).then(res=>{
                form.setFieldsValue(res)
                setTargetKeys(res.resource_ids)
                setRole(res)
            });
        }
    }, [location.state])

    const [targetKeys, setTargetKeys] = useState([]);
    const onChange = keys => {
        console.log(keys)
        setTargetKeys(keys);
    };

    const saveRole = async () =>{
        await form.validateFields();
        const data = {
            role: {...role,...form.getFieldValue()}
        }

        console.log(data)
        switch(params.type){
            case 'new':
                props.createRole(data).then(() => {
                            history.push(`/roles`);
                            message.success({ content:"Create success role" })
                        })
            break;
            case 'edit':
                console.log(data)
                props.updateRole(data).then(() => {
                            history.push(`/roles`);
                            message.success({ content:"Create success role" })
                        })
            break;
            default:
                console.log('nothing to do')
        }

        
    }

    const renderButtons = () => {
        return  (<div>
                        <Button shape="round" style={{borderColor: '#68C89E',color: '#68C89E',right: 15}} onClick={saveRole}>Save</Button> 
                        <Button type="default" style={{borderColor: '#595959',color: '#595959',}} shape="round" onClick={()=>{
                            if(!edited){
                                history.push(`/roles`);
                            }else{
                                setEdited(false)
                            }
                        }}>Cancel</Button>
                    </div>)
    }

    return <LayoutSimple>

        <div className="banners-body">
            <Form form={form} layout="vertical" noStyle>      
            <div className={`main-content`} id="main-content">
                <div className="basic item-relative" id="basic">
                    <div className="title">Role Info</div>
                    <div className="form-items">
                        <Form.Item name="name" label="Role Name" rules={[{ required: true, message: 'Please input your role name' }]}>
                                    <FormInput placeholder="Input your role name" readOnly={!edited}/>
                                </Form.Item>
                                <Form.Item name="resource_ids" label="Resources"  rules={[{ required: true, message: 'Please select your resources' }]}> 
                                    <TreeTansfer  dataSource={resources} targetKeys={targetKeys} onChange={onChange}/>
                                </Form.Item>
                        <Form.Item>
                                            {renderButtons()}
                                </Form.Item>
                    </div>
                </div>
                
            </div>
    </Form>
        </div>
    </LayoutSimple>
}

const mapStateToProps = (state) => {
    return {
        common: state.common
    }
}

const mapActionsToProps = {
    updateRole,
    createRole,
    showRole,
    getResources
}

export default connect(mapStateToProps, mapActionsToProps)(EditRole)