import React from 'react'
import './style.scss'
import {Select} from 'antd'

const FormSelect = props => {
    const {options, placeholder, onChange, defaultValue, value}=props

    return (
      <div className="my-select">
        {value ? (
          <Select
            value={value}
            onChange={onChange}
            suffixIcon={<div className="icon-dropdown"></div>}
            placeholder={placeholder}
          >
            {options.map((option, index) => (
              <Select.Option key={index} {...option}>
                {option.name}
              </Select.Option>
            ))}
          </Select>
        ) : (
          <Select
            defaultValue={defaultValue}
            onChange={onChange}
            suffixIcon={<div className="icon-dropdown"></div>}
            placeholder={placeholder}
          >
            {options.map((option, index) => (
              <Select.Option key={index} {...option}>
                {option.name}
              </Select.Option>
            ))}
          </Select>
        )}
      </div>
    );
}

export default FormSelect
