/**!
 * http请求统一拦截处理
 */

import { useHistory} from 'react-router-dom'
import axios from 'axios'
import {message} from 'antd'
import {storeUtil} from '../utility'
import store from '../redux/store'

// 创建axios实例
const http = axios.create({
  timeout: 30000 // 请求超时时间
})

// 统一错误处理
const errorDeal=(response)=>{
  if(response&&response.data&&(response.data.errors||response.data.message)){
    let msg=''
    if(response.data.errors){
      msg=response.data.errors instanceof Array?response.data.errors[0]:response.data.errors
    }else{
      msg=response.data.message
    }
    message.error({ content:msg })
  }else{
    message.error({
      content:`Response Error,please try again later.`
    })
  }

  if(response&&response.status==401){
    storeUtil.clear()
    window.location.replace('/app/login')
  }
}

// request拦截器
http.interceptors.request.use(
  config => {
    let state=store.getState()
    config.headers['Authorization']=state.common.token||''
    config.headers['x-resource']= `${window.history.match.path}${window.history.location.search}`

    return config
  },
  error => {
    console.error('request error:', error)
    return Promise.reject()
  }
)

// response 拦截器
http.interceptors.response.use(
  response => {
    const res = response.data
    if (response.status !== 200||response.status !== 201||response.status !== 204) {
      return res
    } else {
      let msg=res.errors||res.message
      if(res.errors){
        message.error({
          content:msg
        })
      }else{
        message.error({
          content:`Bad Request，please try again later.`
        })
      }
      console.error('response data error:', response)
      return Promise.reject()
    }
  },
  error => {
    errorDeal(error.response)
    return Promise.reject()
  }
)

export default http