import { message, Progress, Upload } from 'antd'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import FormSelect from '../../components/FormSelect'
import Modal from '../../components/Modal'
import { getAllSeasons } from '../../redux/actions/brand'
import { createCollection, createStoryBoard, getListPhotographer, getListPublication } from '../../request'
import { images } from '../../utility/const'
import FormSelectSearchPhoto from '../FormSelectSearchPhoto'
import './style.scss'




const UploadFiles = props => {
    // pass props
    const { children, type } = props
    // common props
    const history = useHistory()
    const accept = ['image/png', 'image/jpeg', 'image/jpg']
    const [showModal, setShowModal] = useState(false)
    const [percent, setPercent] = useState(0)
    const [status, setStatus] = useState('init')
    const [filelist, setFilelist] = useState()

    // brand props
    const [years, setYears] = useState([])
    const [seasonsOfYear, setSeasonsOfYear] = useState([])
    const [seasons, setSeasons] = useState([])
    const [currentSeason, setCurrentSeason] = useState()

    // media props
    const [mediaInputFields, setMediaInputFields] = useState({ typeStoryName: '', typePhotographerName: '', publication: '' })
    // const [name, setName] = useState()
    const [mediaSelectedYear, setMediaSelectedYear] = useState()
    const [mediaSelectedMonth, setMediaSelectedMonth] = useState()
    let countTemp = 0
    const [listPhotographer,setListPhotographer] = useState([])
    const [listPublication, setListPublication] = useState([])
    const months = [
        { value: "January", name: "January" },
        { value: "February", name: "February" },
        { value: "March", name: "March" },
        { value: "April", name: "April" },
        { value: "May", name: "May" },
        { value: "June", name: "June" },
        { value: "July", name: "July" },
        { value: "August", name: "August" },
        { value: "September", name: "September" },
        { value: "October", name: "October" },
        { value: "November", name: "November" },
        { value: "December", name: "December" }
    ]
    const [currentMonth, setCurrentMonth] = useState();
    const newMonths = ['01','02','03','04','05','06','07','08','09','10','11','12']

    const mapping = {
      "Fall/Winter": "12",
      "Pre-Fall": "08",
      "Spring/Summer": "05",
      Resort: "01",
    };
    useEffect(() => {
        const currentYear = new Date().getFullYear();
        if (type === 'brand') {
            props.getAllSeasons()
                .then(result => {
                    let seasons = {}
                    result.forEach(item => {
                        if (seasons[item.year]) {
                            seasons[item.year].push({ value: item.id, year: item.year, name: item.name })
                        } else {
                            seasons[item.year] = [{ value: item.id, year: item.year, name: item.name }]
                        }
                    })
                    let years = Object.keys(seasons).map(key => ({ value: key, name: key }))
                    setSeasonsOfYear(seasons[currentYear])
                    setSeasons(seasons)
                    setYears(years)
                })
        } else {
            const years = [
                { value: currentYear - 5, name: (currentYear - 5).toString() },
                { value: currentYear - 4, name: (currentYear - 4).toString() },
                { value: currentYear - 3, name: (currentYear - 3).toString() },
                { value: currentYear - 2, name: (currentYear - 2).toString() },
                { value: currentYear - 1, name: (currentYear - 1).toString() },
                { value: currentYear, name: currentYear.toString() },
                { value: currentYear + 1, name: (currentYear + 1).toString() },
                { value: currentYear + 2, name: (currentYear + 2).toString() },
                { value: currentYear + 3, name: (currentYear + 3).toString() },
                { value: currentYear + 4, name: (currentYear + 4).toString() },
                { value: currentYear + 5, name: (currentYear + 5).toString() },
                { value: currentYear + 6, name: (currentYear + 6).toString() },
                { value: currentYear + 7, name: (currentYear + 7).toString() },
                { value: currentYear + 8, name: (currentYear + 8).toString() },
                { value: currentYear + 9, name: (currentYear + 9).toString() },
                { value: currentYear + 10, name: (currentYear + 10).toString() },
                { value: currentYear + 11, name: (currentYear + 11).toString() },
                { value: currentYear + 12, name: (currentYear + 12).toString() },
                { value: currentYear + 13, name: (currentYear + 13).toString() },
                { value: currentYear + 14, name: (currentYear + 14).toString() },
                { value: currentYear + 15, name: (currentYear + 15).toString() },
                { value: currentYear + 16, name: (currentYear + 16).toString() },
                { value: currentYear + 17, name: (currentYear + 17).toString() },
                { value: currentYear + 18, name: (currentYear + 18).toString() },
                { value: currentYear + 19, name: (currentYear + 19).toString() },
                { value: currentYear + 20, name: (currentYear + 20).toString() }
            ]
            setYears(years)
        }
    }, ['type'])


    const changeBrandProps = (name, value) => {
        if (name === 'year') {
            setSeasonsOfYear(seasons[value])
        } 
        if (name === 'season') {
            setCurrentSeason(value)
        }
        if (name === "month") {
          console.log('month',value)
          setCurrentMonth(value);
        }
    }

    const changeMediaProps = (name, value) => {
        if (name === 'year') {
            setMediaSelectedYear(value)
        } else if (name === 'month'){
            setMediaSelectedMonth(value)
        } else if (name === 'photographerName'){
            mediaInputFields.typePhotographerName = value;
            setMediaInputFields({...mediaInputFields, typePhotographerName:value})
        } else if (name === 'listPublication'){
            mediaInputFields.publication = value;
            setMediaInputFields({...mediaInputFields, publication:value})
        }
    }

    const handleInputChange = e => {      
        const {name, value} = e.target
        setMediaInputFields({...mediaInputFields, [name]: value})
    }

    

    const confirm = () => {
        const formData = new FormData()

        // upload progress
        const updateProgress = (percent) => {
            if (percent < 93) {
                setPercent(percent + 1)
                setTimeout(() => {
                    updateProgress(percent + 1)
                }, 10);
            }
        }


        if (type === 'brand') {
            if (!currentSeason) {
                return message.warn({
                    content: `Please select year and season.`
                })
            }
            if (!filelist) {
                return message.warn({
                    content: `Please select your pictures.`
                })
            }
            updateProgress(percent)

            console.log(currentMonth);
            if(Object.keys(mapping).includes(currentSeason.name)){
              formData.append(
                "v_date",
                `${currentSeason.year}-${mapping[currentSeason.name]}`
              );
            }else{
              formData.append(
                "v_date",
                `${currentSeason.year}-${currentMonth}`
              );
            }
            formData.append('name', `${currentSeason.name} ${currentSeason.year}`)
            formData.append('season_id', currentSeason.value)
            filelist.forEach(file => {
                formData.append('collection_looks[file][]', file);
            });

            createCollection(formData)
                .then(res => {
                    setPercent(100)
                    setStatus('success')
                    clickShowModal(false)
                    history.go(0)
                })
        } else if (type === 'media') {
            if (!mediaInputFields.typeStoryName) {
                return message.warn({
                    content: `Please input Type Story Name.`
                })
            }

            if (!mediaInputFields.typePhotographerName) {
                return message.warn({
                    content: `Please input Type Photographer Name.`
                })
            }

            updateProgress(percent)
            formData.append('name', mediaInputFields.typeStoryName)
            formData.append('publication', mediaInputFields.publication)
            formData.append('year', mediaSelectedYear)
            formData.append('month', mediaSelectedMonth)
            formData.append('type_photographer_name', mediaInputFields.typePhotographerName)
            filelist.forEach(file => {
                formData.append('story_board_looks[file][]', file);
            });

            createStoryBoard(formData)
                .then(res => {
                    setPercent(100)
                    setStatus('success')
                    clickShowModal(false)
                    history.go(0)
                })
        }

    }

    const uploadProps = {
        action: '/',
        accept: accept.join(','),
        disabled: false,
        multiple: true,
        showUploadList: false,
        beforeUpload: (file, filelist) => {
            if(countTemp > filelist.length) {
                countTemp = 1
            } else {
                countTemp = countTemp + 1
            }

            if (countTemp === 1 && filelist.length > 15) {
                message.warn({
                    content:`No more than 15 photos for each time`
                })
                setFilelist([])
                return false
            } else if (!accept.includes(file.type)) {
                message.warn({
                    content: `Only jpeg & png files less than 1MB`
                })
                return false
            } else if (file.size > 1024 * 1024) {
                message.warn({
                    content: `Only jpeg & png files less than 1MB`
                })
                return false
            } else if (countTemp === 1 && filelist.length <= 15) {
                setFilelist(filelist)
                setStatus('selected')
                setPercent(0)
                return false
            }
        }
    }

    const isDisable = () => {

        if (type === 'brand') {
            if (currentSeason && filelist && filelist.length > 0) {
                return false
            }
        }

        if (type === 'media') {
            if (mediaInputFields && mediaInputFields.typeStoryName && mediaInputFields.typePhotographerName && filelist && filelist.length > 0) {
                return false
            }
        }
        return true
    }

    const clickShowModal = (bool) => {
        setShowModal(bool)
        setCurrentSeason()
        setFilelist()
        setPercent(0)
        setStatus('init')
    }

    const onSearchPhoto = (event) => {
        getListPhotographer(event)
        .then(res => {
            let includePub = [];
            for (let i = 0; i < res.length; i++){
                let temp = includePub.includes(res[i])
                if (temp === false){
                    includePub.push(res[i])
                }
            }
            setListPhotographer(includePub )
            const index = res.findIndex((item) => item === event);
            if ((index && event) || (index === 0 && event !== "")) {
                setMediaInputFields({...mediaInputFields, typePhotographerName: event})
            }
        })
    }
    const onSearchPublication = (event) => {
      getListPublication(event).then((res) => {
        let includePub = [];
        for (let i = 0; i < res.length; i++){
            let temp = includePub.includes(res[i])
            if (temp === false){
                includePub.push(res[i])
            }
        }
        setListPublication(includePub);
        const index = res.findIndex((item) => item === event);
        if ((index && event) || (index === 0 && event !== "")) {
          setMediaInputFields({ ...mediaInputFields, publication: event });
        }
      });
    };
    return (
      <>
        <div className="upload-button" onClick={() => clickShowModal(true)}>
          <i className="icon-add"></i>
          {children}
        </div>
        {showModal ? (
          <Modal
            title={type === "brand" ? "Create A Collection" : "Create A Story"}
            onClose={() => clickShowModal(false)}
          >
            <div className="form-wraper">
              {type === "brand" ? (
                <>
                  <div className="form-input">
                    <FormSelect
                      value={currentSeason ? currentSeason.value : ""}
                      placeholder="Season"
                      options={seasonsOfYear}
                      onChange={(value, option) =>
                        changeBrandProps("season", option)
                      }
                    />
                  </div>
                  <div className="form-input">
                    <FormSelect
                      placeholder="Year"
                      options={years}
                      onChange={(value) => changeBrandProps("year", value)}
                    />
                  </div>
                  {currentSeason &&
                  !Object.keys(mapping).includes(currentSeason.name) ? (
                    <div className="form-input">
                      <FormSelect
                        placeholder="month"
                        options={newMonths.map((v) => ({ value: v, label: v }))}
                        onChange={(value) => changeBrandProps("month", value)}
                      />
                    </div>
                  ) : null}
                </>
              ) : (
                <>
                  <div className="form-input">
                    <input
                      className="input-text"
                      type="text"
                      name="typeStoryName"
                      placeholder="Story Name"
                      onChange={handleInputChange}
                    />
                    <span className="input-required">*</span>
                  </div>
                  {
                    <div className="form-input">
                      <FormSelectSearchPhoto
                        placeholder="Publication/Platform"
                        options={listPublication}
                        value={mediaInputFields.publication}
                        onSearch={(event) => onSearchPublication(event)}
                        onChange={(value) =>
                          changeMediaProps("listPublication", value)
                        }
                      ></FormSelectSearchPhoto>
                    </div>
                  }

                  <div className="form-input">
                    <FormSelect
                      placeholder="Year"
                      options={years}
                      onChange={(value) => changeMediaProps("year", value)}
                    />
                  </div>
                  <div className="form-input">
                    <FormSelect
                      placeholder="Month"
                      options={months}
                      onChange={(value) => changeMediaProps("month", value)}
                    />
                  </div>
                  {
                    <div className="form-input">
                      <FormSelectSearchPhoto
                        placeholder="Photographer Name"
                        options={listPhotographer}
                        value={mediaInputFields.typePhotographerName}
                        onSearch={(event) => onSearchPhoto(event)}
                        onChange={(value) =>
                          changeMediaProps("photographerName", value)
                        }
                      ></FormSelectSearchPhoto>
                    </div>
                  }
                </>
              )}

              {status === "success" ? (
                <div className="form-upload">
                  <div className="upload-img">
                    <img src={images.icon_upload_success}></img>
                  </div>
                  <div className="upload-text">Upload Success!</div>
                </div>
              ) : (
                <Upload {...uploadProps}>
                  <div className="form-upload">
                    <div className="upload-img">
                      <Progress
                        type="circle"
                        percent={percent}
                        showInfo={false}
                        strokeLinecap="square"
                        strokeWidth="5"
                        strokeColor="#F58C78"
                        trailColor="transparent"
                      ></Progress>
                      <img src={images.icon_upload_img}></img>
                    </div>
                    <div className="upload-text">
                      Please{" "}
                      <span className="underline"> Browse & Upload </span> your
                      photos here
                      <br />
                      *No more than 15 photos for each time
                      <br />
                      *Only jpeg & png files less than 1MB
                    </div>
                  </div>
                </Upload>
              )}
            </div>
            <div
              className={`upload-modal-btn ${isDisable() ? "disable" : ""}`}
              onClick={confirm}
            >
              Confirm
            </div>
          </Modal>
        ) : (
          ""
        )}
      </>
    );
}


const mapActions = {
    getAllSeasons,
}
export default connect(null, mapActions)(UploadFiles)