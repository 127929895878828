import { message } from 'antd'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import LayoutSimple from '../../components/Layout/simple'
import { changePassword, getAvatar } from '../../redux/actions/common'
import './style.scss'


const ProfileEdit = (props) => {
    const password='';
    const { common: { userInfo } } = props
    const history = useHistory()
    
    let dataProfileTemp = {
        currentPass: '',
        newPass: '',
        confirmPass: '',
    }
    const [dataPassword, setPassword] = useState(dataProfileTemp)


    const handleInputChange = e => {         
        const {name, value} = e.target
        setPassword({...dataPassword, [name]: value})
    }

    const savePasswors = () => {
        const params = {
            currentPassword: dataPassword.currentPass,
            password: dataPassword.newPass
        }
        props.changePassword(params).then(() => {
            history.push("/profile");
            message.success({ content:"Update success profile" })
        })
    }

    useEffect(() => {
    }, [])

    return <LayoutSimple>
        <div className="change-password">
            <span className="title">Change Password</span>
            <div>
                <div className="form-input">
                    <input className="input-text" type="text" placeholder="Current PassWord" name="currentPass" value={dataPassword.currentPass || ''} onChange={handleInputChange}/>
                    <span className="input-required">*</span>
                </div>
                <div className="form-input">
                    <input className="input-text" type="text" placeholder="New PassWord" name="newPass" value={dataPassword.newPass || ''} onChange={handleInputChange}/>
                    <span className="input-required">*</span>
                </div>
                {/* <div className="form-input">
                    <input className="input-text" type="text" placeholder="Confirm PassWord" name="confrimPass" value={dataPassword.confrimPass || ''} onChange={handleInputChange}/>
                    <span className="input-required">*</span>
                </div> */}
            </div>

            <div className="edit-save" onClick={savePasswors}>Save</div>
        </div>
    </LayoutSimple>
}

const mapStateToProps = (state) => {
    return {
        common: state.common
    }
}

const mapActionsToProps = {
    changePassword,
    getAvatar,
}

export default connect(mapStateToProps, mapActionsToProps)(ProfileEdit)