import React, { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import './style.scss'
import { connect } from 'react-redux'
import Layout from '../../components/Layout'
import UploadFiles from '../../components/UploadFiles'
import { getBrandLookDetail, getCollectionDetail, getCollectionDetailSample, getBrandLookDetailSamples, getCollectionDetailClipping,updateCollectionLook } from '../../redux/actions/brand'
import Slider from "react-slick";
import {Form, Input, message} from 'antd'

const BrandLookDetail = (props) => {
    const { common: { userInfo } } = props
    const params = useParams()
    const [lookDetail, setLookDetail] = useState([])
    const [lookDetailFile, setLookDetailFile] = useState([])
    const history = props.history
    const [collectionDetail, setCollectionDetail] = useState([])
    const [looks, setLooks] = useState([])
    const slider = useRef(null);
    const [selected, setSelected] = useState([])
    const [nav1, setNav1] = useState();
    const sample = history.location.search.indexOf('sample')
    const clipping = history.location.search.indexOf('clipping')
    const slidesToShow = 4
    const brandManage = localStorage.getItem('infoBrand')
    const convertBrandManage = JSON.parse(brandManage)
    const [form] = Form.useForm();

    const getBrandLookDetail = () => {
        if (sample !== -1){
            props.getBrandLookDetailSamples(params.lookId)
            .then(result => {
                setLookDetail(result)
                setLookDetailFile(result.file)
                setSelected([...selected, result])
            })
            .catch(err => {
                console.log(err)
                setLookDetail([])
                setLookDetailFile([])
            })

            props.getCollectionDetailSample(params.collectionId)
            .then(result => {
                setCollectionDetail(result)
                setLooks(result.collection_samples)
            })
            .catch(err => {
                console.log(err)
                setCollectionDetail([])
                setLooks([])
            })
        } else if (clipping !== -1){

        } else {
            props.getBrandLookDetail(params.lookId)
            .then(result => {
                setLookDetail(result)
                setLookDetailFile(result.file)
                setSelected([...selected, result])
            })
            .catch(err => {
                console.log(err)
                setLookDetail([])
                setLookDetailFile([])
            })
            
            props.getCollectionDetail(params.collectionId)
            .then(result => {
                const dataSort = result.collection_looks;
                setCollectionDetail(result)
                setLooks(dataSort)
                if (dataSort.length > 0) {
                    setLookDetailFile(dataSort[0].file)
                } else {
                    setLookDetailFile([])
                }
            })
            .catch(err => {
                console.log(err)
                setCollectionDetail([])
                setLooks([])
                setLookDetailFile([])
            })
        }
        // props.getCollectionDetailClipping(params.collectionId)
        // .then(result => {
        //     setCollectionDetail(result)
        //     setLooks(result.collection_clippings)
        // })
        // .catch(err => {
        //     setCollectionDetail([])
        //     setLooks([])
        // })
    }

 
       useEffect(() => {
        getBrandLookDetail();

        const listen=history.listen((arg)=>{
            if(arg.pathname==='/brandLookDetail'){
                getBrandLookDetail()
            }
        })
        return listen
        // eslint-disable-next-line
    }, [])

    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: looks.length > slidesToShow ? slidesToShow : looks.length,
        slidesToScroll: 1,
        focusOnSelect: true,
        arrows: true,
        speed: 25,
        swipeToSlide: true,
        variableWidth: true,
    };

    const getLooks = (looks) => {
        const selectLook = (selectedLook) => {
            props.getBrandLookDetail(params.lookId)
            .then(result => {
                setLookDetail(result)
            })
            .catch(err => {
                setLookDetail([])
            })
            setSelected([...selected, selectedLook])
        }
        return looks.map((look, index) => (
            <div className="list-wraper" key={index}>
                {
                    <div className={`slide-item ${look.file.url === lookDetailFile.url ? 'active' : ''}`} onClick={()=>selectLook(look)}>
                        <img alt="" src={look.file.url}></img>
                    </div>
                }
            </div>
        ))
    }

    const clickedPrev = () =>{
        slider.current.slickPrev();
        const index = looks.findIndex(item => item.file.url === lookDetailFile.url)
        if (index > 0) {
            setLookDetailFile(looks[index - 1].file)
            setLookDetail(looks[index - 1])
        } else if (index === 0) {
            const lengthLooks = looks.length
            setLookDetailFile(looks[lengthLooks - 1].file)
            setLookDetail(looks[lengthLooks - 1])
        }
    }

    const clickedNext = () =>{
        slider.current.slickNext();
        const index = looks.findIndex(item => item.file.url === lookDetailFile.url)
        const lengthLooks = looks.length

        if (index >= 0 && index < lengthLooks - 1) {
            setLookDetailFile(looks[index + 1].file)
            setLookDetail(looks[index + 1])
        } else if (index === lengthLooks - 1) {
            setLookDetailFile(looks[0].file)
            setLookDetail(looks[0])
        }
    }

    const beforeChangeImage = (oldIndex, newIndex) => {
        const convertIndex = Math.abs(newIndex)
        if (looks.length > 0) {
            console.log(looks)
            setLookDetailFile(looks[convertIndex].file)
        }
    }

    const addDescription=(e)=>{
        console.log(e.target.value,lookDetail)
        const {value} = e.target
        if(value){
            props.updateCollectionLook({description: value,id: lookDetail.id,collection_id: params.collectionId}).then(res=>{
                message.success("add description success")
                setLooks(looks.map(look=>{
                    if(look.id === lookDetail.id){
                        return {...look,description : value}
                    }
                    return look
                }))
            })
        }
    }

    useEffect(()=>{
        console.log(lookDetail,form.current)
         form.setFieldsValue(lookDetail)
    },[lookDetail])

    return <Layout showBack={true} rightButton={<UploadFiles type="brand">Create A Collection</UploadFiles>}>
        <div className="look-detail">
            <div className="look-name">
                {
                    userInfo.business_type === 'agency' ? (
                        <span>{convertBrandManage.name}</span>
                    ) : (
                        <span>{userInfo.business_name}</span>
                    )
                }
                · {collectionDetail.name} · {lookDetail.name}
            </div>
            <div className="look-info">
                <div className="info-desc">
                    {lookDetailFile.tagline}
                </div>
            </div>
            <div className="look-show">
                <div className="show-bigimg">
                    <Form form={form}>
                        <Form.Item name="description">
                            <Input placeholder='input your description' className="description" onBlur={addDescription}/>
                        </Form.Item>
                    </Form>
                    <img alt="" src={lookDetailFile.url}></img>
                </div>
                <div className="show-slide">
                    <div className="slide-list">
                        <div className="list-wraper">
                        <Slider
                            asNavFor={nav1}
                            ref={slider}
                            {...settings} beforeChange={beforeChangeImage}>
                                {
                                    getLooks(looks)
                                }
                        </Slider>
                        </div>
                    </div>
                    {
                        looks.length >= slidesToShow ? (
                            <div className="slide-navigation">
                                <div className="navigation-left" onClick={clickedPrev}></div>
                                <div className="navigation-right" onClick={clickedNext}></div>
                            </div>
                        ) : null
                    }
                   
                </div>
            </div>
        </div>
    </Layout >
}

const mapStateToProps = (state) => {
    return {
        common: state.common,
        brand: state.brand
    }
}

const mapActionsToProps = {
    getBrandLookDetail,
    getCollectionDetail,
    getBrandLookDetailSamples,
    getCollectionDetailSample,
    getCollectionDetailClipping,
    updateCollectionLook
}

export default connect(mapStateToProps, mapActionsToProps)(BrandLookDetail)