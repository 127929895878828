//import React from 'react'
import {connect} from 'react-redux'


const Home=(props)=>{
    const {userInfo}=props.common
    let uri=''
    
    switch(userInfo&&userInfo.business_type){
        case 'brand': uri='/brandCollections'; break;

        case 'media': uri='/mediaWorks'; break;

        case 'agent': uri='/agentBrands'; break;
    }

    if(uri){
        props.history.replace(uri)
    }else{
        props.history.replace('/login')
    }
   
    return ''
}
  
const mapStateToProps=(state)=>{
    return {
        common:state.common
    }
}
export default connect(mapStateToProps)(Home)
