import * as request from '../../request';
import { storeUtil } from '../../utility';



//add role
export const addBrand = (params) => async (dispatch, getState) => {
    const response = await request.addBrand(params);
    return response;
}
//add role
export const addUser = (params) => async (dispatch, getState) => {
    const response = await request.addUser(params);
    return response;
}

//get user roles
export const getUserRoles = (params) => async (dispatch, getState) => {
    const response = await request.fetchUserRoles(params)
    return response;
}

//add role
export const addRole = (params) => async (dispatch, getState) => {
    const response = await request.addRole(params)
    return response;
}

//get users
export const getUsers = (params) => async (dispatch, getState) => {
    const response = await request.fetchUsers(params)
    return response;
}

//get resources
export const getResources = (params) => async (dispatch, getState) => {
    const response = await request.fetchResources(params)
    return response;
}

//get roles
export const getRoles = (params) => async (dispatch, getState) => {
    const response = await request.fetchRoles(params)
    return response;
}


//show role
export const showRole = (params) => async (dispatch, getState) => {
    const response = await request.showRole(params)
    return response;
}
//delete role
export const deleteRole = (params) => async (dispatch, getState) => {
    const response = await request.deleteRole(params)
    return response;
}
//create role
export const createRole = (params) => async (dispatch, getState) => {
    const response = await request.createRole(params)
    
    return response;
}
//update role
export const updateRole = (params) => async (dispatch, getState) => {
    const response = await request.updateRole(params)
    
    return response;
}



//delete banner
export const fetchJobs = (params) => async (dispatch, getState) => {
    const response = await request.fetchJobs(params);
    return response;
}

//delete banner
export const showJob = (params) => async (dispatch, getState) => {
         const response = await request.showJob(params);

         return response;
       };
//delete banner
export const deleteJob = (params) => async (dispatch, getState) => {
    const response = await request.deleteJob(params)
    
    return response;
}
//create banner
export const createJob = (params) => async (dispatch, getState) => {
    const response = await request.createJob(params)
    
    return response;
}
//update event
export const updateJob = (params) => async (dispatch, getState) => {
    const response = await request.updateJob(params)
    
    return response;
}



//delete banner
export const fetchNews = (params) => async (dispatch, getState) => {
    const response = await request.fetchNews(params);
    return response;
}

//delete banner
export const showNews = (params) => async (dispatch, getState) => {
         const response = await request.showNews(params);

         return response;
       };
//delete banner
export const deleteNews = (params) => async (dispatch, getState) => {
    const response = await request.deleteNews(params);
    
    return response;
}
//create banner
export const createNews = (params) => async (dispatch, getState) => {
    const response = await request.createNews(params);
    
    return response;
}
//update event
export const updateNews = (params) => async (dispatch, getState) => {
    const response = await request.updateNews(params);
    
    return response;
}

//get banners
export const getBanners = (params) => async (dispatch, getState) => {
    const response = await request.fetchBanners(params)
    return response;
}


//show banner
export const showBanner = (params) => async (dispatch, getState) => {
    const response = await request.showBanner(params)
    
    return response;
}
//delete banner
export const deleteBanner = (params) => async (dispatch, getState) => {
    const response = await request.deleteBanner(params)
    
    return response;
}
//create banner
export const createBanner = (params) => async (dispatch, getState) => {
    const response = await request.createBanner(params)
    
    return response;
}
//update event
export const updateBanner = (params) => async (dispatch, getState) => {
    const response = await request.updateBanner(params)
    
    return response;
}


//get banners sort
export const getBannerSorts = (params) => async (dispatch, getState) => {
    const response = await request.fetchBannersSort(params)
    return response;
}


//show banner
export const showBannerSort = (params) => async (dispatch, getState) => {
    const response = await request.showBannerSort(params)
    
    return response;
}
//delete banner
export const deleteBannerSort = (params) => async (dispatch, getState) => {
    const response = await request.deleteBannerSort(params)
    
    return response;
}
//create banner
export const createBannerSort = (params) => async (dispatch, getState) => {
    const response = await request.createBannerSort(params)
    
    return response;
}
//update event
export const updateBannerSort = (params) => async (dispatch, getState) => {
    const response = await request.updateBannerSort(params)
    return response;
}


//delete event
export const importEventUserSeats = (id,params) => async (dispatch, getState) => {
         const response = await request.importEventUserSeats(id, params);

         return response;
       };

//delete event
export const fetchEventUserInfos = (params) => async (dispatch, getState) => {
         const response = await request.fetchEventUserInfos(params);

         return response;
       };

//delete event
export const getAllEvents = (params) => async (dispatch, getState) => {
    const response = await request.fetchEvents(params)
    
    return response;
}


//delete event
export const showEvent = (params) => async (dispatch, getState) => {
    const response = await request.showEvent(params)
    
    return response;
}
//delete event
export const deleteEvent = (event) => async (dispatch, getState) => {
    const response = await request.deleteEvent(event)
    
    return response;
}
//create event
export const createEvent = (event) => async (dispatch, getState) => {
    const response = await request.createEvent(event)
    
    return response;
}
//update event
export const updateEvent = (event) => async (dispatch, getState) => {
    const response = await request.updateEvent(event)
    
    return response;
}

//all businesses
export const getAllBusinesses = () => async (dispatch, getState) => {
    const response = await request.fetchAllBusinesses()
    
    await dispatch({
        type: "getAllBusinesses",
        payload: { businesses: response }
    });
    return response;
}


//forget reset password
export const forgetResetPassword =
    ({ password, passwordConfirmation, resetPasswordToken }) =>
        async (dispatch, getState) => {
            const response = await request.forgetResetPassword({
                password,
                password_confirmation: passwordConfirmation,
                reset_password_token: resetPasswordToken,
            });
            return response;
        };


//send email with forget password
export const sendEmailWithForgetPassword =
    ({ email }) =>
        async (dispatch, getState) => {
            const response = await request.sendEmailWithForgetPassword({ email });
            return response;
        };


// login 
export const encode = ({ email, password }) => async (dispatch, getState) => {
    const response = await request.encode({ email, password })
    const token = response.token
    storeUtil.set('token', token)
    storeUtil.set('resources', response.resources)
    storeUtil.set('roles', response.roles)
    await dispatch({ type: 'setUserInfo', payload: { token } });
    return response;
}


// return user profile
export const decode = ({ token }) => async (dispatch, getState) => {
    const userInfo = await request.decode({ token })
    const payload = { userInfo }
    if (userInfo.business_type === 'agent') {
        userInfo.agentInfo = JSON.stringify(userInfo)
        userInfo.isAgent = true
    }
    storeUtil.set('user', userInfo)
    await dispatch({ type: 'setUserInfo', payload });
    return userInfo;
}

// return local user profile
export const getUserSession = () => {
    const userInfo = storeUtil.get('user')
    const resources = storeUtil.get('resources')
    const roles = storeUtil.get('roles')
    const token = storeUtil.get('token')

    if (userInfo && token) {
        return {
            type: 'setUserInfo',
            payload: {
                token,
                userInfo,
                resources,
                roles
            }
        }
    } else {
        return {
            type: 'setUserInfo',
            payload: {}
        }
    }
}


// 管理品牌，layout菜单需要
export const manageBrand = (brand) => {

    return {
        type: "switchAccountType",
        payload: { business_type: 'brand' }
    }
}

// 返回代理页面，layout菜单需要
export const backAgent = () => {

    return {
        type: "switchAccountType",
        payload: { business_type: 'agency' }
    }
}

// Notification
export const getNotifications = (params) => async (dispatch) => {
    const response = await request.getNotifications(params)
    await dispatch({
        type: "getNotifications",
        payload: response
    });
    return response;
}

// Profile
export const getBusinessProfile = (id) => async (dispatch) => {
    const response = await request.getBusinessProfile(id)
    await dispatch({
        type: "getBusinessProfile",
        payload: response
    });
    return response;
}

export const updateBusinessProfile = (params) => async (dispatch) => {
    const response = await request.updateBusinessProfile(params)
    await dispatch({
        type: "createBusinessProfile",
        payload: response
    });
    return response;
}

export const resetPassword = () => async (dispatch) => {
    const response = await request.resetPassword()
    await dispatch({
        type: "createBusinessProfile",
        payload: response
    });
    return response;
}

export const uploadAvatar = (data) => async (dispatch) => {
    const response = await request.uploadAvatar(data)
    return response;
}

export const changePassword = (data) => async (dispatch) => {
    const response = await request.changePassword(data)
    return response;
}

export const getAvatar = (id) => async (dispatch) => {
    const response = await request.getAvatar(id)
    return response;
}

export const updateDecode = (params) => async (dispatch) => {
    const response = await request.updateDecode(params)
    return response;
}
