/**
 * 静态变量存储
 *
 *
*/


//页面所需静态图片地址
export const images={
    logo:require('../assets/images/logo.png'),
    ASB:require('../assets/images/ASB.png'),
    avatar_default:require('../assets/images/avatar_default.png'),
    look_demo:require('../assets/images/look_demo.png'),
    icon_upload_img:require('../assets/images/icon_upload_img.png'),
    icon_upload_success:require('../assets/images/icon_upload_success.png'),
    home:{
        logo_asb:require('../assets/images/logo_asb.png'),
    },
    default_avatar:require('../assets/images/default-avatar.png'),
    camera: require('../assets/images/camera.png'),
}


// 环境配置: testing, staging, production
let API_BusinessLogic=''      // business api prefix
let API_JWT=''                // jwt api prefix

switch(process.env.REACT_APP_ENV){
    case 'local':
    API_BusinessLogic='/bl'||'https://localhost:20080/bl/api/v1'      
    API_JWT='/id'||'https://localhost:20080/id/api/v1'
    break;

    case 'testing':
    API_BusinessLogic='/bl'||'https://testing.asingularbrand.cn/bl/api/v1'
    API_JWT='/id'||'https://testing.asingularbrand.cn/id/api/v1'
    break;

    case 'staging':
    API_BusinessLogic='https://staging.asingularbrand.cn/bl/api/v1'
    API_JWT='https://staging.asingularbrand.cn/id/api/v1'
    break;

    case 'production':
    API_BusinessLogic='https://prod.asingularbrand.cn/bl/api/v1'
    API_JWT='https://prod.asingularbrand.cn/id/api/v1'
    break;
}

export  {
    API_BusinessLogic,
    API_JWT,
}
