import React, { useState, useEffect } from 'react'
import {Input, message} from 'antd'
import {connect} from 'react-redux'
import { sendEmailWithForgetPassword } from '../../redux/actions/common';
import { getVersionBusiness, getVersionJWT } from '../../request/index';
import './style.scss'
import moment from "moment"

import {images} from '../../utility/const'
import { validate} from '../../utility'


const ForgetPassword=(props)=>{
    const [email, setEmail]=useState('')

    const goStart=()=>{
        if(!validate.email(email)){
            return message.warn({
                content:'Please enter a valid email address'
            })
        }

        props.sendEmailWithForgetPassword({ email }).then((res) => {
          message.success({
            content: 'Please check your email ',
          });
        })
        // .catch(err=>{
        //     message.error({
        //       content: `${email} not found`,
        //     });
        // });
    }

    const onChange=(e)=>{
        const target=e.target
        const name=target.name
        const value=target.value
       
        switch(name){
            case 'email': setEmail(value);break;

            // no default
        }
    }
    const getVersions = () => {
        getVersionBusiness().then((result) => {
            localStorage.setItem("versionBUtag", result.tag)
            let time = new Date(Number((result.timestamp + '000')));
            let timeVersionBUConvert = moment(time).format('DDMMYYYY');
            localStorage.setItem("versionBUtime", timeVersionBUConvert)
        })
        getVersionJWT().then((result) => {
            localStorage.setItem("versionJWTtag", result.tag)
            let timeVersionJWTConvert = moment(Number(result.timestamp + '000')).format('DDMMYYYY')
            localStorage.setItem("versionJWTtime", timeVersionJWTConvert)
        })
        fetch('/app/version.json')
        .then(response => response.json())
        .then(data => {
            localStorage.setItem("versionDBtag", data.tag)
            let timeVersionDBConvert =  moment(Number(data.timestamp + '000')).format('DDMMYYYY')
            localStorage.setItem("versionDBtime", timeVersionDBConvert)
        })
    }

    useEffect(() => {
        getVersions();
    }, [])

    return  <div className="sign-wraper login-wraper">
                <div className="sign-left"></div>

                <div className="sign-right">
                    <div className="sign-right">
                        <div className="logo-info">
                            <div className="logo">
                                <img alt="" src={images.logo} />
                            </div>
                            <div className="company-name">
                                <img alt="" src={images.ASB} />
                            </div>
                        </div>
                        <div className="sign-title">Forgot Password</div>
                        <div className="form-wraper">
                            <div className="form-input">
                                <input className="input-text" 
                                    autoComplete="off"
                                    name="email" 
                                    type="text" 
                                    value={email} 
                                    onChange={onChange} placeholder="Work Email" />
                                <span className="input-required">*</span>
                            </div>
                        </div>
                        <div className="sign-btn" onClick={goStart}> Send </div>
                    </div>
                    <div className="--version" style={{display: 'flex', flexWrap: "wrap", justifyContent: "center"}}>
                        <div style={{width: '45%', textAlign: "center"}}>{`Business Version : ` + localStorage.getItem("versionBUtag")+ " " + localStorage.getItem("versionBUtime")} </div>
                        <div style={{width: '45%', textAlign: "center"}}>{`JWT Version : ` + localStorage.getItem("versionJWTtag") + " " + localStorage.getItem("versionJWTtime")} </div>
                        <div style={{width: "100%", textAlign: "center"}}>{`Dashboard Version : ` + localStorage.getItem("versionDBtag") + " " + localStorage.getItem("versionDBtime")} </div>
                    </div>
                </div>
            </div>
}
  

const mapDispatchToProps = {
  sendEmailWithForgetPassword,
};

export default connect(null, mapDispatchToProps)(ForgetPassword);
