import React, { useState, useEffect } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import "./style.scss";
import {locationJSON} from '../../utility'
import { images } from "../../utility/const";
import Layout from "../../components/Layout";
import UploadFiles from "../../components/UploadFiles";
import FormSelect from "../../components/FormSelect";
import { Upload, Progress, message } from "antd";
import Modal from "../../components/Modal";
import { getAllSeasons } from '../../redux/actions/brand'
import { connect } from "react-redux";
import {Tooltip} from "antd"
import { getCollectionDetail} from "../../redux/actions/brand";

const BrandCollectionDetailBrandMedias = (props) => {
  const history = useHistory();
  const {
    common: { userInfo },
  } = props;
  const params = useParams();
  const [editorType, setEditorType] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [collectionDetail, setCollectionDetail] = useState([]);
  const [looks, setLooks] = useState([]);
  const [lookSamples, setLookSamples] = useState([]);
  const [lookClippings, setLookClippings] = useState([]);
  const [collectionEditors, setCollectionEditors] = useState({ name: "", link: "", });
  const accept = ["image/png", "image/jpeg", "image/jpg"];
  const [years, setYears] = useState([])
  const [seasonsOfYear, setSeasonsOfYear] = useState([])
  const [seasons, setSeasons] = useState([])
  const [currentSeason, setCurrentSeason] = useState()
  const [currentYear, setCurrentYear] = useState()
  const [dataLooks, setDataLooks] = useState([]);
  const [allSeasons, setAllSeasons] = useState([])

  let countTemp = 0
  useEffect(() => {
    const { type, router } = props
    getCollectionDetail()
    
    if (history.location.params) {
      setEditorType(history.location.params)
    }
  }, []);

  const getCollectionDetail = () => {

    let season_id = ''
    props
    .getCollectionDetail(params.collectionId)
    .then((result) => {
      setCollectionDetail(result);
      setLooks(result.collection_looks);
      setDataLooks(result.collection_looks)
      season_id = result.season_id;
      let dataTemp = [];
      result.collection_looks.forEach(element => {
        if (element.published) {
          dataTemp.push(element)
        }
      });
      getAllSeasons(season_id)

      setCollectionEditors({
        link: result.link,
        name: result.name,
      });

    })
    .catch((err) => {
      setCollectionDetail([]);
      setLooks([]);
      setDataLooks([])
    });
  }

  const getAllSeasons = (season_id) => {
    props.getAllSeasons().then(result => {
        let seasons = {}
        result.forEach(item => {
            if (seasons[item.year]) {
                if (item.id === season_id) {
                    setCurrentSeason({ value: item.id, year: item.year, name: item.name })
                    setCurrentYear({value: item.year})
                    setSeasonsOfYear(seasons[item.year])
                }
                seasons[item.year].push({ value: item.id, year: item.year, name: item.name })
            } else {
                seasons[item.year] = [{ value: item.id, year: item.year, name: item.name }]

                if (item.id === season_id) {
                  setCurrentSeason({ value: item.id, year: item.year, name: item.name })
                  setCurrentYear({value: item.year})
                  setSeasonsOfYear(seasons[item.year])
                }
            }
        })
        let years = Object.keys(seasons).map(key => ({ value: key, value: key }))
        years.sort((pre, next) => next.value - pre.value)
        setSeasons(seasons)
        setAllSeasons(result)
        setYears(years)
    })
  }

  const getLooks = () => {
    return dataLooks.map((look, index) => { 
      return (
        <div className="look" key={look.id}>
          <Link to={`/brandLookDetailFromMedia/${params.collectionId}/${look.id}`}>
              <div
                className="look-img"
                style={{ backgroundImage: `url(${look.file.url})` }}
                ></div>
              <div className="look-name"><Tooltip color="gray" placement="bottomLeft" title={look.name}>{look.name}</Tooltip></div>
            </Link>
        </div>
      );
    });
  };

  const getSeasonCollection = (id) => {
    let name = ''
    let index = allSeasons.findIndex(item => item.id === id)
    if (index !== -1){
        name = allSeasons[index].name + ' ' + allSeasons[index].year
    }
    return name;
  }
  const getClickableLink = link => {
    return link.startsWith("http://") || link.startsWith("https://") ?
      link
      : `http://${link}`;
  };


  return (
    <Layout
      showBack={true}
      rightButton={<UploadFiles type="media">Create A Story</UploadFiles>}
    >
      <div className="collection-detail">
        {
          editorType === "" ? (
            <div className="collection-name">
            {collectionDetail.username} · {getSeasonCollection(collectionDetail.season_id)} · {collectionDetail.name}
          </div>
          ) : null
        }

        <div className="collection-info-wraper">
        <div className="collection-info">
            <div className="info-desc">
              {dataLooks.length} Looks · {lookClippings.length} Clippings · {lookSamples.length} Samples · 
              {
                !collectionDetail.link ? (
                  <span className="link-download">
                  Download High Resolution</span>
                ) : (
                    <a href={getClickableLink(collectionDetail.link)} target="_blank">
                    Download High Resolution</a>
                )
              }
            </div>
          </div>
        </div>
        {editorType === "add"}
        <div className="collection-looks">{getLooks()}</div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    common: state.common,
    brand: state.brand,
  };
};

const mapActionsToProps = {
  getCollectionDetail,
  getAllSeasons,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(BrandCollectionDetailBrandMedias);
