import React, { useState } from 'react';
import { Transfer, Tree } from 'antd';

// Customize Table Transfer
const isChecked = (selectedKeys, eventKey) => selectedKeys.indexOf(eventKey) !== -1;

const generateTree = (treeNodes = [], checkedKeys = [], keyProp ="key",titleProp ="title" ) =>
  treeNodes.map(({ children, ...props }) => ({
    ...props,
    disabled: checkedKeys.includes(props.key),
    children: generateTree(children, checkedKeys,keyProp,),
  }));

const arrayToTree = (list = [], parentId = 0) => {
  return list
    .filter((item) => item.parent_id === parentId)
    .map((item) => ({
      ...item,
      children: arrayToTree(list, item.id),
    }));
};  

const TreeTransfer = ({ dataSource, targetKeys=[],...restProps }) => {
  const transferDataSource = [];
  function flatten(list = []) {
    list.forEach(item => {
      transferDataSource.push(item);
      flatten(item.children);
    });
  }
  flatten(dataSource);
  return (
    <Transfer
      {...restProps}
      targetKeys={targetKeys}
      dataSource={transferDataSource}
      className="tree-transfer"
      render={item => item.title}
      // showSelectAll={false}
    >
      {({ direction, onItemSelect, selectedKeys }) => {
        if (direction === 'left') {
          const checkedKeys = [...selectedKeys, ...targetKeys];
          const treeData = arrayToTree(transferDataSource);
          return (
            <Tree
              blockNode
              checkable
              checkStrictly
              defaultExpandAll
              defaultExpandedKeys={checkedKeys}
              height={500}
              checkedKeys={checkedKeys}
              treeData={generateTree(treeData, targetKeys)}
              onCheck={(_, { node: { key, selectedKeys } }) => {
                onItemSelect(key, !isChecked(checkedKeys, key));
              }}
              onSelect={(_, { node: { key } }) => {
                onItemSelect(key, !isChecked(checkedKeys, key));
              }}
            />
          );
        }
        if (direction === 'right') {
          const checkedKeys = [...selectedKeys, ...targetKeys];
          const treeData = arrayToTree(
            transferDataSource.filter((row) => checkedKeys.includes(row.id)),
          );
          return (
            <Tree
              blockNode
              checkable
              checkStrictly
              defaultExpandAll
              height={500}
              checkedKeys={selectedKeys}
              treeData={treeData}
              onCheck={(_, { node: { key } }) => {
                onItemSelect(key, isChecked(checkedKeys, key));
              }}
              onSelect={(_, { node: { key } }) => {
                onItemSelect(key, isChecked(checkedKeys, key));
              }}
            />
          );
        }
      }}
    </Transfer>
  );
};

export default TreeTransfer;