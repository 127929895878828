import { Dropdown } from 'antd'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link, useHistory, useParams } from 'react-router-dom'
import { getAllCollections } from '../../redux/actions/brand'
import { backAgent, getNotifications } from '../../redux/actions/common'
import { locationJSON, storeUtil } from '../../utility'
import { images } from '../../utility/const'
import './style.scss'
import { useIntervel } from './useIntervel'




const arrayToTree = (list = [], parentId = "0") => {
  return list.map((item)=>({...item,ancestry: item.ancestry ? item.ancestry : "0" }))
     .filter((item) => !item.top)
    .filter((item) => item.ancestry === `${parentId}`)
    .map((item) => ({
      ...item,
      children: arrayToTree(list, item.id),
    }));
};  


const Layout = (props) => {
    const { children, showBack, rightButton, headMenus } = props
    const history = useHistory()
    const params = useParams();
    const { common: { userInfo = {},resources = [],roles = [] } } = props
    const brandManage = localStorage.getItem('infoBrand')
    const convertBrandManage = JSON.parse(brandManage)
    const [activeIndex,setActiveIndex] = useState(localStorage.getItem('activeIndex'));
    const accountData = {
        brand: {
            modules: [
                {
                    name: userInfo.business_type !== 'agency' ? 'Collections' : 'Brand - ' + userInfo.business_name,
                    menus: [
                        {
                            name: 'All Collections',
                            path: '/brandCollections',
                            type: 'all'
                        },
                        {
                            name: 'Fall/Winter',
                            path: '/brandCollections?type=ff',
                            type: 'ff'
                        },
                        {
                            name: 'Pre-Fall',
                            path: '/brandCollections?type=pf',
                            type: 'pf'
                        },
                        {
                            name: 'Spring/Summer',
                            path: '/brandCollections?type=ss',
                            type: 'ss'
                        },
                        {
                            name: 'Resort',
                            path: '/brandCollections?type=ce',
                            type: 'ce'
                        },
                        {
                            name: 'Couture',
                            path: '/brandCollections?type=ct',
                            type: 'ct'
                        },
                        {
                            name: 'Drop',
                            path: '/brandCollections?type=dr',
                            type: 'dr'
                        }
                    ]
                },
                {
                    name: userInfo.business_type !== 'agency' ? 'Media List' : 'Media List - ' + userInfo.business_name,
                    menus: [
                        {
                            name: 'All Media',
                            path: '/brandMedias?type=allmedia',
                            type: 'allmedia'
                        },
                        {
                            name: 'My Media',
                            path: '/brandMedias?type=mymedia',
                            type: 'mymedia'
                        }
                    ]
                }
            ],
            topMenus: [
                {
                    name: 'Samples',
                    path: '/brandCollections',
                    filter: 'sample'
                }
            ]
        },
        media: {
            modules: [
                {
                    name: userInfo.business_type !== 'agency' ? 'My Works' : 'Collections - ' + userInfo.business_name,
                    menus: [
                        {
                            name: 'All Works',
                            path: '/mediaWorks',
                            type: 'all'
                        }
                    ]
                },
                {
                    name: userInfo.business_type !== 'agency' ? 'Brand List' : 'Collections - ' + userInfo.business_name,
                    menus: [
                        {
                            name: 'All Brands',
                            path: '/mediaBrands?type=allbrand',
                            type: 'allbrand'
                        },
                        {
                            name: 'My Brands',
                            path: '/mediaBrands?type=mybrand',
                            type: 'mybrand'
                        }
                    ]
                }

            ],
            topMenus: []
        },
        agency: {
            modules: [
                {
                    name: '',
                    menus: [
                        {
                            name: 'Client Brands',
                            path: '/agentBrands?type=allClientBrands',
                            type: 'allClientBrands'
                        }
                    ]
                },
                {
                    name: 'Media List - ' + userInfo.business_name,
                    menus: [
                        {
                            name: 'All Media',
                            path: '/agentMedias?type=allmediaAgency',
                            type: 'allmediaAgency'
                        },
                        {
                            name: 'My Media',
                            path: '/agentMedias?type=mymediaAgency',
                            type: 'mymediaAgency'
                        }
                    ]
                }
            ],
            topMenus: [
                {
                    name: 'Samples',
                    path: '/brandCollections',
                    filter: 'sample'
                }
            ]
        }
    }
    let router = locationJSON(window.location)

    const isAgent = userInfo.isAgent
    const accountType = userInfo.business_type
    let [currentMenu, updateMenu] = useState(router.queryObject.type || 'all')
    const [notificationList, setNotificationList] = useState([])
    const [avatar, setAvatar] = useState()
    // const [count, setCount] = useState(0)
    // const [state, setState] = useState({ data: [] });
    let oldTotal = 0
    let totalNoti = 0


    const initData = () => {
        props.getNotifications()
            .then(result => {
                setNotificationList(result)
                countNoti()
            })
            .catch(err => {
                setNotificationList([])
            })
    }

    useIntervel(async () => {
        await initData();
    });


    const getAvatar = () => {
        if (userInfo.avatar) {
            setAvatar(userInfo.avatar);
        }
    };

    useEffect(() => {
        updateMenu(router.queryObject.type || 'all')
        getAvatar()
        // eslint-disable-next-line
    }, [router.queryObject.type, getAvatar])


    const logOut = () => {
        storeUtil.clear();
        history.push('/login');
    };


    // back button
    const getBack = () => {
        const goBack = () => {
            history.goBack();
        }
        return <div className="menu-back" onClick={goBack}>
            <i className="icon-back"></i>Back
        </div>
    }
    // goNotification
    const goNotification = () => {
        history.push('/notification')
    }
    // back agent
    const backAgentFn = () => {
        props.backAgent()
        history.push('/agentBrands?type=allClientBrands')
    }

    const countNoti = () => {
        if (notificationList.length === 0) {
            return 0;
        } else if (notificationList.length > 0) {
            notificationList.reduce((total, currentValue) => {
                if (!currentValue.seen && (currentValue.status === 'connected' || (currentValue.status === 'pending' && (currentValue.created_by !== userInfo.business_type)))) {
                    totalNoti++
                    if (totalNoti >= oldTotal) {
                        oldTotal = totalNoti
                    }
                }
            }, 0);
        }
        return oldTotal
    }

    // left slide menu
    const getMenus = () => {
        let modules = arrayToTree(resources);
        const paramsRouter = params?.workId || params.collectionId
        const paramsRouterLook = params?.lookId || ''
        
        // const brandManage = localStorage.getItem('infoBrand')
        // const convertBrandManage = JSON.parse(brandManage)

        // const menuAgencyBrand = [
        //     {
        //         name: 'Brand - ' + convertBrandManage?.name,
        //         menus: [
        //             {
        //                 name: 'All Collections',
        //                 path: '/brandCollections',
        //                 type: 'all'
        //             },
        //             {
        //                 name: 'Spring/Summer',
        //                 path: '/brandCollections?type=ss',
        //                 type: 'ss'
        //             },
        //             {
        //                 name: 'Fall/Winter',
        //                 path: '/brandCollections?type=ff',
        //                 type: 'ff'
        //             },
        //             {
        //                 name: 'Couture',
        //                 path: '/brandCollections?type=ct',
        //                 type: 'ct'
        //             },
        //             {
        //                 name: 'Drop',
        //                 path: '/brandCollections?type=dr',
        //                 type: 'dr'
        //             }
        //         ]
        //     },
        //     {
        //         name: 'Media List - ' + convertBrandManage?.name,
        //         menus: [
        //             {
        //                 name: 'All Media',
        //                 path: '/agentMedias?type=allmedia',
        //                 type: 'allmedia'
        //             },
        //             {
        //                 name: 'My Media',
        //                 path: '/agentMedias?type=mymedia',
        //                 type: 'mymedia'
        //             }
        //         ]
        //     }
        // ]
        // const queryObj = router.queryObject.type;
        // if (brandManage && (router.path !== '/app/agentBrands' && queryObj !== 'allmediaAgency' && queryObj !== 'mymediaAgency')) {
        //     const modulesTemp = modules.concat(menuAgencyBrand)
        //     modulesTemp.splice(1, 1)
        //     modules = modulesTemp
        // } else if (router.path === '/app/agentBrands' || queryObj === 'mymediaAgency' || queryObj === 'allmediaAgency' || queryObj === 'allClientBrands') {
        //     localStorage.removeItem('infoBrand')
        // }


        // const bannerMenu = ['Seeder', 'Manager'].includes(userInfo.user_type) ? {
        //     name: 'Banners',
        //     menus: [
        //         {
        //             name: 'Banner Sort',
        //             path: '/banners_sort?type=banner_sorts',
        //             type: 'banner_sorts'
        //         },
        //         {
        //             name: 'Banners',
        //             path: '/banners?type=banners',
        //             type: 'banners'
        //         }
        //     ]
        // } : null;
        // if (bannerMenu) {
        //     modules.unshift(bannerMenu)
        // }

        // const eventMenu = ['Seeder', 'Manager'].includes(userInfo.user_type) ? {
        //     name: 'Events',
        //     menus: [
        //         {
        //             name: 'All Events',
        //             path: '/events?type=events',
        //             type: 'events'
        //         }
        //     ]
        // } : null;
        // if (eventMenu) {
        //     modules.unshift(eventMenu)
        // }

        
        //     modules.unshift({
        //     name: 'System',
        //     menus: [
        //         {
        //             name: 'All Users',
        //             path: '/users?type=users',
        //             type: 'users'
        //         },
        //         {
        //             name: 'All Roles',
        //             path: '/roles?type=roles',
        //             type: 'roles'
        //         }
        //     ]
        // })


        const result = modules.map((module, index) => [
            <div className="module" key={index}>
                <div className="module-name">{module.name}</div>
                <div className="module-menus">
                    {
                        module.children.map((menu) => (
                            <div className={`menu ${(`${menu.id}` === `${activeIndex}` )? 'active' : ''}`} key={menu.uri} onClick={e=>{
                                    setActiveIndex(menu.id)
                                    localStorage.setItem('activeIndex',menu.id)
                                }}>
                                <Link  to={menu.uri}>{menu.name}</Link>
                            </div>
                        ))
                    }
                </div>
            </div>,
            index < (modules.length - 1) ? <div className="module-divide" key='module-divide'></div> : ''
        ])

        // if (isAgent && accountType === 'brand') {
        //     result.unshift(<>
        //         <div className="module">
        //             <div className="module-menus">
        //                 <div className="menu cursor-pointer" onClick={backAgentFn}>Client Brands</div>
        //             </div>
        //         </div>
        //         <div className="module-divide" key='module-divide'></div>
        //     </>)
        // }



        return result
    }

    // top head
    const getHead = () => {
        const topMenus = accountData[accountType] ? accountData[accountType].topMenus || [] : []
        const paramsRouter = params?.workId || params.collectionId

        return (
            <div className="head">
                <div className="head-menus">
                    {showBack ? getBack() : ''}
                    {/* {topMenus.length > 0 &&
                        router.path !== '/brandMedias' &&
                        !router.path.includes('/events') &&
                        !router.path.includes('/banners') &&
                        !router.path.includes('/banners_sort') &&
                        !router.path.includes('/users') &&
                        !router.path.includes('/roles') &&
                        router.path !== '/app/brandMedias' &&
                        router.path !== `/app/mediaWorkDetailMedia/${paramsRouter}`
                        ? topMenus.map((menu, index) => (
                            <div
                                className={`menu-item ${menu.filter === router.queryObject.filter
                                    ? 'active'
                                    : ''
                                    }`}
                                key={menu.filter}
                            >
                                <Link
                                    to={`${menu.path}?filter=${menu.filter}${currentMenu !== 'all' ? '&type=' + currentMenu : ''
                                        }`}
                                >
                                    {menu.name}
                                </Link>
                            </div>
                        ))
                        : ''} */}
                    {headMenus ? headMenus : ''}
                </div>
                <div className="head-right">
                    <div className="notification" onClick={goNotification}>
                        <span className="notification-count">{countNoti()}</span>
                    </div>
                    <div className="avatar">
                        <Dropdown
                            placement="bottomCenter"
                            overlay={
                                <div className="dropdown-menus">
                                    <div className="business-name">
                                        {userInfo.business_name}
                                    </div>
                                    <div className="menu-item">
                                        <Link to={'/selfprofile/' + userInfo.business_id}>
                                            View Profile
                                        </Link>
                                    </div>
                                    
                                    <div className="menu-item">
                                        <Link to="#" onClick={logOut}>
                                            Logout
                                        </Link>
                                    </div>
                                </div>
                            }
                        >
                            {showAvatar()}
                        </Dropdown>
                    </div>
                    {rightButton ? rightButton : ''}
                </div>
            </div>
        );
    }

    const showAvatar = () => {
        if (avatar) {
            return <img alt="" src={avatar} />
        } else {
            return <img alt="" src={images.avatar_default} />
        }
    }

    const backHome = () => {
        // if (userInfo.business_type === "media") {
        //     history.push(`/mediaWorks`)
        // } else if (userInfo.business_type === "brand") {
        //     history.push(`/brandCollections`)
        // } else {
        //     history.push(`/agentBrands?type=allClientBrands`)
        // }

        history.push(`/`)
    }

    return <div className="frame">
        <div className="frame-left">
            <div className="logo-asb" onClick={backHome}>
                <img className="img-logo" alt="" src={images.home.logo_asb} />
            </div>
            {
                getMenus()
            }
        </div>

        <div className="frame-right">
            {
                getHead()
            }
            <div className="content">
                {children}
            </div>
        </div>
    </div>
}


const mapStateToProps = (state) => {

    return {
        common: state.common,
        brand: state.brand
    }
}
const mapDispatchToProps = {
    getAllCollections,
    backAgent,
    getNotifications,
}
export default connect(mapStateToProps, mapDispatchToProps)(Layout)