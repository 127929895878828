import { LoadingOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import LayoutSimple from '../../components/Layout/simple';
import listCountry from '../../country';
import { getAllSeasons } from '../../redux/actions/brand';
import { getBusinessProfile } from '../../redux/actions/common';
import { getAllBrandsConnec } from '../../redux/actions/media';
import { storeUtil } from '../../utility';
import { images } from '../../utility/const';
import './style.scss';


const Profile = (props) => {
    const { common: { userInfo } } = props  
    const params = useParams();
    const history=props.history
    const [businessProfile, setBusinessProfile] = useState([])
    const [businessProfileItems, setBusinessProfileItems] = useState([])
    const [avatar, setAvatar] = useState('')
    const [isLoading, setIsloading] = useState(true)
    const [allSeasons, setAllSeasons] = useState([])
    const [brands, setBrandList]=useState([])
    const [isDataEmpty, setIsDataEmpty]=useState(false)
    const [lastId, setLastId]=useState('')
    const limit = 18

    const getItems=()=>{
        props.getBusinessProfile(params.id)
        .then(result=>{
            setBusinessProfile(result)   
            if (result.business_type === "media"){
                setBusinessProfileItems(result.media_story_boards)
                setIsloading(false)
            } else{
                setBusinessProfileItems(result.brand_collections)
                setIsloading(false)
            }
        })
        .catch(err=>{
            console.log(err)
            setBusinessProfile([])
        })
    }

    const getAllSeasons = (season_id) => {
        props.getAllSeasons().then(result => {
            setAllSeasons(result)
        })
    }
    const dataUser = JSON.parse(localStorage.getItem("user"));
    useEffect(() => {
        getItems();
        getAllSeasons();
        if (userInfo.business_type === 'agency') {
            getBrandsAgentcy();
        }
        if (userInfo.avatar) {
            console.log(dataUser)
            setAvatar(dataUser.avatar)
        }
        const listen=history.listen((arg)=>{
            if(arg.pathname==`/selfprofile/${params.id}`){
                getItems()
            }
        })
        return listen
    }, [])

    const getSeasonCollection = (id, item) => {
        let name = ''
        let index = allSeasons.findIndex(item => item.id === id)
        if (index !== -1){
            name = allSeasons[index].name + ' ' + allSeasons[index].year
        } else {
            name = item.name
        }
        return name;
    }

    const showColectionDetail = (item) => {
        if ((businessProfile.business_type === "media" || userInfo.business_type === "agency") && businessProfile.id === userInfo.id) {
            console.log(`/mediaWorkDetail/${item.id}`)
            history.push(`/mediaWorkDetail/${item.id}`)
        } else if (businessProfile.business_type === "brand" && businessProfile.id === userInfo.id) {
             console.log(`/brandCollectionDetail/${item.id}`)
            history.push(`/brandCollectionDetail/${item.id}`);
        } else if ((userInfo.business_type === "brand" || userInfo.business_type === "agency") && businessProfile.id !== userInfo.id) {
            console.log(`/mediaWorkDetailMedia/${item.id}`)
            history.push(`/mediaWorkDetailMedia/${item.id}`);
        } else if (userInfo.business_type === "media" && businessProfile.id !== userInfo.id) {
            console.log(`/brandCollectionDetailBrandMedias/${item.id}`)
            history.push(`/brandCollectionDetailBrandMedias/${item.id}`);
        }
    }


    const getDetails = (items) => {
        return items?.map((item, index) => (
            <div className="look" key={index} onClick={() => showColectionDetail(item)}>
                <div className="look-img">
                    {
                        item.collection_looks && item.collection_looks.length > 0? (
                            <img src={item.collection_looks[0].file.url}></img>
                        ) : item.story_board_looks && item.story_board_looks.length > 0 ? ( 
                        <img src={item.story_board_looks[0].file.url}></img>
                        ) : (<img src={images.look_demo}></img>)
                    }
                </div>
                <div className="look-info">
                    <div className="info-name">
                        {
                            getSeasonCollection(item.season_id, item)
                        }
                    </div>
                    <div className="info-desc">
                        {item.story_board_looks ? item.story_board_looks.length : item.collection_looks.length} pictures
                    </div>
                </div>
            </div>
        )) || ''
    } 

    const logOut = () => {
        storeUtil.clear()
        history.push("/login");
    }

    const getCountry = (index) => {
        const parseIndex = parseInt(index)
        if (parseIndex || parseIndex === 0) {
            return listCountry[parseIndex].name
        } else {
            return ''
        }
    }

    const getBrandsAgentcy = () => {
        const params = {
            limit: limit
        }
        props.getAllBrandsConnec(params).then(
            result=>{
                const lengthBrand = result.length;
                setBrandList([...brands, ...result])
                if( 0 < limit < lengthBrand){
                    setIsDataEmpty(true)
                    setLastId(result[lengthBrand - 1].id)
                }else if (lengthBrand === 0){
                    setIsDataEmpty(false)
                }
        })
        .catch(err=>{
            setBrandList([])
        })
    }

    const loadMoreMedia = () => {
        getBrandsAgentcy();
    }

    return <LayoutSimple>
        <div className="profile-container">
            <div className="profile-head">
            <div className="profile-avatar">
                {
                    !isLoading ? (
                        businessProfile.avatar ? ( <img src={businessProfile.avatar}/>) : <img src={images.avatar_default}/>)
                        : (<LoadingOutlined/>)
                }
            </div>
            <div className="profile-info">
                <div className="info-name">{businessProfile.name} </div>
                <div className="info-desc">
                    {
                        businessProfile.publication ? (
                            <span>{businessProfile.publication} · </span>
                        ) : null
                    }

                    {
                        businessProfile.city ? (
                            <span>{businessProfile.city} · </span>
                        ) : null
                    }
                    

                    <span>{getCountry(businessProfile.country)}</span>
                </div>

                {
                    businessProfile.id === userInfo.id ? (
                        <div className="info-desc">{businessProfile.email} · {businessProfile.phone}</div>
                    ) : null
                }
            </div>

            {
                businessProfile.id === userInfo.id ? (
                    <div>
                        <div className="profile-operate">
                            <div className="profile-editbtn"><Link to={`/selfprofile/${userInfo.business_id}`}>Edit Profile</Link></div>
                        </div>

                        <div className="logout">
                            <div className="profile-editbtn" onClick={logOut}>Logout</div>
                        </div>
                    </div>
                ) : null
            }

        </div>

        <div className="profile-looks">
            {
                getDetails(businessProfileItems)
            }
        </div>

        <div className="profile-agency">
            {
                userInfo.business_type === businessProfile.business_type ? (
                    brands.map((item, index) => (
                        <div className="brand-img" key={index}>
                        <img src={item.avatar || images.avatar_default}></img>
                    </div>
                ))
                ) : null
            }
            {
                isDataEmpty && brands.length >= limit ? ( 
                    <div className="button-load-mode">
                        <span onClick={() => loadMoreMedia()}>Unlock More Media</span>
                    </div>
                    ) : null
            }
        </div>

        </div>

    </LayoutSimple>
}

const mapStateToProps = (state) => {
    return {
        common: state.common
    }
}

const mapActionsToProps = {
    getBusinessProfile,
    getAllSeasons,
    getAllBrandsConnec,
}

export default connect(mapStateToProps, mapActionsToProps)(Profile)