import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import InviteButton from '../../components/InviteButton'
import Layout from '../../components/Layout'
import listCountry from '../../country'
import { getAllBrandMedias, getMyBrandMedias } from '../../redux/actions/brand'
import { createFollower, getAllMediaByAgency, getMyMediaByAgency } from '../../request'
import { locationJSON } from '../../utility'
import { images } from '../../utility/const'
import './style.scss'


const AgentBrands = props => {
    const {common:{userInfo}, brand}=props
    const [mediaList, setMediaList]=useState([])
    const history=props.history
    const [lastId, setLastId]=useState('')
    const [isDataEmpty, setIsDataEmpty]=useState(false)
    const limit = 8

    const currentBrand = JSON.parse(localStorage.getItem('infoBrand'))
    const getAgentMedias=()=>{
        
        const router=locationJSON(window.location)
        let media_type = ''
        setIsDataEmpty(false)
        if(router.queryObject.type){
            media_type=router.queryObject.type
        }


        let params =  {
            lastId: lastId
        }
  
        
        if(media_type === 'allmedia' || media_type === 'mymedia'){
            params.brandId = currentBrand.id
        } else if (media_type === 'allmediaAgency' || media_type === 'mymediaAgency'){
            params.brandId = userInfo.id
        }

        if ( media_type === 'allmediaAgency' || media_type === 'allmedia' ){
            getAllMediaByAgency(params)
            .then(result=>{
                const lengthMedia = result.length;
                setMediaList([...mediaList, ...result])
                if (0 < limit < lengthMedia ) {
                    setIsDataEmpty(true)
                    setLastId(result[lengthMedia - 1].id)
                } else if (lengthMedia === 0) {
                    setIsDataEmpty(false)
                } 
            })
            .catch(err=>{
                console.log(err)
                setMediaList([])
            })   
        } else {
            getMyMediaByAgency(params)
            .then(result=>{
                const lengthMedia = result.length;
                setMediaList([...mediaList, ...result])
                if (0 < limit < lengthMedia ) {
                    setIsDataEmpty(true)
                    setLastId(result[lengthMedia - 1].id)
                } else if (lengthMedia === 0) {
                    setIsDataEmpty(false)
                }
            })
            .catch(err=>{
                setMediaList([])
            })
        } 
    }

    useEffect(()=>{
        getAgentMedias()
        
        const listen=history.listen((arg)=>{
            if(arg.pathname ==='/agentMedias'){
                getAgentMedias()
            }
        })
        return listen
        // eslint-disable-next-line
    },[])

    const getCountry = (index) => {
        const parseIndex = parseInt(index)
        if (parseIndex || parseIndex === 0) {
            return listCountry[parseIndex].name
        } else {
            return ''
        }
    }

    const showProfile = (media) => {
        history.push(`/profile/${media.business_user_mapping_id}`)
    }

    const getMedias = mediaList => {
        const connectMedia= (media) =>{
            createFollower(userInfo.business_type, media.user_business_id, media.id)
            .then(res=>{
                const index = mediaList.findIndex(item => item.id === media.id)
                let dataTemp = [...mediaList]
                if (index !== -1) {
                    dataTemp[index].status = 'Pending'
                    setMediaList(dataTemp)
                }
               setMediaList(dataTemp)
            })
        }

        return mediaList.map((media, index)=>(
            <div className ="media" key = {index}>
                <div className ="media-avatar" onClick = {() => showProfile(media)}>
                    <img alt="description Image" src={media?.avatar || images.avatar_default}></img>
                </div>
                <div className="media-info">
                    <div className="info1">   
                        <div className="info-name" onClick = {() => showProfile(media)}>
                            {media.name}
                        </div>
                        <div className="info-desc">{media.name} · {media.city} · {getCountry(media.country)}</div>
                    </div>
                    <div className="info2">
                        {
                            media.status === "Connected" ? (
                                <div className ="info-connect connected">
                                    Connected
                                    <i className="icon-right"></i>
                                </div>
                            ) : media.status ==="Pending"?(
                                <div className="info-connect pending"> Pending </div>
                            ) : (
                                <div className="info-connect" onClick = {() => connectMedia(media)}> Connect </div>
                            )
                        }
                       
                    </div>
                </div>
            </div>
        ))
    }

    const loadMoreMedia = () => {
        getAgentMedias();
    }

    return <Layout 
                rightButton={<InviteButton type="media">Invite Media</InviteButton>}>
        <div className="agent-medias">
            <div className="info">
                <div className="info-name">
                    My Media
                </div>
                <div className="info-desc">{mediaList.length} Media</div>
            </div>
            <div className="medias">
                { getMedias(mediaList) }
            </div>
            {
                isDataEmpty && mediaList.length >= limit ? ( 
                    <div className="button-load-mode">
                        <span onClick={() => loadMoreMedia()}>Unlock More Media</span>
                    </div>
                ) : null
            }
        </div>
    </Layout>
}

const mapStateToProps=(state)=>{
    return {
        common:state.common,
        brand:state.brand
    }
}

const mapActionsToProps={
    getAllBrandMedias,
    getMyBrandMedias,
}

export default connect(mapStateToProps, mapActionsToProps) (AgentBrands)
