import { Button, List, Popconfirm } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Layout from '../../components/Layout'
import { deleteRole, getRoles } from '../../redux/actions/common'
import './style.scss'


const Roles = props => {
    const history = props.history
    const [collections, setCollections] = useState([])
    const [total, setTotal] = useState(0)
    const [current, setCurrent] = useState(1)

    const getCollections = useCallback(() => {
        props.getRoles({ page: current })
            .then(result => {
                setCollections(result.data)
                setTotal(result.total)
            })
            .catch(err => {
                setCollections([])
            })
    },[current, props])

    
    useEffect(() => {
        getCollections();
    }, [current, getCollections])

    const newEvent = () => {
        history.push(`/roles/new`)
    }
    const addButton = <div className="upload-button" onClick={newEvent} ><i className="icon-add"></i>Create A Role</div>

    const handleRemove = (id) => {
        props.deleteRole({ id }).then(res => {
            getCollections()
        })
    }

    return <Layout rightButton={addButton}>
        <div className="roles-collections">
            {
                collections.length > 0 ? (
                    <div className="collection">
                        <div className="collection-name">Roles</div>
                        <div className="collection-info">
                            <div className="info-desc">
                                {total} Roles
                            </div>
                        </div>
                        <div>
                            <List
                                itemLayout="horizontal"
                                size="large"
                                pagination={{
                                    simple: true,
                                    onChange: page => {
                                        setCurrent(page);
                                    },
                                    pageSize: 20,
                                    total,
                                    showQuickJumper: true
                                }}
                                dataSource={collections}
                                renderItem={item => (
                                    <List.Item
                                        key={item.id}
                                        actions={[<Button style={{ borderColor: '#505050', color: '#505050' }} onClick={e => {
                                            history.push({ pathname: '/roles/edit', state: { id: item.id } })
                                        }}>Edit</Button>,
                                        <Popconfirm placement="bottom" title={"Are you sure to delete this item?"} onConfirm={() => { handleRemove(item.id) }} okText="Yes" cancelText="No">
                                            <Button style={{ borderColor: '#F58C78', color: '#F58C78' }}>Delete</Button>
                                        </Popconfirm>]}
                                    >
                                        <List.Item.Meta
                                            title={<a href="javascript:void(0)" onClick={e => {
                                                history.push({ pathname: '/roles/show', state: { id: item.id } })
                                            }}>{item.name}</a>}
                                        />
                                    </List.Item>
                                )}
                            />
                        </div>
                    </div>


                ) : (
                    <div className="collection">
                        <div className="collection-name">Roles</div>
                        <div className="collection-info">
                            <div className="info-desc">
                                0 Roles
                            </div>
                        </div>
                        <div className="collection-looks-nothing">
                            <div className="noting-img">
                                <div className="noting-img-arrow"></div>
                            </div>
                            <div className="noting-text">
                                Your role page is empty, <br />
                                Please create an role here!
                            </div>
                        </div>
                    </div>
                )
            }

        </div>
    </Layout>
}


const mapStateToProps = (state) => {
    return {
        common: state.common
    }
}

const mapActionsToProps = {
    getRoles,
    deleteRole
}

export default connect(mapStateToProps, mapActionsToProps)(Roles)