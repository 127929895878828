import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import InviteButton from '../../components/InviteButton'
import Layout from '../../components/Layout'
import listCountry from '../../country'
import { manageBrand } from '../../redux/actions/common'
import { getAllBrandsConnec } from '../../redux/actions/media'
import { locationJSON } from '../../utility'
import { images } from '../../utility/const'
import './style.scss'


const AgentBrands = props => {
    const router=locationJSON(window.location)
    const [brands, setBrandList]=useState([])
    const [isDataEmpty, setIsDataEmpty]=useState(false)
    const [lastId, setLastId]=useState('')
    const limit = 8
    const manageBrandFn=(brand)=>{
        props.manageBrand(brand)
        props.history.push('/brandCollections')
        localStorage.setItem('infoBrand', JSON.stringify(brand))
    }

    const getAllBrands = () => {
        setIsDataEmpty(false)
        const params = {
            lastId: lastId
        }
        props.getAllBrandsConnec(params).then(
            result=>{
                const lengthBrand = result.length;
                setBrandList([...brands, ...result])
                if( 0 < limit < lengthBrand){
                    setIsDataEmpty(true)
                    setLastId(result[lengthBrand - 1].id)
                }else if (lengthBrand === 0){
                    setIsDataEmpty(false)
                }
        })
        .catch(err=>{
            setBrandList([])
        })
    }

    useEffect(()=>{
        getAllBrands()
    },[])

    const loadMoreMedia = () => {
        getAllBrands();
    }

    const getCountry = (index) => {
        const parseIndex = parseInt(index)
        if (parseIndex || parseIndex === 0) {
            return listCountry[parseIndex].name
        } else {
            return ''
        }
    }

    return <Layout 
                rightButton={<InviteButton type="brand">Invite Brand</InviteButton>}>
                 <div className="agent-brands">
                   <div className="info">
                     <div className="info-name">
                        Client Brands
                    </div>
                    <div className="info-desc">{brands.length} Brands</div>
                </div>
            <div className="brands">
                {
                    brands.map((brand, index)=>(
                        <div className="media" key={index}>
                            <div className="media-avatar">
                                <img src={brand?.avatar || images.avatar_default}></img>
                            </div>
                            <div className="media-info">
                                <div className="info1">   
                                    <div className="info-name">
                                        {brand.name}
                                    </div>
                                    <div className="info-desc">{brand.name} · {brand.city} · { getCountry(brand.country) }</div>
                                </div>
                                <div className="info2">
                                    {
                                            <div className="info-connect" onClick={()=>manageBrandFn(brand)}>Manage</div>
                                    }
                                </div>
                            </div>
                            {
                                isDataEmpty && brands.length >= limit ? ( 
                                    <div className="button-load-mode">
                                        <span onClick={() => loadMoreMedia()}>Unlock More Media</span>
                                    </div>
                                 ) : null
                            }
                        </div>
                    ))
                }
            </div>
        </div>
    </Layout>
}

const mapDispatchToProps = {
    manageBrand,
    getAllBrandsConnec
}

export default connect(null,mapDispatchToProps)(AgentBrands)
