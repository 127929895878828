import React, { useState, useEffect } from 'react'
import {useParams, useHistory} from 'react-router-dom'
import {Input, message} from 'antd'
import './style.scss'

import {images} from '../../utility/const'
import {validate} from '../../utility/'
import { getInviteInfo, createUser, checkRegister} from '../../request/'

const Register=()=>{
    const history=useHistory()
    const params=useParams()
    const [inviteInfo, setInviteInfo]=useState({})
    const [password, setPassord]=useState('')


    useEffect(()=>{
        checkRegister(params.token)
        .then(res=>{
            if(res.status){
                history.push("/login")
            }else{
                getInviteInfo(params.token)
                .then(res=>{
                    setInviteInfo(res)
                })
            }
        })

        // eslint-disable-next-line
    },[])
    const onChange=(e)=>{
       const {value}=e.target
       setPassord(value)
    }

    const toRegister=()=>{
        if(validate.password(password)){
            createUser({password},{Token:params.token})
            .then(res=>{
                message.success({
                    content:'Registered succesfully，you will be redirected to the login page！',
                    onClose:()=>{
                        history.push('/login')
                    }
                })
            })
        }else{
            message.warn({
                content:'The password must be at least 6 letters, numbers or combination !'
            })
        }
    }

    
    return  <div className="sign-wraper register-wraper">
                <div className="sign-left"></div>

                <div className="sign-right">
                    <div className="logo-info">
                        <div className="logo">
                            <img src={images.logo} />
                        </div>
                        <div className="company-name">
                            <img src={images.ASB} />
                        </div>
                    </div>
                    <div className="sign-title">Create An Account</div>
                    <div className="form-wraper">
                        <div className="form-input">
                            <input className="input-text" defaultValue={inviteInfo.business_name} type="text" disabled />
                            <span className="input-required">*</span>
                        </div>
                        <div className="form-input">
                            <input className="input-text" defaultValue={inviteInfo.email} type="text" disabled/>
                            <span className="input-required">*</span>
                        </div>
                        <div className="form-input">
                            <Input.Password placeholder="Create Password" autoComplete="off" onChange={onChange} value={password}/>
                            {/* <input className="input-text" type="password" placeholder="Password" />
                            <span className="input-required">*</span> */}
                        </div>
                    </div>
                    <div className="sign-btn" onClick={toRegister}> Start  </div>
                </div>
            </div>
}
  
export default Register
