import React, { useState, useEffect } from 'react'
import {Link, useParams} from 'react-router-dom'
import './style.scss'
import { connect } from 'react-redux'
import {images} from '../../utility/const'
import {locationJSON} from '../../utility'
import Layout from '../../components/Layout'
import UploadFiles from '../../components/UploadFiles'
import { Upload, Progress, message,Tooltip } from "antd";
import FormSelect from '../../components/FormSelect';
import Modal from "../../components/Modal";

import { getWorkDetail, getWorkLookDetail } from '../../redux/actions/media'

const MediaWorkDetailMedia = (props) => {
    const params = useParams()
    const [showModal, setShowModal] = useState(false);
    const [workDetail, setWorkDetail] = useState([])
    const [looks, setLooks] = useState([])
    const [dataLooks, setDataLooks] = useState([])
    const history = props.history
    const accept = ["image/png", "image/jpeg", "image/jpg"];
    const obj=locationJSON(window.location)
    const [mediaEditorFields, setMediaEditorFields] = useState({ typeStoryName: '', year: '', month: '', typePhotographerName: '', link: '',  publication: '', username: ''})
    const [listUpdatedLooks, setListUpdatedLooks] = useState([]);
    const [list, setName] = useState([]);

    const getStoryWorkDetail = () => {
        props.getWorkDetail(params.workId)
        .then(result => {
            setWorkDetail(result)
            setLooks(result.story_board_looks)
            setDataLooks(result.story_board_looks)
            setMediaEditorFields({
                typeStoryName: result.name,
                year: result.year,
                month: result.month,
                typePhotographerName: result.type_photographer_name,
                link: result.link,
                publication: result.publication,
                username: result.username
            })
            let dataTemp = [];
            result.story_board_looks.forEach(element => {
              if (element.published) {
                dataTemp.push(element)
              }
            });
            setListUpdatedLooks(dataTemp)
        })
        .catch(err => {

            setWorkDetail([])
            setLooks([])
            setDataLooks([])
        })
    }

    useEffect(() => {
        getStoryWorkDetail();
        // eslint-disable-next-line
    }, [])

    const getLooks = looks => {
        return dataLooks.map((look, index) => (
            <div className="look" key={look.id}>
                <Link to={`/mediaLookDetail/${params.workId}/${look.id}`}>
                    <div>
                        <div className="look-img">
                            <img className="img" alt="" src={look.file.url} />
                        </div>
                        <Tooltip placement="bottomLeft" color="gray" title={look.name}><div className="look-name">{look.name}</div></Tooltip>
                    </div>
                </Link>
            </div>
        ))
    }

    return <Layout showBack={true} rightButton={<UploadFiles type="brand">Create A Collection</UploadFiles> }>
        <div className="work-detail">
        <div className="collection-name">{ mediaEditorFields.username } · { mediaEditorFields.typeStoryName }</div>
            <div className="collection-info-wraper">
                <div className="collection-info">
                    <div className="info-desc">
                        {
                            mediaEditorFields.description ? (
                                <span>
                                    { mediaEditorFields.description } ·&nbsp;
                                </span>
                            ) : null
                        }

                        {
                            mediaEditorFields.publication ? (
                                <span>
                                    { mediaEditorFields.publication } ·&nbsp;
                                </span>
                            ) : null
                        }

                        {
                            mediaEditorFields.month ? (
                                <span>
                                    { mediaEditorFields.month } &nbsp;
                                </span> 
                            ) : null
                        }

                        {
                            mediaEditorFields.year ? (
                                <span>
                                    { mediaEditorFields.year } ·&nbsp;
                                </span>
                            ) : null
                        }

                        {
                            mediaEditorFields.typePhotographerName ? (
                                <span>
                                    { mediaEditorFields.typePhotographerName }
                                </span>
                            ) : null
                        }

                    </div>
                </div>
            </div>
            
            <div className="collection-looks">
                {
                    getLooks(looks)
                }
            </div>
            
        </div>
    </Layout>
}

const mapStateToProps = (state) => {
    return {
        common: state.common,
        media: state.media
    }
}

const mapActionsToProps = {
    getWorkDetail,
    getWorkLookDetail,
}

export default connect(mapStateToProps, mapActionsToProps)(MediaWorkDetailMedia)
