import { Button } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import LayoutSimple from '../../components/Layout/simple';
import { storeUtil } from '../../utility';
import './style.scss';


const Forbidden = props => {

  const history = useHistory();
    return <LayoutSimple rightButton={null}>
        <div className="not-found">
            <div className="content">403 Forbidden:<br/> Access to this resource on the server is denied!
            <br/>

            <Button type="default" style={{borderColor: '#595959',color: '#595959',}} onClick={e=>{
                history.replace('/')
            }}>back to home</Button>
            &nbsp;
            <Button type="default" style={{borderColor: '#595959',color: '#595959',}} onClick={e=>{
                storeUtil.clear();
                history.replace('/')
            }}>sign out</Button>
            </div>
        </div>
    </LayoutSimple>
}


const mapStateToProps = (state) => {
    return {
        common: state.common
    }
}

const mapActionsToProps = {
}

export default connect(mapStateToProps, mapActionsToProps)(Forbidden);
