import React from 'react'
import './style.scss'
import {Select} from 'antd'

const FormSelect = props => {
    const {options, placeholder, onChange, defaultValue, value,customStyle={}}=props

    return <div className="my-select">
                {
                    value?(
                        <Select 
                            style={customStyle}
                            value={value}
                            onChange={onChange}
                            suffixIcon={<div className="icon-dropdown"></div>}
                            placeholder={placeholder} 
                            showSearch
                            dropdownClassName="my-option"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) === 0
                              }>
                            {
                                options.map((option,index)=>(
                                    <Select.Option key={index} {...option}>{option.name}</Select.Option>
                                ))
                            }
                        </Select>
                    ):(
                        <Select
                            style={customStyle}
                            defaultValue={defaultValue}
                            onChange={onChange} 
                            suffixIcon={<div className="icon-dropdown"></div>}
                            placeholder={placeholder} 
                            showSearch
                            dropdownClassName="my-option"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) === 0
                              }>
                            {
                                options.map((option,index)=>(
                                    <Select.Option key={index} {...option}>{option.name}</Select.Option>
                                ))
                            }
                        </Select>
                    )
                }
               
            </div>
}

export default FormSelect
