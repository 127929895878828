import AgentBrands from '../pages/agentBrands/'
import AgentMedias from '../pages/agentMedias/'
import Banners from '../pages/Banners'
import EditBanner from '../pages/Banners/EditBanner'
import BannerSorts from '../pages/BannerSorts'
import BrandCollectionDetail from '../pages/brandCollectionDetail/'
import BrandCollectionDetailBrandMedias from '../pages/brandCollectionDetailBrandMedias/'
import BrandCollections from '../pages/brandCollections/'
import BrandLookDetail from '../pages/brandLookDetail/'
import BrandLookDetailFromMedia from '../pages/brandLookDetailFromMedia'
import BrandMedias from '../pages/brandMedias/'
import ChangePassword from '../pages/changePassword/'
import Events from '../pages/Events'
import EditEvent from '../pages/Events/EditEvent'
import ForgotPassword from '../pages/forgotPassword'
import Home from '../pages/home/'
import Jobs from '../pages/Jobs'
import EditJob from '../pages/Jobs/EditJob'
import Login from '../pages/login/'
import MediaBrands from '../pages/mediaBrands/'
import MediaLookDetail from '../pages/mediaLookDetail/'
import MediaLookDetailByMedia from '../pages/mediaLookDetailByMedia'
import MediaWorkDetail from '../pages/mediaWorkDetail/'
import MediaWorkDetailMedia from '../pages/mediaWorkDetailMedia/'
import MediaWorks from '../pages/mediaWorks/'
import News from '../pages/News'
import EditNews from '../pages/News/EditNews'
import Notification from '../pages/notification/'
import Profile from '../pages/profile/'
import ProfileEdit from '../pages/profileEdit/'
import Register from '../pages/register/'
import ResetPassword from '../pages/resetPassword'
import Roles from '../pages/Roles'
import EditRole from '../pages/Roles/EditRole'
import SelfProfile from '../pages/selfProfile/'
import Users from '../pages/Users'
import EditUser from '../pages/Users/EditUser'
import Waitlist from '../pages/waitlist/'






const routes = [
  {
    path: "/",
    auth: true,
    component: Home,
  },
  {
    path: "/brandCollections",
    auth: true,
    component: BrandCollections,
  },
  {
    path: "/brandCollectionDetail/:collectionId",
    auth: true,
    component: BrandCollectionDetail,
  },
  {
    path: "/brandLookDetail/:collectionId/:lookId",
    auth: true,
    component: BrandLookDetail,
  },
  {
    path: "/brandLookDetailFromMedia/:collectionId/:lookId",
    auth: true,
    component: BrandLookDetailFromMedia,
  },
  {
    path: "/brandMedias",
    auth: true,
    component: BrandMedias,
  },

  {
    path: "/mediaWorks",
    auth: true,
    component: MediaWorks,
  },
  {
    path: "/mediaLookDetail/:workId/:lookId",
    auth: true,
    component: MediaLookDetail,
  },
  {
    path: "/mediaLookDetailByMedia/:workId/:lookId",
    auth: true,
    component: MediaLookDetailByMedia,
  },
  {
    path: "/mediaWorkDetail/:workId",
    auth: true,
    component: MediaWorkDetail,
  },
  {
    path: "/mediaBrands",
    auth: true,
    component: MediaBrands,
  },

  {
    path: "/agentBrands",
    auth: true,
    component: AgentBrands,
  },
  {
    path: "/agentMedias",
    auth: true,
    component: AgentMedias,
  },

  {
    path: "/notification",
    auth: true,
    component: Notification,
  },
  {
    path: "/profile/:id",
    auth: true,
    component: Profile,
  },
  {
    path: "/selfprofile/:id",
    auth: true,
    component: SelfProfile,
  },
  {
    path: "/profileEdit",
    auth: true,
    component: ProfileEdit,
  },
  {
    path: "/changePassword",
    auth: true,
    component: ChangePassword,
  },
  {
    path: "/brandCollectionDetailBrandMedias/:collectionId",
    auth: true,
    component: BrandCollectionDetailBrandMedias,
  },
  {
    path: "/mediaWorkDetailMedia/:workId",
    auth: true,
    component: MediaWorkDetailMedia,
  },
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/register/:token",
    component: Register,
  },
  {
    path: "/waitlist",
    component: Waitlist,
  },
  {
    path: "/forgotPassword",
    component: ForgotPassword,
  },
  {
    path: "/resetPassword/:reset_password_token",
    component: ResetPassword,
  },
  {
    path: "/events",
    auth: true,
    component: Events,
  },
  {
    path: "/events/:type",
    auth: true,
    component: EditEvent,
  },
  {
    path: "/jobs",
    auth: true,
    component: Jobs,
  },
  {
    path: "/jobs/:type",
    auth: true,
    component: EditJob,
  },
  {
    path: "/banners",
    auth: true,
    component: Banners,
  },
  {
    path: "/banners_sort",
    auth: true,
    component: BannerSorts,
  },
  {
    path: "/banners/:type",
    auth: true,
    component: EditBanner,
  },
  {
    path: "/roles",
    auth: true,
    component: Roles,
  },
  {
    path: "/roles/:type",
    auth: true,
    component: EditRole,
  },
  {
    path: "/users",
    auth: true,
    component: Users,
  },
  {
    path: "/users/:type",
    auth: true,
    component: EditUser,
  },
  {
    path: "/news",
    auth: true,
    component: News,
  },
  {
    path: "/news/:type",
    auth: true,
    component: EditNews,
  },
];

export default routes