import {
  Avatar,
  Button, Form, Input,
  List,
  Modal,
  Select, Transfer
} from "antd";
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import Layout from '../../components/Layout';
import {
  addRole, addUser, getAllBusinesses, getRoles,
  getUserRoles,
  getUsers
} from "../../redux/actions/common";
import './style.scss';


const { Search } = Input;

const Users = props => {
    const history = props.history
    const [collections, setCollections] = useState([])
    const [total, setTotal] = useState(0)
    const [current, setCurrent] = useState(1)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isBrandVisible, setIsBrandVisible] = useState(false);
    const [roles,setRoles] = useState([])
    const [targetKeys, setTargetKeys] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [currentUser, setCurrentUser] = useState({});
    const [search, setSearch] = useState({})
    const [businesses, setBusinesses] = useState([]);
    const [currentItem, setCurrentItem] = useState({});
    

    const showModal = (user) => {
        props.getUserRoles({...user}).then(res=>{
            setCurrentUser(res)
            setTargetKeys(res.role_ids)
            setIsModalVisible(true);
        })
    };

    const handleOk = () => {
        console.log(targetKeys,selectedKeys)
        props.addRole({id: currentUser.id,role_ids: targetKeys}).then(res=>{
            setCurrentUser({})
            setTargetKeys([])
            setSelectedKeys([])
            setIsModalVisible(false);
        })
    };


    const handleCreateOk = useCallback(() => {
      const business = businesses.find(
        (business) => business.business_user_mapping_id === currentItem.business_id
      );
      const role = roles.find((role) => role.id === currentItem.role_id);
      props
        .addUser({
          ...currentItem,
          business_name: business.name,
          business_type: business.business_type,
          user_type: "user",
        })
        .then((res) => {
          console.log(res);
          handleCancel();
        });
    },[currentItem]);

    const handleCancel = () => {
        setCurrentUser({})
        setTargetKeys([])
        setSelectedKeys([])
        setIsModalVisible(false);
        setIsBrandVisible(false);
    };

    const getCollections = useMemo(() => {
      return ()=>{
        props
          .getUsers({ page: current, ...search })
          .then((result) => {
            setCollections(result.data);
            setTotal(result.total);
          })
          .catch((err) => {
            setCollections([]);
          });
      }
    }, [current, search]);

    useEffect(() => {
        props.getRoles().then(res=>{
            setRoles(res.data)
        })
    }, [])
    useEffect(() => {
        getCollections();
    }, [current, getCollections])

    const onChange = (nextTargetKeys, direction, moveKeys) => {
        setTargetKeys(nextTargetKeys);
    };
    const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
        setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
    };

    const newAccount = () => {
      history.push(`/users/new`);
    };

    const addButton = (
      <div className="upload-button" onClick={newAccount}>
        <i className="icon-add"></i>Create An Account
      </div>
    );

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
    };

    return (
      <Layout rightButton={addButton}>
        <div className="users-collections">
          <div className="collection">
            <div className="collection-name">Users</div>
            <div className="collection-info">
              <div className="info-desc">{total} Users</div>
            </div>
            <div>
              <List
                itemLayout="vertical"
                size="large"
                header={[
                  <Form
                    layout="inline"
                    onValuesChange={(e) => {
                      setSearch({ ...search, ...e });
                    }}
                  >
                    <Form.Item label="Name" name="s">
                      <Input
                        placeholder="input user name or nick name"
                        style={{ width: 200, border: "none", color: "#000" }}
                        size="small"
                      />
                    </Form.Item>
                    <Form.Item label="Roles" name="r">
                      <Select
                        placeholder="select user role"
                        mode="multiple"
                        style={{
                          width: 200,
                        }}
                      >
                        <Select.Option key="all" value="all">
                          all
                        </Select.Option>
                        {roles.map((role) => (
                          <Select.Option key={role.name} value={role.name}>
                            {role.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Form>,
                ]}
                pagination={{
                  simple: true,
                  onChange: (page) => {
                    setCurrent(page);
                  },
                  pageSize: 20,
                  total,
                  showQuickJumper: true,
                }}
                rowKey="id"
                dataSource={collections}
                renderItem={(item, index) => {
                  let buttons = [
                    <Button
                      onClick={(e) => {
                        showModal(item);
                      }}
                    >
                      Add Role
                    </Button>,
                  ];
                  if (
                    !item.role_names.includes("wechat") &&
                    !item.role_names.includes("wechat_admin")
                  ) {
                    buttons.push(
                      <Button
                        onClick={(e) => {
                          history.push({
                            pathname: "/users/edit",
                            state: { id: item.id },
                          });
                        }}
                      >
                        Edit User
                      </Button>
                    );
                  }

                  return <List.Item extra={buttons}>
                    <List.Item.Meta
                      avatar={<Avatar src={item.avatar} />}
                      description={[
                        <p>{item.business_name}</p>,
                        <p>{item.nick_name}</p>,
                        <p>{item.role_names.join(",")}</p>,
                      ]}
                      title={item.username}
                    />
                  </List.Item>;
                }}
              />
            </div>
          </div>
          <Modal
            title="Add Role"
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
              <Button
                key="Cancel"
                style={{ borderColor: "#505050", color: "#505050" }}
                onClick={handleCancel}
              >
                Cancel
              </Button>,
              <Button
                key="Submit"
                style={{
                  background: "#505050",
                  borderColor: "#505050",
                  color: "#fff",
                }}
                onClick={handleOk}
              >
                Submit
              </Button>,
            ]}
          >
            <Transfer
              dataSource={roles}
              render={(item) => item.name}
              targetKeys={targetKeys}
              selectedKeys={selectedKeys}
              onChange={onChange}
              onSelectChange={onSelectChange}
            />
          </Modal>
        </div>
      </Layout>
    );
}


const mapStateToProps = (state) => {
    return {
        common: state.common
    }
}

const mapActionsToProps = {
  getUsers,
  addRole,
  getRoles,
  getUserRoles,
  getAllBusinesses,
  addUser,
};

export default connect(mapStateToProps, mapActionsToProps)(Users)