import React from 'react'
//import ReactDOM from 'react-dom';
import './style.scss'


const Modal = props => {
    const { children, onClose, title } = props

    // ReactDom.render 渲染的组件导致children插槽不实现更新 #更新直接替换导致输入被替换
    // useEffect(() => {
    //     let mask=document.createElement('div')

    //     const destroy=()=>{
    //         if(mask){
    //             document.body.classList.remove('over-hidden')
    //             ReactDOM.unmountComponentAtNode(mask)
    //             document.body.removeChild(mask)
    //             mask=null
    //         }

    //     }
    //     const close=()=>{
    //         destroy()
    //         onClose&&onClose()
    //     }
    //     const ModalWraper=()=>(
    //         <div className="modal">
    //             <div className="modal-close" onClick={close}></div>
    //             <div className="modal-title">{title}</div>
    //             <div className="modal-content">
    //                 {children}
    //             </div>
    //         </div>
    //     )
    //     mask.classList.add('mask')
    //     document.body.classList.add('over-hidden')
    //     document.body.appendChild(mask)
    //     ReactDOM.render(<ModalWraper></ModalWraper>, mask)

    //     return destroy
    // }, [children])

    // return ''




    // 第二种方案
    const close=()=>{
        onClose&&onClose()
    }
    
    return <div className="mask">
        <div className="modal">
            <div className="modal-close icon-close" onClick={close}></div>
            <div className="modal-title">{title}</div>
            <div className="modal-content">
                {children}
            </div>
        </div>
    </div>
}


export default Modal