import React, { useState } from 'react'
import {message} from 'antd'
import './style.scss'

import {images} from '../../utility/const'
import {validate} from '../../utility'
import FormSelect from '../../components/FormSelect'


const Waitlist=()=>{
    const [businessType,setBusinessType]=useState('')
    const [companyName,setCompanyName]=useState('')
    const [socialMedia,setSocialMedia]=useState('')
    const [workEmail,setWorkEmail]=useState('')

    const options=[{value:'Brand',name:"Brand"},{value:'Media',name:"Media"},{value:'Agency',name:"Agent"}]


    const onChange=(e)=>{
        if(typeof e == "string"){
            setBusinessType(e)
        }else{
            let name=e.target.name
            let value=e.target.value
            switch(name){
                case 'companyName':setCompanyName(value); break;
                case 'socialMedia':setSocialMedia(value); break;
                case 'workEmail':setWorkEmail(value); break;
            }
        }
    }

    const submit=()=>{
        if(validate.isEmpty(businessType)){
            return message.error({content:"请选择所属类型"})
        }
        if(validate.isEmpty(companyName)){
            return message.error({content:"请填写公司名称"})
        }
        if(validate.isEmpty(socialMedia)){
            return message.error({content:"请填写社交媒体"})
        }
        if(!validate.email(workEmail)){
            return message.error({content:"请填写正确的邮箱地址"})
        }

        const sitekey="KQlzwPLDyGZEnfSb4XRM3zgh0iFfpWFQ"
        window.Pageclip.send(sitekey, 'default', {
            businessType,
            companyName,
            socialMedia,
            workEmail
        }, function (error, response) {
            if(error){
                message.error({content:"发送失败，请稍后再试！"})
            }else{
                message.success({content:"发送成功,我们会尽快与您联系！"})
            }
        })
    }


    return  <div className="sign-wraper waitlist-wraper">
                <div className="sign-left"></div>

                <div className="sign-right">
                    <div className="logo-info">
                        <div className="logo">
                            <img alt="description Image" src={images.logo} />
                        </div>
                        <div className="company-name">
                            <img alt="description Image" src={images.ASB} />
                        </div>
                    </div>
                    <div className="sign-title">Join The Waitlist</div>
                    
                    <div className="form-wraper">  
                        <div className="form-input">
                            <FormSelect 
                                name="businessType"
                                placeholder="Brand/Media/Agency"
                                options={options} 
                                onChange={onChange} />
                        </div>
                        <div className="form-input">
                            <input className="input-text" 
                                name="companyName"
                                onChange={onChange}
                                type="text" placeholder="Company Name" />
                            <span className="input-required">*</span>
                        </div>
                        <div className="form-input">
                            <input className="input-text" 
                                    name="socialMedia"
                                    onChange={onChange}
                                type="text" placeholder="Website/Social Media" />
                            <span className="input-required">*</span>
                        </div>
                        <div className="form-input">
                            <input className="input-text" 
                                name="workEmail"
                                onChange={onChange}
                                type="text" placeholder="Work Email" />
                            <span className="input-required">*</span>
                        </div>
                    </div>
                    <div className="sign-btn" onClick={submit}> Submit  </div>
                </div>
            </div>
}
  
export default Waitlist
