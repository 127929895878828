import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "./style.scss";
import LayoutSimple from "../../components/Layout/simple";
import {
  createBanner,
  updateBanner,
  showBanner,
  getBannerSorts,
} from "../../redux/actions/common";
import { useParams, useLocation } from "react-router-dom";
import FormInput from "../../components/FormInput";
import {
  Button,
  Form,
  Row,
  Col,
  Select,
  message,
  Upload,
  InputNumber,
  DatePicker,
  Input,
  TimePicker,
} from "antd";
import moment from "moment";

import { storeUtil } from "../../utility";
const EditBanner = (props) => {
  const params = useParams();
  const location = useLocation();
  const history = props.history;
  const [banner, setBanner] = useState({
    status: "editing",
    images: [],
  });
  const [edited, setEdited] = useState(["new", "edit"].includes(params.type));

  const [form] = Form.useForm();
  const [bannnerSorts, setBannerSorts] = useState([]);
  useEffect(() => {
    props.getBannerSorts().then((res) => {
      setBannerSorts(res.data);
    });
    if (location.state) {
      props.showBanner(location.state).then((res) => {
        form.setFieldsValue({
          ...res,
          begin_time: res.begin_time && moment(res.begin_time), end_time: res.end_time && moment(res.end_time),
          image_url: res.image_url
            ? [
                {
                  uid: res.id,
                  url: res.image_url,
                  status: "done",
                  type: "image/jpeg",
                  name: "default.jpeg",
                },
              ]
            : [],
          images: res.images.map((img, index) => ({
            uid: index,
            url: img,
            status: "done",
            type: "image/jpeg",
            name: "default.jpeg",
          })),
        });
        setBanner(res);
      });
    }
  }, [location.state]);

  const saveBanner = async () => {
    await form.validateFields();
    const data = {
      banner: {
        ...banner,
        ...form.getFieldValue(),
        image_url: banner.image_url,
        images: banner.images,
      },
    };

    switch (params.type) {
      case "new":
        props.createBanner(data).then(() => {
          history.push(`/banners?type=banners`);
          message.success({ content: "Create success banner" });
        });
        break;
      case "edit":
        props.updateBanner(data).then(() => {
          history.push(`/banners?type=banners`);
          message.success({ content: "Create success banner" });
        });
        break;
      default:
        console.log("nothing to do");
    }
  };

  const renderButtons = () => {
    return (
      <div>
        <Button
          shape="round"
          style={{ borderColor: "#68C89E", color: "#68C89E", right: 15 }}
          onClick={saveBanner}
        >
          Save
        </Button>
        <Button
          type="default"
          style={{ borderColor: "#595959", color: "#595959" }}
          shape="round"
          onClick={() => {
            if (!edited) {
              history.push(`/banners?type=banners`);
            } else {
              setEdited(false);
            }
          }}
        >
          Cancel
        </Button>
      </div>
    );
  };
  const uploadProps = {
    name: "file",
    listType: "picture-card",
    action: "/bl/api/v1/event_banners",
    headers: {
      Authorization: storeUtil.get("token") || "",
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        // setFileList([])
      }
      if (status === "done") {
        form.setFieldsValue({
          image_url: [
            {
              uid: 1,
              url: info.file.response.url,
              status: "done",
              type: "image/jpeg",
              name: "default.jpeg",
            },
          ],
        });
        // setFileList([{uid: 1,url: info.file.response.url}])
        setBanner({
          ...banner,
          image_url: info.file.response.url,
        });
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onRemove(e) {
      form.setFieldsValue({ image_url: [] });
      setBanner({
        ...banner,
        image_url: null,
      });
    },
    onPreview: async (file) => {
      let src = file.url;
      if (!src) {
        src = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.readAsDataURL(file.originFileObj);
          reader.onload = () => resolve(reader.result);
        });
      }
      const image = new Image();
      image.src = src;
      const imgWindow = window.open(src);
      imgWindow.document.write(image.outerHTML);
    },
  };

  const imagesProps = {
    name: "file",
    listType: "picture-card",
    action: "/bl/api/v1/event_banners",
    headers: {
      Authorization: storeUtil.get("token") || "",
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        // setFileList([])
      }
      if (status === "done") {
        // form.setFieldsValue({images: [...form.getFieldValue('images'),{uid: 1,url: info.file.response.url,status: 'done',type:'image/jpeg',name:'default.jpeg'}] })
        // setFileList([{uid: 1,url: info.file.response.url}])
        setBanner({
          ...banner,
          images: [...banner.images, info.file.response.url],
        });
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onRemove(e) {
      // const imageList = form.getFieldValue('images')
      // form.setFieldsValue({images: imageList.filter(url=>url !== e.url) })
      setBanner({
        ...banner,
        images: banner.images && banner.images.filter((url) => url !== e.url),
      });
    },
    onPreview: async (file) => {
      let src = file.url;
      if (!src) {
        src = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.readAsDataURL(file.originFileObj);
          reader.onload = () => resolve(reader.result);
        });
      }
      const image = new Image();
      image.src = src;
      const imgWindow = window.open(src);
      imgWindow.document.write(image.outerHTML);
    },
  };
  const statusMap = { editing: "Editing", opened: "Opened", closed: "Closed" };
  const uploadButton = (
    <div>
      +<div style={{ marginTop: 8 }}>Upload Cover</div>
    </div>
  );

  const imagesButton = (
    <div>
      +<div style={{ marginTop: 8 }}>Upload Image</div>
    </div>
  );

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  function range(start, end) {
    let result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  }

  const disabledDateTime = () => {
    return {
      disabledSeconds: () => range(0, 60),
    };
  };

  const checkRequired = (id) => {
    const sorts = bannnerSorts.find((s) => s.id === id);
    return sorts && sorts.name === "hot_spot";
  };

  return (
    <LayoutSimple>
      <div className="banners-body">
        <Form
          form={form}
          layout="vertical"
          noStyle
          initialValues={{ ...banner }}
        >
          <div className={`main-content`} id="main-content">
            <div className="basic item-relative" id="basic">
              <div className="title">Banner Info</div>
              <div className="form-items">
                <Form.Item
                  name="image_url"
                  noStyle
                  label="Main Picture"
                  rules={[
                    {
                      required: false,
                      message: "Please input your main picture",
                    },
                  ]}
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                >
                  <Upload {...uploadProps} className="avatar-uploader">
                    {uploadButton}
                  </Upload>
                </Form.Item>

                <Form.Item
                  name="images"
                  noStyle
                  label="Banner Images"
                  rules={[
                    {
                      required: false,
                      message: "Please input your banner images",
                    },
                  ]}
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                >
                  <Upload {...imagesProps} className="avatar-uploader">
                    {imagesButton}
                  </Upload>
                </Form.Item>
                <Row gutter={[16, 0]}>
                  <Col span={10}>
                    <Form.Item
                      name="name"
                      label="Banner Name"
                      rules={[
                        {
                          required: true,
                          message: "Please input your banner name",
                        },
                      ]}
                    >
                      <FormInput
                        placeholder="Input your banner name"
                        readOnly={!edited}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={10} offset={2}>
                    <Form.Item
                      name="banner_sort_id"
                      label="Banner Sort"
                      rules={[
                        {
                          required: true,
                          message: "Please select your banner sort",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder="Select your banner sort"
                        disabled={!edited}
                      >
                        {bannnerSorts.map((banner_sort, index) => (
                          <Select.Option
                            key={index}
                            value={banner_sort.id}
                            label={banner_sort.name}
                          >
                            {banner_sort.desc}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, curValues) => {
                    return (
                      prevValues.banner_sort_id !== curValues.banner_sort_id &&
                      checkRequired(curValues.banner_sort_id)
                    );
                  }}
                >
                  {() =>
                    checkRequired(form.getFieldValue("banner_sort_id")) ? (
                      <>
                        <Row gutter={[16, 0]}>
                          <Col span={10}>
                            <Form.Item
                              name="begin_time"
                              label="Begin Time"
                              rules={[
                                {
                                  required: checkRequired(
                                    form.getFieldValue("banner_sort_id")
                                  ),
                                  message: "Please select begin time",
                                },
                              ]}
                            >
                              <TimePicker
                                format="HH:mm"
                                disabledTime={disabledDateTime}
                                // showTime={{
                                //   defaultValue: moment("00:00", "HH:mm"),
                                // }}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={10} offset={2}>
                            <Form.Item
                              name="end_time"
                              label="End Time"
                              rules={[
                                {
                                  required: checkRequired(
                                    form.getFieldValue("banner_sort_id")
                                  ),
                                  message: "Please select end time",
                                },
                              ]}
                            >
                              <TimePicker
                                format="HH:mm"
                                disabledTime={disabledDateTime}
                                // showTime={{
                                //   defaultValue: moment("12:00", "HH:mm"),
                                // }}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={[16, 48]}>
                          <Col span={22}>
                            <Form.Item
                              label="Address"
                              name="address"
                              rules={[
                                {
                                  required: checkRequired(
                                    form.getFieldValue("banner_sort_id")
                                  ),
                                  message: "Please input your address",
                                  type: "string",
                                },
                              ]}
                            >
                              <FormInput
                                placeholder="Input your address"
                                readOnly={!edited}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </>
                    ) : null
                  }
                </Form.Item>

                <Row gutter={[16, 48]}>
                  <Col span={22}>
                    <Form.Item
                      name="desc"
                      label="Description"
                      rules={[
                        {
                          required: true,
                          message: "Please input your description",
                        },
                      ]}
                    >
                      <FormInput
                        placeholder="Input your description"
                        readOnly={!edited}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[16, 48]}>
                  <Col span={22}>
                    <Form.Item
                      name="link"
                      label="Target Link"
                      rules={[
                        {
                          required: false,
                          message: "Please input your target link",
                        },
                      ]}
                    >
                      <FormInput
                        placeholder="Input your target link"
                        readOnly={!edited}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[16, 48]}>
                  <Col span={10}>
                    <Form.Item
                      name="status"
                      label="Status"
                      rules={[
                        {
                          required: true,
                          message: "Please select your status",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select your status"
                        disabled={!edited}
                      >
                        {Object.keys(statusMap).map((key, index) => (
                          <Select.Option key={index} value={key}>
                            {statusMap[key]}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={10} offset={2}>
                    <Form.Item
                      name="sort"
                      label="Sort Number"
                      rules={[
                        {
                          required: true,
                          message: "Please select your sort number",
                        },
                      ]}
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        min={1}
                        max={100000}
                        step={1}
                        readOnly={!edited}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item>{renderButtons()}</Form.Item>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </LayoutSimple>
  );
};

const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};

const mapActionsToProps = {
  updateBanner,
  createBanner,
  showBanner,
  getBannerSorts,
};

export default connect(mapStateToProps, mapActionsToProps)(EditBanner);
