
import { combineReducers } from "redux";
import brand from "./brand";
import common from "./common"

const rootReducer = combineReducers({
    common,
    brand,
});

export default rootReducer;
