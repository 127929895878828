const common = (state = {}, action) => {
    
    switch (action.type) {
        case "setUserInfo":
            return {
                ...state,
                ...action.payload
            };
            
        case 'switchAccountType':
            state.userInfo.business_type=action.payload.business_type
            return {
                ...state
            }

        default:
            return {
                ...state
            };
    }
}


export default common