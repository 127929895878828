import UploadAdapter from '@ckeditor/ckeditor5-adapter-ckfinder/src/uploadadapter'
import Autoformat from '@ckeditor/ckeditor5-autoformat/src/autoformat'
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold'
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic'
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote'
import CKFinder from '@ckeditor/ckeditor5-ckfinder/src/ckfinder'
import CloudServices from '@ckeditor/ckeditor5-cloud-services/src/cloudservices'
import EasyImage from '@ckeditor/ckeditor5-easy-image/src/easyimage'
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor'
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials'
import Heading from '@ckeditor/ckeditor5-heading/src/heading'
import Image from '@ckeditor/ckeditor5-image/src/image'
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption'
import ImageResizeEditing from '@ckeditor/ckeditor5-image/src/imageresize/imageresizeediting'
import ImageResizeHandles from '@ckeditor/ckeditor5-image/src/imageresize/imageresizehandles'
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle'
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar'
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload'
import Indent from '@ckeditor/ckeditor5-indent/src/indent'
import Link from '@ckeditor/ckeditor5-link/src/link'
import List from '@ckeditor/ckeditor5-list/src/list'
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph'
import PasteFromOffice from '@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import Table from '@ckeditor/ckeditor5-table/src/table'
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar'
import TextTransformation from '@ckeditor/ckeditor5-typing/src/texttransformation'
import { Button, Col, DatePicker, Form, Input, InputNumber, message, Modal, Row, Select, Space, Switch, Typography, Upload } from 'antd'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import FormInput from '../../components/FormInput'
import LayoutSimple from '../../components/Layout/simple'
// import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
import * as mapUtils from '../../components/TMapGL/index'
import { createEvent, getAllBusinesses, showEvent, updateEvent } from '../../redux/actions/common'
import { storeUtil } from '../../utility'
import MyUploadAdapter from './components/MyUploadAdapter'
import './style.scss'






ClassicEditor.defaultConfig = {
    plugins: [Essentials,
        UploadAdapter,
        Autoformat,
        Bold,
        Italic,
        BlockQuote,
        CKFinder,
        CloudServices,
        EasyImage,
        Heading,
        Image,
        ImageCaption,
        ImageStyle,
        ImageToolbar,
        ImageUpload,
        Indent,
        Link,
        List,
        Paragraph,
        PasteFromOffice,
        Table,
        TableToolbar,
        TextTransformation,
        ImageResizeEditing,
        ImageResizeHandles],
    toolbar: {
        // eslint-disable-next-line no-sparse-arrays
        items: [
            'undo',
            'redo',
            'heading',
            'fontfamily', 'fontsize', '|',
            'alignment',                                                 // <--- ADDED
            'bold',
            'italic',
            'link', 'strikethrough',
            'bulletedList',
            'numberedList',
            '|',
            'outdent', 'indent',
            '|', 'uploadImage', ,
            'imageResize',
            // 'imageTextAlternative',
            'blockQuote',
            'resizeImage'
        ],
        viewportTopOffset: 30,
        shouldNotGroupWhenFull: true,
    },
    image: {
        toolbar: [
            'imageStyle:inline',
            'imageStyle:block',
            'imageStyle:side',
            '|',
            'toggleImageCaption',
            'autoImage'
        ],

    },
    // This value must be kept in sync with the language defined in webpack.config.js.
    language: 'en',
    height: '500',
    ckfinder: {
        uploadUrl: '/bl/api/v1/event_banners',
        headers: {
            Authorization: storeUtil.get("token") || ""
        },
    },
    enterMode: CKEditor.ENTER_BR,
    shiftEnterMode: CKEditor.ENTER_P

};


const EditEvent = (props) => {
    const { common: { userInfo } } = props
    const params = useParams();
    const location = useLocation();
    const history = props.history
    const [event, setEvent] = useState({
        need_qrcode: false,
        hidden: false
    })
    const [edited, setEdited] = useState(['new', 'edit'].includes(params.type))

    const [form] = Form.useForm();
    const [businesses, setBusinesses] = useState([])
    const [fileList] = useState([])
    const [mapVisable, setMapVisable] = useState(false)
    console.log(userInfo)
    useEffect(() => {
        props.getAllBusinesses().then(res => {
            if (userInfo.roles.includes('admin')) {
                setBusinesses(res)
            } else {
                const [first, ...last] = res
                setEvent({ ...event, brand: first.id, brand_image: first.logo })
            }
        })
        if (location.state) {
            props.showEvent(location.state).then(res => {
                form.setFieldsValue({ ...res, start_date: res.start_date && moment(res.start_date), end_date: res.end_date && moment(res.end_date), banner_image: res.banner_image ? [{ uid: res.id, url: res.banner_image, status: 'done', type: 'image/jpeg', name: 'default.jpeg' }] : [] })
                // res.banner_image && setFileList([{uid: res.id,url: res.banner_image,status: 'done',type:'image/jpeg',name:'default.jpeg'}])
                setEvent({ ...event, ...res })
            });
        }
    }, [])

    const saveEvent = async () => {
        await form.validateFields();
        const data = {
            event: { ...event, ...form.getFieldValue(), banner_image: event.banner_image, brand_image: event.brand_image }
        }

        switch (params.type) {
            case 'new':
                props.createEvent(data).then(() => {
                    history.push(`/events?type=events`);
                    message.success({ content: "Create success event" })
                })
                break;
            case 'edit':
                props.updateEvent(data).then(() => {
                    history.push(`/events?type=events`);
                    message.success({ content: "Create success event" })
                })
                break;
            default:
                break;
        }


    }

    const renderButtons = () => {
        return (<div>
            <Button shape="round" style={{ borderColor: '#68C89E', color: '#68C89E', right: 15 }} onClick={saveEvent}>Save</Button>
            <Button type="default" style={{ borderColor: '#595959', color: '#595959', }} shape="round" onClick={() => {
                if (!edited) {
                    history.push(`/events?type=events`);
                } else {
                    setEdited(false)
                }

            }}>Cancel</Button>
        </div>)
    }

    const readyCK = (editor) => {
        editor.isReadOnly = !edited;
        if (edited) {
            editor.plugins.get("FileRepository").createUploadAdapter = function (loader) {
                return new MyUploadAdapter(loader, '/bl/api/v1/event_banners', {
                    Authorization: storeUtil.get("token") || ""
                });
            };

            editor.editing.view.document.on('enter', (evt, data) => {
                if (data.isSoft) {
                    editor.execute('enter');
                } else {
                    editor.execute('shiftEnter');
                }

                data.preventDefault();
                evt.stop();
                editor.editing.view.scrollToTheSelection();
            }, { priority: 'high' });
        }
    }
    const uploadProps = {
        name: 'file',
        listType: "picture-card",
        action: '/bl/api/v1/event_banners',
        headers: {
            Authorization: storeUtil.get("token") || ""
        },
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                // setFileList([])
            }
            if (status === 'done') {
                form.setFieldsValue({ banner_image: [{ uid: 1, url: info.file.response.url, status: 'done', type: 'image/jpeg', name: info.file.response.name }] })
                // setFileList([{uid: 1,url: info.file.response.url}])
                setEvent({
                    ...event,
                    banner_image: info.file.response.url,
                })
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        onRemove(e) {
            form.setFieldsValue({ banner_image: [] })
            setEvent({
                ...event,
                banner_image: null,
            })
        },
        onPreview: async file => {
            let src = file.url;
            if (!src) {
                src = await new Promise(resolve => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file.originFileObj);
                    reader.onload = () => resolve(reader.result);
                });
            }
            const image = new Image();
            image.src = src;
            const imgWindow = window.open(src);
            imgWindow.document.write(image.outerHTML);
        }
    };
    const typeMap = { public: 'Public', waitlist: 'Waitlist', invitation: 'Invitation' }
    const categoryMap = { fashion: 'Fashion', art: 'Art', music: 'Music', charity: 'Charity', others: 'Others' }
    const uploadButton = (
        <div>
            +
            <div style={{ marginTop: 8 }}>Upload Banner</div>
        </div>
    );

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    const mapId = useRef() //  地图实例

    const search = useRef() //  地图实例
    const markers = useRef() //  地图实例
    const handleVisible = () => {
        setMapVisable(true);
        mapUtils.TMapGL().then(() => {

            let s = new window.TMap.service.Search({ pageSize: 10 });
            let ipLocation = new window.TMap.service.IPLocation();
            let map = new window.TMap.Map(document.querySelector('#container'), {
                zoom: 16.2,
                baseMap: [
                    { type: 'vector' }, //设置矢量底图
                    // { type: 'traffic' }, //设置路况底图
                ],
            })

            ipLocation.locate({}).then(res => {
                const { result } = res;
                map.setCenter(result.location)
            })


            let m = new window.TMap.MultiMarker({
                map: map,
                geometries: [],
            });
            mapId.current = map //存储当前的map
            search.current = s
            markers.current = m
        })
    }
    const [infoWindowList] = useState([])
    const handleSearch = (value) => {
        markers.current.setGeometries([]);
        search.current.searchRectangle({ keyword: value, bounds: mapId.current.getBounds() }).then((res) => {
            if (res.data && res.data.length) {
                mapId.current.setCenter(res.data[0].location)
            }
            res.data.forEach((item, index) => {
                var geometries = markers.current.getGeometries();
                var infoWindow = new window.TMap.InfoWindow({
                    map: mapId.current,
                    position: item.location,
                    content: `<h3>${item.title}</h3><p>地址：${item.address}</p>`,
                    offset: { x: 0, y: -50 }
                }); // 新增信息窗体显示地标的名称与地址、电话等信息
                infoWindow.close();
                infoWindowList[index] = infoWindow;
                geometries.push({
                    id: String(index), // 点标注数据数组
                    position: item.location,
                    value: `${item.title},${item.address}`
                });
                markers.current.updateGeometries(geometries); // 绘制地点标注
                markers.current.on('click', (e) => {
                    infoWindowList[Number(e.geometry.id)].open();
                    form.setFieldsValue({ real_address: e.geometry.value })
                    setEvent({ ...event, real_address: e.geometry.value })
                }); // 点击标注显示信息窗体
            });
        })
    }

    const searchButton = <Button style={{ borderColor: '#505050', color: '#505050' }}>Search</Button>

    function range(start, end) {
        let result = [];
        for (let i = start; i < end; i++) {
            result.push(i);
        }
        return result;
    }

    const disabledDate = (current) => {
        // Can not select days before today and today
        return current && current < moment().endOf('day');
    }

    const disabledDateTime = () => {
        return {
            disabledDate,
            disabledSeconds: () => range(0, 60),
        };
    }

    return (
      <LayoutSimple>
        <div className="body">
          <Form
            form={form}
            layout="vertical"
            noStyle
            initialValues={{ ...event }}
          >
            <div className={`main-content`} id="main-content">
              <div className="basic item-relative" id="basic">
                <div className="title">Event Info</div>
                <div className="form-items">
                  <Row gutter={[16, 0]}>
                    <Col span={userInfo.roles.includes("admin") ? 10 : 22}>
                      <Form.Item
                        name="name"
                        label="Event Name"
                        rules={[
                          {
                            required: true,
                            message: "Please input your event name",
                          },
                        ]}
                      >
                        <FormInput
                          placeholder="Input your event name"
                          readOnly={!edited}
                        />
                      </Form.Item>
                    </Col>
                    {userInfo.roles.includes("admin") ? (
                      <Col span={10} offset={2}>
                        <Form.Item
                          name="brand"
                          label="Brand"
                          rules={[
                            {
                              required: true,
                              message: "Please select your brand",
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            placeholder="Select your brand"
                            filterOption={(input, option) => {
                              return (
                                option.label
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              );
                            }}
                            disabled={!edited}
                            onChange={(value, option) => {
                              setEvent({
                                ...event,
                                brand_image: option.img,
                              });
                            }}
                          >
                            {businesses.map((business, index) => (
                              <Select.Option
                                key={index}
                                value={`${business.id.toString()}`}
                                label={business.name}
                                img={business.logo}
                              >
                                <div className="demo-option-label-item">
                                  <span role="img" aria-label="China">
                                    <img
                                      src={business.logo}
                                      style={{ height: 25 }}
                                    />
                                  </span>
                                  {business.name}
                                </div>
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    ) : null}
                  </Row>
                  <Row gutter={[16, 48]}>
                    <Col span={10}>
                      <Form.Item
                        name="type"
                        label="Type"
                        rules={[
                          {
                            required: true,
                            message: "Please select your type",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Select your type"
                          disabled={!edited}
                        >
                          {Object.keys(typeMap).map((key, index) => (
                            <Select.Option key={index} value={key}>
                              {typeMap[key]}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={10} offset={2}>
                      <Form.Item
                        name="category"
                        label="Category"
                        rules={[
                          {
                            required: true,
                            message: "Please select your category",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Select your category"
                          disabled={!edited}
                        >
                          {Object.keys(categoryMap).map((key, index) => (
                            <Select.Option key={index} value={key}>
                              {categoryMap[key]}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[16, 48]}>
                    <Col span={10}>
                      <Form.Item
                        name="start_date"
                        label="Start Date"
                        rules={[
                          {
                            required: true,
                            message: "Please select start date",
                          },
                        ]}
                      >
                        <DatePicker
                          format="YYYY-MM-DD HH:mm"
                          // disabledTime={disabledDateTime}
                          showTime
                        />
                      </Form.Item>
                    </Col>
                    <Col span={10} offset={2}>
                      <Form.Item
                        name="end_date"
                        label="End Date"
                        rules={[
                          { required: true, message: "Please select end date" },
                        ]}
                      >
                        <DatePicker
                          format="YYYY-MM-DD HH:mm"
                          // disabledTime={disabledDateTime}
                          showTime
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[16, 48]}>
                    <Col span={10}>
                      <Form.Item
                        name="address"
                        label="Address"
                        rules={[
                          {
                            required: true,
                            message: "Please input your address",
                          },
                        ]}
                      >
                        <FormInput
                          placeholder="Input your address"
                          readOnly={!edited}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={10} offset={2}>
                      <Form.Item
                        name="real_address"
                        label="Real Address"
                        rules={[
                          {
                            required: true,
                            message: "Please input your real address",
                          },
                        ]}
                      >
                        <FormInput
                          placeholder="Input your real address" /**readOnly={true} onClick={handleVisible} */
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[16, 48]}>
                    <Col span={10}>
                    <Form.Item label="Limit">
                      <Space>
                        <Form.Item name="upper_limit" noStyle>
                          <InputNumber placeholder="input limit" />
                        </Form.Item>
                        <Typography.Text>
                          请填入场人数上限，如无限制请输入 0 或留空
                        </Typography.Text>
                      </Space>
                    </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[16, 48]}>
                    <Col span={10}>
                      <Form.Item
                        name="upper_limit"
                        label="Limit"
                      >
                        <InputNumber placeholder="input limit"/>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[16, 48]}>
                    <Col span={10}>
                      <Form.Item
                        name="need_qrcode"
                        label="Need QR Code"
                        rules={[
                          { required: true, message: "Please select Yes/No" },
                        ]}
                        valuePropName="checked"
                      >
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          defaultChecked
                        />
                      </Form.Item>
                    </Col>
                    <Col span={10} offset={2}>
                      <Form.Item
                        name="hidden"
                        label="Hidden"
                        rules={[
                          { required: true, message: "Please select Yes/No" },
                        ]}
                        valuePropName="checked"
                      >
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          defaultChecked
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item
                    name="banner_image"
                    label="Banner Image"
                    rules={[
                      {
                        required: true,
                        message: "Please input your banner images",
                      },
                    ]}
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                  >
                    <Upload {...uploadProps}>
                      {fileList.length == 0 && uploadButton}
                    </Upload>
                  </Form.Item>
                  <Row gutter={[16, 48]}>
                    <Col span={22}>
                      <Form.Item
                        name="brief"
                        label="Brief"
                        rules={[
                          {
                            required: true,
                            message: "Please input your banner brief",
                          },
                        ]}
                      >
                        <CKEditor
                          editor={ClassicEditor}
                          onReady={(editor) => readyCK(editor)}
                          data={form.getFieldValue("brief")}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            form.setFieldsValue({
                              ...form.getFieldsValue(),
                              brief: data,
                            });
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[16, 48]}>
                    <Col span={22}>
                      <Form.Item>{renderButtons()}</Form.Item>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </Form>
          <Modal
            visible={mapVisable}
            width={800}
            footer={null}
            onCancel={(e) => {
              setMapVisable(false);
            }}
          >
            <div style={{ margin: "auto" }}>
              <Input.Search
                placeholder="input address"
                allowClear
                enterButton={searchButton}
                onSearch={handleSearch}
                style={{ width: 400 }}
              />
              {mapVisable && (
                <div
                  id="container"
                  style={{ height: 400, width: "100%" }}
                ></div>
              )}
            </div>
          </Modal>
        </div>
      </LayoutSimple>
    );
}

const mapStateToProps = (state) => {
    return {
        common: state.common
    }
}

const mapActionsToProps = {
    getAllBusinesses,
    updateEvent,
    createEvent,
    showEvent
}

export default connect(mapStateToProps, mapActionsToProps)(EditEvent)
