import * as request from '../../request'



export const getAllCollections = (season_type) => async (dispatch, getState) => {
    const state = getState()

    // console.log(state.brand['collections_' + season_type]);

    // if (state.brand['collections_' + season_type]) {
    //     return state.brand['collections_' + season_type]
    // } else {
    //     const response = await request.getAllCollections(season_type)
    //     await dispatch({
    //         type: "getCollections",
    //         payload: { ['collections_' + season_type]: response }
    //     });
    //     return response;
    // }

    const response = await request.getAllCollections(season_type)
    await dispatch({
        type: "getCollections",
        payload: { ['collections_' + season_type]: response }
    });
    return response;
}

export const getCollectionDetail = id => async (dispatch, getState) => {
    const state = getState()

    const response = await request.getCollectionDetail(id)
    await dispatch({
        type: "getCollectionDetail",
        payload: response
    });
    return response;
}

export const getCollectionDetailSample = collection_id => async (dispatch, getState) => {
    const state = getState()

    const response = await request.getCollectionDetailSample(collection_id)
    await dispatch({
        type: "getCollectionDetailSample",
        payload: response
    });
    return response;
}

export const getCollectionDetailClipping = collection_id => async (dispatch, getState) => {
    const state = getState()

    const response = await request.getCollectionDetailClipping(collection_id)
    await dispatch({
        type: "getCollectionDetailClipping",
        payload: response
    });
    return response;
}

export const getAllSeasons = id => async (dispatch, getState) => {
    let state = getState()
    if (state.brand.seasons) {
        return state.brand.seasons
    } else {
        const response = await request.getAllSeasons()
        await dispatch({
            type: "getAllSeasons",
            payload: response
        });
        return response;
    }
}

export const getBrandLookDetail = id => async (dispatch, getState) => {
    const response = await request.getBrandLookDetail(id)
    await dispatch({
        type: "getBrandLookDetail",
        payload: response
    });
    return response;
}

export const getBrandLookDetailSamples = id => async (dispatch, getState) => {
    const response = await request.getBrandLookDetailSamples(id)
    await dispatch({
        type: "getBrandLookDetailSamples",
        payload: response
    });
    return response;
}

export const getAllBrandMedias = lastId => async (dispatch, getState) => {
    const response = await request.getAllBrandMedias(lastId)
    await dispatch({
        type: "getAllBrandMedias",
        payload: response
    });
    return response;
}

export const getMyBrandMedias = id => async (dispatch, getState) => {
    const state = getState()

    const response = await request.getMyBrandMedias(id)
    await dispatch({
        type: "getMyBrandMedias",
        payload: response
    });
    return response;
}

export const updateCollection = id => async (dispatch, getState) => {
    const state = getState()

    const response = await request.updateCollection(id)
    await dispatch({
        type: "getCollectionDetail",
        payload: response
    });
    return response;
}

export const deleteCollection = id => async (dispatch, getState) => {
    const response = await request.deleteCollection(id)
    return response;
} 

export const getColectionAgencyBrand = params => async (dispatch, getState) => {
    const response = await request.getColectionAgencyBrand(params)
    return response;
}

export const deleteAllLooks = (id, params) => async (dispatch, getState) => {
    const response = await request.deleteAllLooks(id, params)
    return response;
}


export const updateCollectionLook = params => async (dispatch, getState) => {
    const response = await request.updateCollectionLook(params)
    return response;
}