import React, { useState, useEffect } from 'react'
import {Link, useHistory} from 'react-router-dom'
import {connect} from 'react-redux'
import {Input, Upload, message, Modal, Button} from 'antd'
import './style.scss'
import {storeUtil} from '../../utility'
import {images} from '../../utility/const'
import LayoutSimple from '../../components/Layout/simple'
import FormSelectSearch from '../../components/FormSelectSearch'
import FormSelect from '../../components/FormSelect'
import {getListPublication} from '../../request'
import { updateBusinessProfile, getBusinessProfile, resetPassword, uploadAvatar, updateDecode } from '../../redux/actions/common'
import listCountry from '../../country'
import { CameraOutlined, UploadOutlined } from '@ant-design/icons';
import {getAllCollections} from '../../redux/actions/brand'
import FormSelectSearchPhoto from '../../components/FormSelectSearchPhoto'
import Avatar from 'react-avatar-edit'
import base64 from 'file-base64'

const ProfileEdit = (props) => {
    const userInfo = storeUtil.get('user')
    const history = useHistory()
    const optionsCountry = listCountry
    const [businessProfile, setBusinessProfile] = useState([])
    let dataProfileTemp = {
        name: businessProfile?.name || '',
        email: businessProfile.email,
        phone: businessProfile.phone,
        country: businessProfile?.country || '',
        province: businessProfile?.province || '',
        city: businessProfile?.city || '',
        password: '',
        description: businessProfile?.description,
        description1: businessProfile?.description1,
        publication: businessProfile?.publication,
    }
    const [dataProfile, setProfile] = useState(dataProfileTemp)
    const [avatar, setAvatar] = useState('')
    const [collections, setCollections]=useState([])
    const [optionsOnlyCollections, setOptionsOnlyCollections]=useState([])
    const [listPublication, setListPublication] = useState([])
    const [preview, setPreview] = useState([])
    const [visible, setVisible] = useState(false)
    const [avatarUpload, setAvatarUpload] = useState('')
    const [widthUploadImg,setWidthUploadImg] = useState('')
    const [heightUploadImg,setHeightUploadImg] = useState('')
    const [state,setState] = React.useState({src:null})
    let nameImage = ''

    const aRef = React.useRef();

    const onChange = (name, value)=>{
        if (name === 'country') {
            setProfile({...dataProfile, country: value})
        } else if (name === 'province'){
            setProfile({...dataProfile, province: value})
        } else if (name === 'city'){
            setProfile({...dataProfile, city: value})
        } else if (name === 'onlyCollection'){
            setProfile({...dataProfile, description: value})
        }else if (name === 'listPublication'){
            setProfile({...dataProfile, publication: value})
        }
    }

    const handleInputChange = e => {         
        const {name, value} = e.target
        setProfile({...dataProfile, [name]: value})
    }

    const saveProfile = () => {
        const params = {
            id: userInfo.id,
            business_id: userInfo.business_id,
            data: dataProfile
        }
        props.updateBusinessProfile(params).then(() => {
            history.push(`/profile/${userInfo.business_id}`);
            message.success({ content:"Update success profile" })
            userInfo.business_name = dataProfile.name
            storeUtil.set('user',userInfo)
        })
        updateDecode()

    }

    const getItems=()=>{
        props.getBusinessProfile(userInfo.business_id)
        .then(result=>{
            setBusinessProfile(result)
            const dataProfileTemp = {
                name: result?.name || '',
                phone: result.phone,
                country: result?.country || '',
                province: result?.province || '',
                city: result?.city || '',
                password: '',
                description: result?.description,
                description1: result?.description1,
                email: userInfo.email,
                publication: result.publication
            }
            setProfile(dataProfileTemp)
        })
        .catch(err=>{
            setBusinessProfile([])
        })
    }

    const resetPasswork = () => {
        props.resetPassword().then(() => {
            message.success({ content:"Reset Password success. Please check email!" })
        })
    }

    useEffect(() => {
        getItems();
        if (userInfo.business_type === "brand") {
            getCollections();
        }
        if (userInfo.avatar) {  
            setAvatar(userInfo.avatar.blob.service_url)
        }
    }, [])

    const getCollections=()=>{
        let season_type='all'
        props.getAllCollections(season_type)
        .then(result=>{ 
            setCollections(result)
            if (result && result.lenght <= 4) {
                setOptionsOnlyCollections(result.collection_looks)
            } else {
                const dataTemp = result.splice(0, 4)
                setOptionsOnlyCollections(dataTemp)
            }
        })
        .catch(err=>{
            setCollections([])
        })
    }

    const getImageData = (evt) => {
        setAvatarUpload(evt.target.files[0])
        const reader = new FileReader();
        reader.readAsDataURL(avatarUpload);
        reader.onload = e => {
            setAvatar(e.target.result)
        };

        uploadAvatar()
    }

    const updateDecode = () => {
        const params = {
            id: userInfo.id,
            data: {
                business_name: dataProfile.name
            }
        }
        props.updateDecode(params)
    }

    const uploadAvatar = () => {
        const formData = new FormData();
        formData.append('avatar', avatarUpload);
        formData.append('business_user_mapping_id', userInfo.business_id)
        props.uploadAvatar(formData).then((res) => {
            message.success('Upload Avatar success')
            const userInfoTemp = storeUtil.get('user',userInfo)
            const avatarTemp = {
                blob: {
                    service_url: res.avatar
                }
            }
            userInfoTemp['avatar'] = avatarTemp
            storeUtil.set('user',userInfoTemp)
            setAvatar(res.avatar)
            setAvatarUpload(null)
        })
    }

    const onSearchPublication = (event) => {
        getListPublication(event)
        .then(res => {
            setListPublication(res)
            const index = res.findIndex((item) => item === event);
            if ((index && event) || (index === 0 && event !== "")) {
                setProfile({...dataProfile, publication: event})
            } 
        })
    }

    const showModal = () => {
        if (aRef.current) {
            aRef.current.onCloseClick()
        }
        setState(p=>({...p,src:null}))
        setPreview(null)
        setVisible(true)
        onClose()
      };
    
    const handleOk = () => {
        setVisible(false)
        uploadAvatar()
      };
    
    const handleCancel = () => {
        setVisible(false)
      };

    const onClose = () => {
        setPreview(null)
        setState(p=>({src:null}))
      }
      
      const b64toBlob = (b64Data, contentType='', sliceSize=512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];
      
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
          const slice = byteCharacters.slice(offset, offset + sliceSize);
      
          const byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }
      
          const byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }
      
        const blob = new Blob(byteArrays, {type: contentType});
        return blob;
      }

    const onCrop = (preview) => {
        let blod = ''

        setPreview(preview )
        if (preview) {
            blod = b64toBlob(preview.split(',')[1]);
            const file = new File([blod], 'nameImage', {
                type: "image/jpeg",
            });
    
            setAvatarUpload(file)
            // uploadAvatar()
        }
    }
    
    const onBeforeFileLoad = (elem) => {
       setAvatarUpload(elem.target.files[0])
        nameImage = elem.target.files[0].name
        const reader = new FileReader();
        reader.readAsDataURL(elem.target.files[0]);
        reader.onload = e => {
            setPreview(e.target.result)
            setAvatar(e.target.result)
            const img = new Image()
            img.onload = function(){
                setWidthUploadImg(this.width);
                setHeightUploadImg(this.height);
            } 
            img.src = e.target.result
        };
        setAvatarUpload(null)
    }

    const handleCenterAvatar = () => {
        if (heightUploadImg > 390 && heightUploadImg < widthUploadImg){
            return 390/2;
        }else if (heightUploadImg < 390 && heightUploadImg < widthUploadImg){
            return heightUploadImg/2;
        }else if (widthUploadImg < 295 && heightUploadImg > widthUploadImg){
            return widthUploadImg/2;
        } else {
            return 295/2;
        }
            
    }

    return <LayoutSimple>
        <div className="profile-edit">
            <div className="edit-avatar">
                           
                <div className="avatar">
                    <div className="avatar-edit" onClick={showModal}>
                        <form action="" method="post" id="form-image" >
                            {/* <input type='file' id="imageUpload" accept=".png, .jpg, .jpeg" onChange={showModal} name="avatar"/> */}
                            <label for="imageUpload" className="icon-upload">
                                {/* <CameraOutlined/> */}
                            </label>
                        </form>
                    </div>
                    
                    <div className="avatar-preview">   
                        <img onClick={getImageData} className="profile-user-img img-responsive img-circle" id="imagePreview" src={avatar ? avatar : images.avatar_default}/>
                    </div>
                </div>
                <div className="avatar-upload">
                    <label for="upload-photo" className="avatar-upload">Click Image To Upload</label>
                    {/* <input type="file" id="upload-photo" aria-label="File browser example" onChange={getImageData} name="avatar"/> */}
                </div>
            </div>

            <Button type="primary" onClick={showModal}>
                </Button>
                <Modal
                title="Change Avatar"
                visible={visible}
                onOk={handleOk}
                onCancel={handleCancel}
                >
                <div className="avatar-model">
                <Avatar
                    ref={aRef}
                    width={widthUploadImg < 295 ? widthUploadImg : 295}
                    height={heightUploadImg < 390 ? heightUploadImg : 390}
                    imageWidth={widthUploadImg < 295 ? widthUploadImg : 295}
                    label = 'Choose a picture'
                    cropRadius= {handleCenterAvatar()}
                    onCrop={onCrop}
                    onClose={onClose}
                    onBeforeFileLoad={onBeforeFileLoad}
                    src = {state.src}
                    />    
                </div>
                </Modal>

            <div className="edit-form">
                <div className="form-input">
                    <input className="input-text" type="text" placeholder="Name" name="name" value={dataProfile?.name || ''} onChange={handleInputChange}/>
                    <span className="input-required">*</span>
                </div>
                
                {
                    userInfo.business_type === "media" ? (     
                         
                            <div className="form-input">
                                <FormSelectSearchPhoto
                                    placeholder="Publication/Platform"
                                    options={listPublication}
                                    value={dataProfile.publication}
                                    onSearch={(event) => onSearchPublication(event)}
                                    onChange={(value) => onChange('listPublication', value)}
                                >
                                </FormSelectSearchPhoto>
                            </div>
                    ) : null
                }

                <div className="form-input">
                    <input className="input-text" type="text" value={userInfo?.email || ''} disabled/>
                    <span className="input-required">*</span>
                </div>
                <div className="form-input">
                    <input className="input-text" type="text" placeholder="Phone Number"  name="phone" value={dataProfile?.phone || ''} onChange={handleInputChange}/>
                    <span className="input-required">*</span>
                </div>
                <div className="form-input">
                    <FormSelectSearch
                        placeholder="Country"
                        options={optionsCountry} 
                        value={dataProfile.country}
                        onChange={(value) => onChange('country', value)} />
                </div>
                <div className="form-input">
                    <input className="input-text" type="text" placeholder="City"  name="city" value={dataProfile?.city || ''} onChange={handleInputChange}/>
                    <span className="input-required">*</span>
                </div>
                <div className="form-input">
                    <input className="input-text" type="text" placeholder="Description"  name="description1" value={dataProfile?.description1 || ''} onChange={handleInputChange}/>
                    <span className="input-required">*</span>
                </div>
                <div className="group-button">
                    <div className="reset-password" onClick={resetPasswork}>
                        Reset Password
                    </div>
                    <div className="btn-change-password">
                        <Link className="reset-password" to={`/changePassword`}>
                            Change Password
                        </Link>
                    </div>
                </div>
            </div>
            <div className="edit-save" onClick={saveProfile}>Save</div>
        </div>
    </LayoutSimple>
}

const mapStateToProps = (state) => {
    return {
        common: state.common
    }
}

const mapActionsToProps = {
    getBusinessProfile,
    updateBusinessProfile,
    resetPassword,
    uploadAvatar,
    updateDecode,
    getAllCollections,
}

export default connect(mapStateToProps, mapActionsToProps)(ProfileEdit)
