import React from 'react';
import {Input} from 'antd';
import './index.scss'


export default function FormInput(props){
  const {type} = props
  return <div className="">
     {
       type=='textarea' ? <Input.TextArea className="custom-input"  borderd={false} {...props}/> : <Input className="custom-input"  borderd={false} {...props}/>
     }
  </div>
}