import React from 'react'
import './style.scss'
import { connect } from 'react-redux'
import LayoutSimple from '../../components/Layout/simple'

const NotFound = props => {
    return <LayoutSimple rightButton={null}>
        <div className="not-found">
            <div className="content">404 page not found</div>
        </div>
    </LayoutSimple>
}


const mapStateToProps = (state) => {
    return {
        common: state.common
    }
}

const mapActionsToProps = {
}

export default connect(mapStateToProps, mapActionsToProps)(NotFound);
