
// 获取指定参数值
export const getQueryString= (url,name)=> {
    let reg = new RegExp("(^|\\?|&)" + name + "=([^&]*)(&|$)", "i");
    let res = url.match(reg)||[];
    return res[2]&&decodeURIComponent(res[2])
}

// 本地缓存
export const storeUtil={
    get:(key)=>{
        const value=localStorage.getItem(key)
        if(value != null && (value.startsWith("{")&&value.endsWith('}')||value.startsWith("[")&&value.endsWith(']'))){
            return JSON.parse(value) 
        }
        return value
    },
    set:(key, value)=>{
        if(typeof value==='object'){
            localStorage.setItem(key, JSON.stringify(value))
        }else{
            localStorage.setItem(key, value)
        } 
    },
    clear:()=>{
        localStorage.clear()
    }
}

// 返回location json
export const locationJSON=(location)=>{
    let queryObject={}
    let params=location.search.slice(1).split('&')
    params.forEach(param => {
        param=param.split('=')
        queryObject[param[0]]=param[1]
    });

   return {
    path:location.pathname,
    queryObject,
   }
}

// 把数组arr里元素按照首字母A-Z排序
export const sortListAZ=(arr,keyword)=>{
    let newArr1 = [];
    let tempArr = [];
    let reg = /\b(\w)|\s(\w)/g;
    let k = 0;
    let firstWord = arr[0][keyword].charAt(0).toUpperCase();//获取第一个分类字母
    arr.map((v) => {
      v[keyword] = v[keyword].replace(reg,m=>m.toUpperCase());//首字母大写
      if(firstWord === v[keyword].charAt(0)){
        tempArr.push(v);
        newArr1[k] = {
          Title:firstWord,
          List:tempArr
        }
      }else{
        //这里循环到这表示已经第二个字母了
        firstWord = v[keyword].charAt(0);//设置第二字母
        tempArr = [];//把之前的清除掉
        tempArr.push(v);//添加第一个
        newArr1[++k] = {//自增
          Title: firstWord,
          List : tempArr
        }
      }
    });
    return newArr1;
}

// 验证规则
export const validate={
    password:(value)=>{
        let reg=/^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?!([^(0-9a-zA-Z)])+$).{6,}$/
        return reg.test(value)
    },
    email:(value)=>{
        let reg=/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/
        return reg.test(value)
    },
    isEmpty:(value)=>{
        return !(value&&value.trim())
    }
}