import { Button, Form, List, message, Modal, Popconfirm, Upload } from "antd";
import ExportJsonExcel from "js-export-excel";
import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import FormInput from "../../components/FormInput";
import Layout from "../../components/Layout";
import {
  deleteEvent,
  fetchEventUserInfos,
  getAllEvents,
  importEventUserSeats,
  updateEvent
} from "../../redux/actions/common";
import "./style.scss";

const Events = (props) => {
  const history = props.history;
  const [collections, setCollections] = useState([]);
  const [total, setTotal] = useState(0);
  const [current, setCurrent] = useState(1);
  const [visible, setVisible] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [form] = Form.useForm();

  const getCollections = useMemo(() => {
    return()=>{
      props
        .getAllEvents({ page: current })
        .then((result) => {
          setCollections(result.data);
          setTotal(result.total);
        })
        .catch((err) => {
          setCollections([]);
        });
    }
  },[current]);

  useEffect(() => {
    getCollections();
  }, [current, getCollections]);

  const newEvent = () => {
    history.push(`/events/new`);
  };

  const handleRemove = (id) => {
    props.deleteEvent({ id }).then((res) => {
      getCollections();
    });
  };

  const handleUpdate = async () => {
    await form.validateFields();
    const data = {
      event: {
        ...form.getFieldsValue(),
        id: currentItem.id,
      },
    };

    props.updateEvent(data).then((res) => {
      console.log(res);
      setVisible(false);
      setCurrentItem(null);
    });
  };

  const addButton = (
    <div className="upload-button" onClick={newEvent}>
      <i className="icon-add"></i>Create An Event
    </div>
  );

  const export2Excel = async (event) => {
    const { data } = await props.fetchEventUserInfos(event);
    let sheetHeader = [
      "座位号(Seat)",
      "活动标识(Event ID)",
      "用户标识(User ID)",
      "公司(Company)",
      "姓名(User Name)",
      "手机号(PhoneNumber)",
    ];
    let sheetFilter = [
      "seat",
      "event_id",
      "user_id",
      "company",
      "name",
      "phone_number",
    ];
    let option = {};
    option.fileName = `${event.name}-` + Date.parse(new Date());
    option.datas = [
      {
        sheetData: data,
        sheetFilter: sheetFilter,
        sheetName: `sheet`,
        sheetHeader: sheetHeader,
        columnWidths: [10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10],
      },
    ];

    var toExcel = new ExportJsonExcel(option); //new
    toExcel.saveExcel(); //保存
  };

  return (
    <Layout rightButton={addButton}>
      <div className="events-collections">
        {collections.length > 0 ? (
          <div className="collection">
            <div className="collection-name">Events</div>
            <div className="collection-info">
              <div className="info-desc">{total} Events</div>
            </div>
            <div>
              <List
                size="large"
                pagination={{
                  simple: true,
                  onChange: (page) => {
                    setCurrent(page);
                  },
                  pageSize: 20,
                  total,
                  showQuickJumper: true,
                }}
                dataSource={collections}
                renderItem={(item) => (
                  <List.Item key={item.id}>
                    <List.Item.Meta
                      title={
                        <a
                          href="javascript:void(0)"
                          onClick={() => {
                            history.push({
                              pathname: "/events/show",
                              state: { id: item.id },
                            });
                          }}
                        >
                          {item.name}
                        </a>
                      }
                    />
                    <Upload
                      action={`/bl/api/v1/events/${item.id}/import`}
                      headers={{
                        Authorization: props.common.token,
                      }}
                      multiple={false}
                      onChange={(file) => {
                        console.log(file);
                        if (file.file.status === "done") {
                          message.success("import success");
                        }

                        if (file.file.status === "error") {
                          message.error("import error");
                        }
                      }}
                      showUploadList={false}
                      accept=".xls,.xlsx,.csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    >
                      <Button
                        style={{ borderColor: "#505050", color: "#505050" }}
                      >
                        Import
                      </Button>
                    </Upload>
                    <Button
                      style={{ borderColor: "#505050", color: "#505050" }}
                      onClick={(e) => {
                        export2Excel(item);
                      }}
                    >
                      Export
                    </Button>
                    <Button
                      style={{ borderColor: "#505050", color: "#505050" }}
                      onClick={(e) => {
                        setVisible(true);
                        setCurrentItem(item);
                      }}
                    >
                      Schedule
                    </Button>
                    <Button
                      style={{ borderColor: "#505050", color: "#505050" }}
                      onClick={(e) => {
                        history.push({
                          pathname: "/events/edit",
                          state: { id: item.id },
                        });
                      }}
                    >
                      Edit
                    </Button>
                    <Popconfirm
                      placement="bottom"
                      title={"Are you sure to delete this item?"}
                      onConfirm={() => {
                        handleRemove(item.id);
                      }}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button
                        style={{ borderColor: "#F58C78", color: "#F58C78" }}
                      >
                        Delete
                      </Button>
                    </Popconfirm>
                  </List.Item>
                )}
              />
            </div>
          </div>
        ) : (
          <div className="collection">
            <div className="collection-name">Events</div>
            <div className="collection-info">
              <div className="info-desc">0 Events</div>
            </div>
            <div className="collection-looks-nothing">
              <div className="noting-img">
                <div className="noting-img-arrow"></div>
              </div>
              <div className="noting-text">
                Your event page is empty, <br />
                Please create an event here!
              </div>
            </div>
          </div>
        )}
        {currentItem && (
          <Modal
            title="Modify Schdule"
            visible={visible}
            footer={null}
            onCancel={(e) => {
              setVisible(false);
              setCurrentItem(null);
            }}
          >
            <Form form={form} initialValues={currentItem}>
              <Form.Item
                name="program_id"
                label="Program ID"
                rules={[
                  { required: true, message: "Please input your program id" },
                ]}
              >
                <FormInput placeholder="Input your program id" />
              </Form.Item>
              <Form.Item
                name="schedule_id"
                label="Schedule ID"
                rules={[
                  {
                    required: true,
                    message: "Please input your schedule id",
                  },
                ]}
              >
                <FormInput placeholder="Input your schedule id" />
              </Form.Item>
              <Form.Item>
                <Button
                  style={{ borderColor: "#505050", color: "#505050" }}
                  onClick={handleUpdate}
                >
                  Submit
                </Button>
                <Button
                  style={{ borderColor: "#F58C78", color: "#F58C78" }}
                  onClick={(e) => {
                    setVisible(false);
                    setCurrentItem(null);
                  }}
                >
                  Cancel
                </Button>
              </Form.Item>
            </Form>
          </Modal>
        )}
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};

const mapActionsToProps = {
  getAllEvents,
  deleteEvent,
  updateEvent,
  importEventUserSeats,
  fetchEventUserInfos,
};

export default connect(mapStateToProps, mapActionsToProps)(Events);
