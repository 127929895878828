/**！
 * http请求底层类封装
 */
import { API_BusinessLogic, API_JWT } from '../utility/const'
import http from './http'


export const get = (uri, params = {}, config = {}) => {
    return http.get(uri, { params, ...config,headers: { 'Content-Type': 'application/json' } })
}
export const post = (uri, params = {}, config = {}) => {
    return http.post(uri, params, config)
}
export const put = (uri, params = {}, config = {}) => {
    return http.put(uri, params, config)
}

export const createInvite = ({ email, name, business_type, messageInvite, business_user_mapping_id, created_by }) => {
    let uri = `${API_JWT}/api/v1/invites`
    let business_name = name
    return post(uri, {
        email, business_name, business_type, message: messageInvite, business_user_mapping_id, created_by
    })
}
export const getInviteInfo = (token) => {
    let uri = `${API_JWT}/api/v1/invites/${token}`
    return get(uri)
}
export const createUser = ({ password, user_type = 'user' }, headers) => {
    let uri = `${API_JWT}/api/v1/users`
    return post(uri, {
        password, user_type
    }, { headers })
}

export const encode = ({ email, password }) => {
    let uri = `${API_JWT}/api/v1/auth/encode`
    return post(uri, {
        email, password
    })
}
export const decode = ({ token }) => {
    let uri = `${API_JWT}/api/v1/auth/decode`
    return post(uri)
}

// Brand Space
export const createCollection = (params) => {
    let uri = `${API_BusinessLogic}/api/v1/brands/collections`
    return post(uri, params, { headers: { 'Content-Type': 'multipart/form-data' } })
}

export const getAllCollections = (type) => {
    let uri = `${API_BusinessLogic}/api/v1/brands/collections/season/${type || 'all'}`
    return get(uri)
}

export const updateCollection = (id, params) => {
    let uri = `${API_BusinessLogic}/api/v1/brands/collections/${id}`
    return put(uri, params)
}

export const changePublic = (id, params) => {
    let uri = `${API_BusinessLogic}/api/v1/brands/collections/${id}`
    return put(uri, params)
}

export const getCollectionDetail = (id) => {
    let uri = `${API_BusinessLogic}/api/v1/brands/collections/${id}`
    return get(uri)
}

export const deleteLooks = (collectionId, id) => {
    let uri = `${API_BusinessLogic}/api/v1/brands/collections/${collectionId}/collection_looks/${id}`
    return http.delete(uri)
}

export const putShowLooks = (collectionDetailId, params) => {
    let uri = `${API_BusinessLogic}/api/v1/brands/collections/${collectionDetailId}/update-multi-collection-looks`
    return put(uri, params)
}

export const getBrandLookDetail = (id) => {
    let uri = `${API_BusinessLogic}/api/v1/brands/collections/looks/${id}`
    return get(uri)
}

export const getAllBrandMedias = (lastId) => {
    let query = '';
    if (lastId) {
        query = `?lastId=${lastId}`
    }
    let uri = `${API_BusinessLogic}/api/v1/businesses/1/get-all-medias${query}`
    return get(uri)
}

export const getMyBrandMedias = (lastId) => {
    let query = '';
    if (lastId) {
        query = `?lastId=${lastId}`
    }
    let uri = `${API_BusinessLogic}/api/v1/businesses/1/get-my-medias${query}`
    return get(uri)
}

export const getAllSeasons = () => {
    let uri = `${API_BusinessLogic}/api/v1/brands/seasons`
    return get(uri)
}


export const getAllMediaByAgency = (params) => {
    let query = '';
    if (params.lastId) {
        query = `?lastId=${params.lastId}`
    }
    let uri = `${API_BusinessLogic}/api/v1/businesses/1/get-all-medias-by-agency/${params.brandId}${query}`
    return get(uri)
}


export const getMyMediaByAgency = (params) => {
    let query = '';
    if (params.lastId) {
        query = `?lastId=${params.lastId}`
    }
    let uri = `${API_BusinessLogic}/api/v1/businesses/1/get-my-medias-by-agency/${params.brandId}${query}`
    return get(uri)
}

export const getAllBrands = (params) => {
    let query = '';
    if (params.page) {
        query += `&page=${params.page}`
    }
    if (params.search) {
        query += `&search=${params.search}`
    }
    let uri = `${API_BusinessLogic}/api/v1/businesses/get_all_brands?${query}`
    return get(uri)
}

export const getAllBrandsConnec = (params) => {
    console.log(params);
    let query = '';
    if (params.lastId) {
        query = `?page=${params.lastId}`
    }

    if (params.limit) {
        query += `?limit=${params.limit}`
    }
    let uri = `${API_BusinessLogic}/api/v1/businesses/get-all-brands-connected${query}`
    return get(uri)
}

export const getMyBrands = (page) => {
    let query = '';
    if (page) {
        query = `?page=${page}`
    }
    let uri = `${API_BusinessLogic}/api/v1/businesses/get_my_brands${query}`
    return get(uri)
}

export const addLooks = (id, params) => {
    let uri = `${API_BusinessLogic}/api/v1/brands/collections/${id}/add-looks`
    return put(uri, params, { headers: { 'Content-Type': 'multipart/form-data' } })
}

export const getColectionAgencyBrand = (params) => {
    let query = '';
    if (params.type) {
        query = `?type=${params.type}`
    }
    let uri = `${API_BusinessLogic}/api/v1/businesses/${params.id}${query}`
    return get(uri)
}

export const deleteAllLooks = (collectionDetailId, params) => {
    let uri = `${API_BusinessLogic}/api/v1/brands/collections/${collectionDetailId}/delete-multi-collection-looks`
    return put(uri, params)
}

//Brand/Sample space
export const setLooksAsSample = (collectionId, params) => {
    let uri = `${API_BusinessLogic}/api/v1/brands/collections/${collectionId}/add-samples`
    return put(uri, params, { headers: { 'Content-Type': 'multipart/form-data' } })
}

export const getCollectionDetailSample = (collection_id) => {
    let uri = `${API_BusinessLogic}/api/v1/brands/collections/${collection_id}/show-samples`
    return get(uri)
}

export const addSamples = (id, params) => {
    let uri = `${API_BusinessLogic}/api/v1/brands/collections/${id}/collection_samples?looksId=${params}`
    return post(uri, params, { headers: { 'Content-Type': 'multipart/form-data' } })
}

export const deleteSamples = (collectionId, id) => {
    let uri = `${API_BusinessLogic}/api/v1/brands/collections/${collectionId}/collection_samples/${id}`
    return http.delete(uri)
}


export const getBrandLookDetailSamples = (id) => {
    let uri = `${API_BusinessLogic}/api/v1/brands/collections/samples/${id}`
    return get(uri)
}

//Brand/ Clipping space
export const setLooksAsClipping = (collectionId, params) => {
    let uri = `${API_BusinessLogic}/api/v1/brands/collections/${collectionId}/add-clippings`
    return put(uri, params, { headers: { 'Content-Type': 'multipart/form-data' } })
}

export const getCollectionDetailClipping = (collection_id) => {
    let uri = `${API_BusinessLogic}/api/v1/brands/collections/${collection_id}/show-clippings`
    return get(uri)
}

export const addClippings = (id, params) => {
    let uri = `${API_BusinessLogic}/api/v1/brands/collections/${id}/collection_clippings?looksId=${params}`
    return post(uri, params, { headers: { 'Content-Type': 'multipart/form-data' } })
}

export const deleteClippings = (collectionId, id) => {
    let uri = `${API_BusinessLogic}/api/v1/brands/collections/${collectionId}/collection_clippings/${id}`
    return http.delete(uri)
}

//////////-///////////


export const deleteCollection = (id) => {
    let uri = `${API_BusinessLogic}/api/v1/brands/collections/${id}`
    return http.delete(uri)
}

// Media Space
export const createStoryBoard = (params) => {
    let uri = `${API_BusinessLogic}/api/v1/medias/storyboards`
    return post(uri, params, { headers: { 'Content-Type': 'multipart/form-data' } })
}

export const getAllStoryBoards = (id) => {
    let uri = `${API_BusinessLogic}/api/v1/medias/storyboards`
    return get(uri)
}

export const updateStoryBoard = (id, params) => {
    let uri = `${API_BusinessLogic}/api/v1/medias/storyboards/${id}`
    return put(uri, params, { headers: { 'Content-Type': 'multipart/form-data' } })
}

export const getStoryBoardDetail = (id) => {
    let uri = `${API_BusinessLogic}/api/v1/medias/storyboards/${id}`
    return get(uri)
}

export const getStoryBoardLook = (id) => {
    let uri = `${API_BusinessLogic}/api/v1/medias/storyboards/looks/${id}`
    return get(uri)
}

export const updateStoryBoardLook = (collectionId, params) => {
    let uri = `${API_BusinessLogic}/api/v1/medias/storyboards/${collectionId}/update-multi-storyboard-looks`
    return put(uri, params)
}


export const deleteMeidaLooks = (storyboardId, id) => {
    let uri = `${API_BusinessLogic}/api/v1/medias/storyboards/${storyboardId}/storyboard_looks/${id}`
    return http.delete(uri)
}

export const deleteStoryBoard = (id) => {
    let uri = `${API_BusinessLogic}/api/v1/medias/storyboards/${id}`
    return http.delete(uri)
}

export const addMediaLooks = (id, params) => {
    let uri = `${API_BusinessLogic}/api/v1/medias/storyboards/${id}/add-looks`
    return put(uri, params, { headers: { 'Content-Type': 'multipart/form-data' } })
}

export const deleteAllMediaLooks = (storyboardId, params) => {
    let uri = `${API_BusinessLogic}/api/v1/medias/storyboards/${storyboardId}/delete-multi-storyboard-looks`
    return put(uri, params)
}

export const getListPhotographer = (keyword) => {
    let uri = `${API_BusinessLogic}/api/v1/medias/storyboards/search-by-conditions?search=${keyword}&fields=type_photographer_name`
    return get(uri)
}

export const getListPublication = (keyword) => {
    let uri = `${API_BusinessLogic}/api/v1/medias/storyboards/search-by-conditions?search=${keyword}&fields=publication`
    return get(uri)
}

// Follower Space
export const createFollower = (created_by, business_brand_follower_id, business_media_follower_id) => {
    let uri = `${API_BusinessLogic}/api/v1/followers`
    return post(uri, {
        created_by, business_brand_follower_id, business_media_follower_id
    })
}

export const decideFollower = (id, status) => {
    let uri = `${API_BusinessLogic}/api/v1/followers/${id}`
    return put(uri, { status })
}

export const getNotifications = (params) => {
    let uri = `${API_BusinessLogic}/api/v1/followers/1/notify`

    if (params) {
        uri += `?seen=true`
    }
    return get(uri)
}

export const checkRegister = (params) => {
    let uri = `${API_JWT}/api/v1/users/check-exist-user/${params}`
    return get(uri)
}

// Profile
export const getBusinessProfile = (id) => {
    let uri = `${API_BusinessLogic}/api/v1/businesses/1/get-business-profile/${id}`
    return get(uri)
}

export const updateBusinessProfile = (params) => {
    let uri = `${API_BusinessLogic}/api/v1/businesses/${params.business_id}/profiles/${params.id}`
    return put(uri, params.data)
}

export const resetPassword = () => {
    let uri = `${API_JWT}/api/v1/users/reset-password`
    return post(uri)
}

export const uploadAvatar = (data) => {
    let uri = `${API_JWT}/api/v1/users/upload-avatar`
    return post(uri, data)
}

export const changePassword = (data) => {
    let uri = `${API_JWT}/api/v1/users/change-password`
    return post(uri, data)
}

export const getAvatar = (id) => {
    let uri = `${API_JWT}/api/v1/users/${id}/avatar`
    return get(uri)
}

export const updateDecode = (params) => {
    let uri = `${API_JWT}/api/v1/users/${params.id}`
    return put(uri, params.data)
}

//--------------Version------------------//
export const getVersionBusiness = () => {
    let uri = `${API_BusinessLogic}/version`
    return get(uri)
}

export const getVersionJWT = () => {
    let uri = `${API_JWT}/version`
    return get(uri)
}

export const sendEmailWithForgetPassword = (data) => {
    let uri = `${API_JWT}/api/v1/forget_password`;
    return post(uri, data)
}


export const forgetResetPassword = (data) => {
    let uri = `${API_JWT}/api/v1/forget_password`;
    return put(uri, data);
};



export const fetchAllBusinesses = (data) => {
    let uri = `${API_BusinessLogic}/api/v1/businesses`;
    return get(uri, data);
};


export const fetchEvents = (data) => {
    let uri = `${API_BusinessLogic}/api/v1/events`;
    return get(uri, data);
};


export const updateEvent = (data) => {
    let uri = `${API_BusinessLogic}/api/v1/events/${data.event.id}`;
    return put(uri, data);
};


export const createEvent = (data) => {
    let uri = `${API_BusinessLogic}/api/v1/events`;
    return post(uri, data);
};


export const deleteEvent = (data) => {
    let uri = `${API_BusinessLogic}/api/v1/events/${data.id}`;
    return http.delete(uri, data);
};


export const showEvent = (data) => {
    let uri = `${API_BusinessLogic}/api/v1/events/${data.id}`;
    return get(uri, data);
};




export const fetchBannersSort = (data) => {
    let uri = `${API_BusinessLogic}/api/v1/banner_sorts`;
    return get(uri, data);
};


export const updateBannerSort = (data) => {
    let uri = `${API_BusinessLogic}/api/v1/banner_sorts/${data.banner_sort.id}`;
    delete data.banner_sort.id
    return put(uri, data);
};


export const createBannerSort = (data) => {
    let uri = `${API_BusinessLogic}/api/v1/banner_sorts`;
    return post(uri, data);
};


export const deleteBannerSort = (data) => {
    let uri = `${API_BusinessLogic}/api/v1/banner_sorts/${data.id}`;
    return http.delete(uri, data);
};


export const showBannerSort = (data) => {
    let uri = `${API_BusinessLogic}/api/v1/banner_sorts/${data.id}`;
    return get(uri, data);
};


export const fetchBanners = (data) => {
    let uri = `${API_BusinessLogic}/api/v1/banners`;
    return get(uri, data);
};


export const updateBanner = (data) => {
    let uri = `${API_BusinessLogic}/api/v1/banners/${data.banner.id}`;
    delete data.banner.id
    return put(uri, data);
};


export const createBanner = (data) => {
    let uri = `${API_BusinessLogic}/api/v1/banners`;
    return post(uri, data);
};


export const deleteBanner = (data) => {
    let uri = `${API_BusinessLogic}/api/v1/banners/${data.id}`;
    return http.delete(uri, data);
};


export const showBanner = (data) => {
    let uri = `${API_BusinessLogic}/api/v1/banners/${data.id}`;
    return get(uri, data);
};


export const showJob = (params) => {
  let uri = `${API_BusinessLogic}/api/v1/jobs/${params.id}`;
  return get(uri, params);
};

export const updateJob = (data) => {
    let uri = `${API_BusinessLogic}/api/v1/jobs/${data.id}`;
    return put(uri, data);
};


export const createJob = (data) => {
    let uri = `${API_BusinessLogic}/api/v1/jobs`;
    return post(uri, data);
};


export const deleteJob = (data) => {
    let uri = `${API_BusinessLogic}/api/v1/jobs/${data.id}`;
    return http.delete(uri, data);
};


export const fetchJobs = (params) => {
  let uri = `${API_BusinessLogic}/api/v1/jobs.json`;
  return get(uri, params);
};


export const fetchRoles = (data) => {
    let uri = `${API_JWT}/api/v1/roles.json`;
    return get(uri, data);
};




export const updateRole = (data) => {
    let uri = `${API_JWT}/api/v1/roles/${data.role.id}`;
    delete data.role.id
    return put(uri, data);
};

export const createRole = (data) => {
    let uri = `${API_JWT}/api/v1/roles`;
    return post(uri, data);
};


export const deleteRole = (data) => {
    let uri = `${API_JWT}/api/v1/roles/${data.id}`;
    return http.delete(uri, data);
};


export const showRole = (data) => {
    let uri = `${API_JWT}/api/v1/roles/${data.id}.json`;
    return get(uri, data);
};

export const fetchResources = (data) => {
    let uri = `${API_JWT}/api/v1/resources`;
    return get(uri, data);
};


export const fetchUsers = (params) => {
    let uri = `${API_JWT}/api/v1/users.json`;
    return get(uri, params);
};

export const addRole = (data) => {
    let uri = `${API_JWT}/api/v1/users/add_role`;
    return post(uri, data);
};


export const fetchUserRoles = (data) => {
    let uri = `${API_JWT}/api/v1/users/${data.id}/roles.json`;
    return get(uri, data);
};


export const fetchCurrentResources = (data) => {
    let uri = `${API_JWT}/api/v1/resources/current.json`;
    return get(uri, data);
};

export const fetchEventUserInfos = (data) => {
  let uri = `${API_BusinessLogic}/api/v1/events/${data.id}/users.json`;
  return get(uri, {});
};


export const importEventUserSeats = (id,data) => {
  let uri = `${API_BusinessLogic}/api/v1/events/${id}/import`;
  return post(uri, data);
};


export const addUser = (data) => {
  let uri = `${API_JWT}/api/v1/users/new_account`;
  return post(uri, data);
};


export const addBrand = (data) => {
  let uri = `${API_BusinessLogic}/api/v1/profiles/${data.type}/create`;
  return post(uri, data);
};



export const updateCollectionLook = (data) => {
  let uri = `${API_BusinessLogic}/api/v1/brands/collections/${data.collection_id}/collection_looks/${data.id}`;
  return put(uri, data);
};


export const showUser = (data) => {
    let uri = `${API_JWT}/api/v1/users/show_by_id/${data.id}`;
  return get(uri, data);
}


export const updateUser = (data) => {
    let uri = `${API_JWT}/api/v1/users/${data.id}`;
  return put(uri, data);
}





export const showNews = (params) => {
  let uri = `${API_BusinessLogic}/api/v1/news/${params.id}`;
  return get(uri, params);
};

export const updateNews = (data) => {
  let uri = `${API_BusinessLogic}/api/v1/news/${data.id}`;
  return put(uri, data);
};

export const createNews = (data) => {
  let uri = `${API_BusinessLogic}/api/v1/news`;
  return post(uri, data);
};

export const deleteNews = (data) => {
  let uri = `${API_BusinessLogic}/api/v1/news/${data.id}`;
  return http.delete(uri, data);
};

export const fetchNews = (params) => {
  let uri = `${API_BusinessLogic}/api/v1/news.json`;
  return get(uri, params);
};
