import React, { useState, useEffect } from 'react'
import {Link, useParams} from 'react-router-dom'
import './style.scss'
import { connect } from 'react-redux'
import {images} from '../../utility/const'
import {locationJSON} from '../../utility'
import Layout from '../../components/Layout'
import UploadFiles from '../../components/UploadFiles'
import { Upload, Progress, message,Tooltip, Modal } from "antd";
import FormSelect from '../../components/FormSelect';
import ModalAdd from "../../components/Modal";
import FormSelectSearchPhoto from '../../components/FormSelectSearchPhoto'
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { getWorkDetail, getWorkLookDetail, deleteStoryBoard } from '../../redux/actions/media'
import { 
    updateStoryBoard,
    updateStoryBoardLook,
    deleteMeidaLooks,
    addMediaLooks,
    deleteAllMediaLooks,
    getListPhotographer, 
    getListPublication
     } from '../../request'

const { confirm } = Modal;

const MediaWorkDetail = (props) => {
    const params = useParams()
    const {common:{userInfo}}=props
    const [showModal, setShowModal] = useState(false);
    const [percent, setPercent] = useState(0);
    const [workDetail, setWorkDetail] = useState([])
    const [looks, setLooks] = useState([])
    const [lookSelectName, setLookSelectName] = useState([])
    const [dataLooks, setDataLooks] = useState([])
    const history = props.history
    const [editorType, setEditorType]=useState('')
    const [listSelectedDelete, setListSelectedDelete]=useState([])
    const accept = ["image/png", "image/jpeg", "image/jpg"];
    const [filelist, setFilelist] = useState();
    const [status, setStatus] = useState("init");
    const obj=locationJSON(window.location)
    const [mediaEditorFields, setMediaEditorFields] = useState({ typeStoryName: '', year: '', month: '', typePhotographerName: '', link: '',  publication: ''})
    const [listUpdatedLooks, setListUpdatedLooks] = useState([]);
    const [list, setName] = useState([]);
    const [listRemovedLooks, setListRemovedLooks] = useState([]);
    const [listPhotographer,setListPhotographer] = useState([])
    const [listPublication, setListPublication] = useState([])
    let countTemp = 0

    const getStoryWorkDetail = () => {
        props.getWorkDetail(params.workId)
        .then(result => {
            setWorkDetail(result)
            setLooks(result.story_board_looks)
            setDataLooks(result.story_board_looks)
            setMediaEditorFields({
                typeStoryName: result.name,
                year: result.year,
                month: result.month,
                typePhotographerName: result.type_photographer_name,
                link: result.link,
                publication: result.publication,
            })
            let dataTemp = [];
            result.story_board_looks.forEach(element => {
              if (element.published) {
                dataTemp.push(element)
              }
            });
            setListUpdatedLooks(dataTemp)
        })
        .catch(err => {
            setWorkDetail([])
            setLooks([])
            setDataLooks([])
        })
    }

    useEffect(() => {
        getStoryWorkDetail();
        if (history.location.params === 'edit') {
            setEditorType('edit')
        }

        const listen=history.listen((arg)=>{
            if(arg.pathname==='/mediaWorkDetail'){
                getStoryWorkDetail()
            }
        })
        return listen
    }, [])

    const handleInputChangeName = (value, index) => {       
        let dataTempLooks = looks;
        let dataTempUpdate = [];
        let newData = [...lookSelectName]
        dataTempLooks[index].name = value.target.value
        const findIndex = newData.findIndex(i => i.id === looks[index].id)
        if(findIndex === -1) {
            newData.push(dataTempLooks[index])
        }else{
            newData[findIndex].name = value.target.value
        }
        setLookSelectName(newData)
        setLooks(dataTempLooks);
        setDataLooks(dataTempLooks)
        setName(value.target.value)
        listUpdatedLooks.forEach(element => {
            if (element.published) {
              dataTempUpdate.push(element)
            }
        });
        setListUpdatedLooks(dataTempUpdate)
    }

    const getLooks = looks => {
        const removeSelectedLook = (look) => { 
            confirm({
                title: `Are you sure you want to delete ${look.name}?`,
                icon: <ExclamationCircleOutlined />,
                okText: 'Yes',
                okType: 'danger',
                cancelText: 'No',
                onOk() {
                    setListRemovedLooks([...listRemovedLooks, look]);
                    setLooks(looks.filter((item) => item.id !== look.id))
                    setDataLooks(looks.filter((item) => item.id !== look.id));
                    let dataTemp = [...listUpdatedLooks];
                    const index = listUpdatedLooks.findIndex(item => item.id === look.id);
                    dataTemp.splice(index, 1)
                    setListUpdatedLooks(dataTemp)
                    deleteMeidaLooks(workDetail.id, look.id).then(
                        setEditorType('edit'),
                        setMediaEditorFields(mediaEditorFields),
                        message.success('Detete Success')
                    )
                },
                onCancel() {
                    setEditorType('edit')
                }
              })
          };
        const selectLook=(look, index)=>{
            setListUpdatedLooks([...listUpdatedLooks, look]);
            let newArr = [...looks];
            if(look.selected){
                newArr[index].selected = false;
                const findIndex = listSelectedDelete.findIndex(i => i.id === look.id)
                if(findIndex !== -1){
                    listSelectedDelete.splice(findIndex, 1)
                    setListSelectedDelete(listSelectedDelete)
                }
            } else {
                newArr[index].selected = true;
                setListSelectedDelete([...listSelectedDelete, look]);
            }
            setLooks(newArr);
            setDataLooks(newArr);
        }

   

        return dataLooks.map((look, index) => (
            <div className="look" key={look.id}>
            {
                editorType=='edit'?(
                    <>
                        <div className="look-img">
                            <img className="img" alt="" src={look.file.url} />
                        </div>
                        <div className="look-editor">
                            <div className={`editor-checkbox ${look.selected ? "checked" : "" }`}
                                onClick={() => selectLook(look, index)}></div>
                                <div className="editor-name">
                                    <input
                                        value={look.name}
                                        onChange={(value) => handleInputChangeName(value, index)} />
                                </div>
                                <div className="icon-close"
                                    onClick={() => removeSelectedLook(look)}
                                ></div>
                        </div>
                        
                    </>
                ):  editorType == '' ? (
                    <Link to={`/mediaLookDetailByMedia/${params.workId}/${look.id}`}>
                    <div>
                        <div className="look-img">
                            <img className="img" alt="" src={look.file.url} />
                        </div>
                        <Tooltip placement="bottomLeft" color="gray" title={look.name}><div className="look-name">{look.name}</div></Tooltip>
                    </div>
                    </Link>
                ): null
            }
            </div>
        ))
    }
    const changeNameMedia = () => {
        // const isChange =  window.confirm('You want change name Look?')
        // if (isChange) {
        //     let params = {
        //         workDetail: workDetail.id,
        //         id: look.id,
        //         data: look
        //     }
        //     updateStoryBoardLook(params).then(
        //       setEditorType(''),
        //       message.success('Change name Look sussess!'),
        //       getStoryWorkDetail()
        //     )
        // } 
        const params = {
            conditions: lookSelectName
        }
        updateStoryBoardLook(workDetail.id, params).then()
      }

    const cancel=()=>{
        setEditorType('');
    }

    const confirmInfoEditor=()=>{
        const changeNameLook = new Promise((resolve, reject) => {
            changeNameMedia();
            resolve()
        });
        const changeInfo = new Promise((resolve, reject) => {
            const formData = new FormData()
            formData.append('name', mediaEditorFields.typeStoryName)       
            formData.append('link', mediaEditorFields.link)
            formData.append('type_photographer_name', mediaEditorFields.typePhotographerName)
            formData.append('publication', mediaEditorFields.publication)
            formData.append('type_photographer_name', mediaEditorFields.typePhotographerName)
            formData.append('month', mediaEditorFields.month)
            formData.append('year', mediaEditorFields.year)

            updateStoryBoard(workDetail.id, formData)
            .then(result=>{
                setEditorType('')
                setMediaEditorFields({
                    typeStoryName: result.name,
                    year: result.year,
                    month: result.month,
                    typePhotographerName: result.type_photographer_name,
                    link: result.link,
                    name: result.name,
                    publication: result.publication,
                })
            }) 
            resolve();
        })

        Promise.all([changeInfo, changeNameLook]).then(() => {
            setEditorType('')
            message.success('Change sussess!')
            getStoryWorkDetail()
        })
        
    }

    const getInfoEditor=()=>{
        
        const months = [
            { value: "January", name: "January" },
            { value: "February", name: "February" },
            { value: "March", name: "March" },
            { value: "April", name: "April" },
            { value: "May", name: "May" },
            { value: "June", name: "June" },
            { value: "July", name: "July" },
            { value: "August", name: "August" },
            { value: "September", name: "September" },
            { value: "October", name: "October" },
            { value: "November", name: "November" },
            { value: "December", name: "December" }
        ]
    
        const years = [
            { value: 2018, name: "2018" },
            { value: 2019, name: "2019" },
            { value: 2020, name: "2020" },
            { value: 2021, name: "2021" },
            { value: 2022, name: "2022" },
        ]
    
        const onChange=(value)=>{
            console.log(value,'选中的option value')
        }

        const handleInputChange = e => {         
            const {name, value} = e.target
            setMediaEditorFields({...mediaEditorFields, [name]: value})
        }

        const changeMediaProps = (name, value) => {
            if (name === 'year') {
                setMediaEditorFields({...mediaEditorFields, year: value})
            } else if (name === 'month'){
                setMediaEditorFields({...mediaEditorFields, month: value})
            }else if (name === 'photographerName'){
                mediaEditorFields.typePhotographerName = value;
                setMediaEditorFields({...mediaEditorFields, typePhotographerName:value})
            } else if (name === 'listPublication'){
                mediaEditorFields.publication = value;
                setMediaEditorFields({...mediaEditorFields, publication:value})
            }
        }

        const onSearchPhoto = (event) => {
            getListPhotographer(event)
            .then(res => {
                setListPhotographer(res)
                const index = res.findIndex((item) => item === event);
                if ((index && event) || (index === 0 && event !== "")) {
                    setMediaEditorFields({...mediaEditorFields, typePhotographerName: event})
                }
            })
        }

        const onSearchPublication = (event) => {
            getListPublication(event)
            .then(res => {
                setListPublication(res)
                const index = res.findIndex((item) => item === event);
                if ((index && event) || (index === 0 && event !== "")) {
                    setMediaEditorFields({...mediaEditorFields, publication: event})
                } 
            })
        }
        return <div className="info-editor">
            <div className="info-editor-row d-flex">
                <div className="form-input info-editor-titleinput">
                    <input className="input-text" name="typeStoryName" type="text" value={mediaEditorFields.typeStoryName} onChange={handleInputChange} placeholder="The New Chapter" />
                </div>
                {
                    <div className="form-input info-editor-authorinput">
                        <FormSelectSearchPhoto
                            placeholder="Photographer Name"
                            options={listPhotographer}
                            value={mediaEditorFields.typePhotographerName}
                            onSearch={(event) => onSearchPhoto(event)}
                            onChange={(value) => changeMediaProps('photographerName', value)}
                        ></FormSelectSearchPhoto>                       
                    </div>
                }
            </div>
            <div className="info-editor-row d-flex justify-between">
                <div className="info-editor-col">
                    <div className="form-input info-editor-linkinput">
                        <input className="input-text" name="link" type="text" value={mediaEditorFields.link} onChange={handleInputChange} placeholder="Download High Resolution" />
                    </div>
                    {
                        <div className="form-input info-editor-authorinput">
                            <FormSelectSearchPhoto
                                placeholder="Publication/Platform"
                                options={listPublication}
                                value={mediaEditorFields.publication}
                                onSearch={(event) => onSearchPublication(event)}
                                onChange={(value) => changeMediaProps('listPublication', value)}
                            >
                            </FormSelectSearchPhoto>
                        </div>
                    }
                </div>
                <div className="info-editor-form">
                    <div className="form-input">
                        <FormSelect 
                            defaultValue={months[0].value}
                            value={mediaEditorFields.month}
                            options={months} 
                            onChange={(value) => changeMediaProps('month', value)} />
                    </div>
                    <div className="form-input">
                        <FormSelect 
                            defaultValue={years[0].value}
                            value={mediaEditorFields.year}
                            options={years} 
                            onChange={(value) => changeMediaProps('year', value)} />
                    </div>
                    <div className="info-editor-cancel" onClick={cancel}>Cancel</div>
                    <div className="info-editor-confirm" onClick={confirmInfoEditor}>Confirm</div>
                </div>
            </div>
            
        </div>
    }
    const chooseAll = (status) => {
        const arr = dataLooks.map(i => {
            i.selected = !status
            return i
        })
        if (looks.filter(i => i.selected).length === looks.length){
            setListSelectedDelete(arr)
        }else {
            setListSelectedDelete([])
        }
        setListUpdatedLooks(arr)  
        setLooks(arr)
        setDataLooks(arr)
};
    const getPictureEditor=()=>{
        const toSample=()=>{
            cancel()
        }
        const toDeleteAll=()=>{
            const params = {
                conditions: listSelectedDelete.map(i => {
                    return {
                        id: i.id
                    }
                })
            }
            if (listSelectedDelete.length === 0){
                message.warning(' You must choose pictures ')
            }else{
                confirm({
                    title: `Are you sure you want to delete ${listSelectedDelete.length} pictures?`,
                    icon: <ExclamationCircleOutlined />,
                    okText: 'Yes',
                    okType: 'danger',
                    cancelText: 'No',
                    onOk() {
                        deleteAllMediaLooks(workDetail.id, params)
                        .then((res) => {
                            setEditorType("");
                            getStoryWorkDetail();
                            setListSelectedDelete()
                            message.success(' Delete complete ')
                        })
                    }
                })
            }
               
        }


        return  (
            looks.length > 0 ? (
            <div className="picture-editor-bottom">
                <div className="picture-editor-choose-all" onClick={() => chooseAll(looks.filter(i=>i.selected).length === looks.length)}>
                    <div className={`editor-checkbox ${
                        looks.filter(i=>i.selected).length === looks.length && "checked"}`
                    }></div>
                    Choose All
                </div>
                <div className="picture-editor-operates">
                    <div className="operate-btn" onClick={toDeleteAll}>Delete All</div>
                </div>
            </div>
            ) : null
        );
    }

    const toEditLink = (mediaEditorFields) => {
        if(!mediaEditorFields.link) {
            setEditorType('edit')
        }
    }
    const clickShowModal = (bool) => {
        setShowModal(bool);
        setFilelist();
        setPercent(0);
        setStatus("init");
      };
    const addMediaPicture = () => {
        return setEditorType("add"), setShowModal(true), setPercent(0);
    }

    const getUploadPictures = () => {
        const uploadProps = {
          action: "/",
          accept: accept.join(","),
          disabled: false,
          multiple: true,
          showUploadList: false,
          beforeUpload: (file, filelist) => {
            if(countTemp > filelist.length) {
              countTemp = 1
          } else {
              countTemp = countTemp + 1
          }
    
          if (countTemp === 1 && filelist.length > 15) {
              message.warn({
                  content:`No more than 15 photos for each time`
              })
              setFilelist([])
              return false
          } else if (!accept.includes(file.type)) {
              message.warn({
                  content: `Only jpeg & png files less than 1MB`
              })
              return false
          } else if (file.size > 1024 * 1024) {
              message.warn({
                  content: `Only jpeg & png files less than 1MB`
              })
              return false
          } else if (countTemp === 1 && filelist.length <= 15) {
              setFilelist(filelist)
              setStatus('selected')
              setPercent(0)
              return false
          }
          },
        };
    
        return (
          <Upload {...uploadProps}>
            <div className="form-upload">
              <div className="upload-img">
                <Progress
                  type="circle"
                  percent={percent}
                  showInfo={false}
                  strokeLinecap="square"
                  strokeWidth="5"
                  strokeColor="#F58C78"
                  trailColor="transparent"
                ></Progress>
                <img src={images.icon_upload_img}></img>
              </div>
              <div className="upload-text">
              Please <span className="underline"> Browse & Upload </span> your photos here
              <br />
              *No more than 15 photos for each time
              <br/>
              *Only jpeg & png files less than 1MB
              </div>
            </div>
          </Upload>
        );
      };


      const confirmToAddPicture = () => {
        const formData = new FormData();
    
        // upload progress
        const updateProgress = (percent) => {
          if (percent < 93) {
            setPercent(percent + 1);
            setTimeout(() => {
              updateProgress(percent + 1);
            }, 10);
          }
        };
    
        if (!filelist) {
          return message.warn({
            content: `Please select your pictures.`,
          });
        }
        
       
        {
          updateProgress(percent);
          filelist.forEach((file) => {
            formData.append("story_board_looks[file][]", file);
          });
          addMediaLooks(workDetail.id, formData).then((res) => {
            setWorkDetail(res);
            setLooks(res.story_board_looks);
            setDataLooks(res.story_board_looks)
            setMediaEditorFields({
                typeStoryName: res.name,
                year: res.year,
                month: res.month,
                typePhotographerName: res.type_photographer_name,
                link: res.link,
                name: res.name,
                publication: res.publication,
            });
            setPercent(100);
            setStatus("success");
            clickShowModal(false);
            setEditorType("");
            getStoryWorkDetail();
          });
        }
       
      };
      const closeAdd = () => {
        return clickShowModal(false), setEditorType("");
      };

      const isDisable = () => {
        if (filelist && filelist.length > 0) {
          return false;
      }
      return true;
    };

      const getConfirmToAddPictureEditor = () => {
        return (
          <>
            {showModal ? (
              <ModalAdd title={"Add Picture"} onClose={() => closeAdd()}>
                <div className="form-wraper">
                  {status == "success" ? (
                    <div className="form-upload">
                      <div className="upload-img">
                        <img src={images.icon_upload_success}></img>
                      </div>
                      <div className="upload-text">Upload Success!</div>
                    </div>
                  ) : (
                    getUploadPictures()
                  )}
                </div>
                <div
                  className={`upload-modal-btn ${isDisable() ? "disable" : ""}`}
                  onClick={() => confirmToAddPicture()}
                >
                  Confirm
                </div>
              </ModalAdd>
            ) : (
              ""
            )}
            <div className="collection-info">
              <div className="info-desc">
                {looks.length} Looks · 5 Clippings · 4 Samples · 
                {
                  !workDetail.link ? (
                    <span onClick={() => toEditLink(workDetail)} className="link-download">
                    Download High Resolution</span>
                ) : (
                    <a href={getClickableLink(workDetail.link)} target="_blank">
                    Download High Resolution</a>
                )
                }
              </div>
              <div className="info-editors">
                <div className="editor-btn">Edit</div>
                <div className="editor-btn">Add Picture</div>
              </div>
            </div>
          </>
        );
      };

    const deleteStory = () => {
        confirm({
            title: `Are you sure you want to delete Story?`,
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                props.deleteStoryBoard(workDetail.id).then(() => {
                    message.success('Detete Success');
                    history.push('/mediaWorks')
                })
            }
        })
    }

    const getClickableLink = link => {
        return link.startsWith("http://") || link.startsWith("https://") ?
          link
          : `http://${link}`;
    };

    return <Layout showBack={true} rightButton={<UploadFiles type="media">Create A Story</UploadFiles> }>
        <div className="work-detail">
            {
                editorType=='edit'?(
                    getInfoEditor()
                ): editorType=='add'?(
                    getConfirmToAddPictureEditor()
                ):(
                    <>
                    <div className="collection-name">{ mediaEditorFields.typeStoryName }</div>
                    <div className="collection-info-wraper">
                        <div className="collection-info">
                            <div className="info-desc">
                                {
                                    mediaEditorFields.description ? (
                                        <span>
                                            { mediaEditorFields.description } ·&nbsp;
                                        </span>
                                    ) : null
                                }

                                {
                                    mediaEditorFields.publication ? (
                                        <span>
                                            { mediaEditorFields.publication } ·&nbsp;
                                        </span>
                                    ) : null
                                }

                                {
                                    mediaEditorFields.month ? (
                                        <span>
                                            { mediaEditorFields.month } &nbsp;
                                        </span> 
                                    ) : null
                                }

                                {
                                    mediaEditorFields.year ? (
                                        <span>
                                            { mediaEditorFields.year } ·&nbsp;
                                        </span>
                                    ) : null
                                }

                                {
                                    mediaEditorFields.typePhotographerName ? (
                                        <span>
                                            { mediaEditorFields.typePhotographerName } ·&nbsp;
                                        </span>
                                    ) : null
                                }

                                {
                                    !mediaEditorFields.link ? (
                                        <span onClick={() => toEditLink(mediaEditorFields)} className="link-download">
                                        Download High Resolution</span>
                                    ) : (
                                        <a href={getClickableLink(mediaEditorFields.link)} target="_blank">
                                        Download High Resolution</a>
                                    )
                                }
                                

                            </div>
                            <div className="info-editors">
                                <div className="editor-btn" onClick={()=>setEditorType('edit')}>Edit</div>
                                <div className="editor-btn" onClick={() => addMediaPicture()}>
                                    Add Picture
                                 </div>
                                <div className="editor-btn" onClick={()=>deleteStory()}>Delete This Story</div>

                            </div>
                        </div>
                    </div>
                    </>
                )
            }
            
            <div className="collection-looks">
                {
                    getLooks(looks)
                }
            </div>
            
        </div>
        {
            editorType=='edit'&&getPictureEditor()
        }
    </Layout>
}

const mapStateToProps = (state) => {
    return {
        common: state.common,
        media: state.media
    }
}

const mapActionsToProps = {
    getWorkDetail,
    getWorkLookDetail,
    updateStoryBoardLook,
    deleteStoryBoard,
}

export default connect(mapStateToProps, mapActionsToProps)(MediaWorkDetail)