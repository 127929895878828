import { Avatar, Button, List, Popconfirm } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import Layout from "../../components/Layout";
import {
  deleteJob, fetchJobs
} from "../../redux/actions/common";
import "./style.scss";

const Jobs = (props) => {
  const history = props.history;
  const [jobs, setJobs] = useState([]);
  const [total, setTotal] = useState(0);
  const [current, setCurrent] = useState(1);


  const getJobs = useMemo(() => {
    return()=>{
      props
        .fetchJobs({ page: current })
        .then((result) => {
          setJobs(result.data);
          setTotal(result.total);
        })
        .catch((err) => {
          setJobs([]);
        });
    }
  },[current]);

  useEffect(() => {
    getJobs();
  }, [current, getJobs]);

  const newJob = () => {
    history.push(`/jobs/new`);
  };

  const handleRemove = (id) => {
    props.deleteJob({ id }).then((res) => {
      getJobs();
    });
  };

  const addButton = (
    <div className="upload-button" onClick={newJob}>
      <i className="icon-add"></i>Create Job
    </div>
  );

  return (
    <Layout rightButton={addButton}>
      <div className="events-collections">
        {jobs.length > 0 ? (
          <div className="collection">
            <div className="collection-name">Jobs</div>
            <div className="collection-info">
              <div className="info-desc">{total} Jobs</div>
            </div>
            <div>
              <List
                size="large"
                pagination={{
                  simple: true,
                  onChange: (page) => {
                    setCurrent(page);
                  },
                  pageSize: 20,
                  total,
                  showQuickJumper: true,
                }}
                dataSource={jobs}
                renderItem={(item) => (
                  <List.Item
                    key={item.id}
                    actions={[
                      <Button
                        style={{ borderColor: "#505050", color: "#505050" }}
                        onClick={(e) => {
                          history.push({
                            pathname: "/jobs/edit",
                            state: { id: item.id },
                          });
                        }}
                      >
                        Edit
                      </Button>,
                      <Popconfirm
                        placement="bottom"
                        title={"Are you sure to delete this item?"}
                        onConfirm={() => {
                          handleRemove(item.id);
                        }}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button
                          style={{ borderColor: "#F58C78", color: "#F58C78" }}
                        >
                          Delete
                        </Button>
                      </Popconfirm>,
                    ]}
                  >
                    <List.Item.Meta
                      title={
                        <a
                          href="javascript:void(0)"
                          onClick={() => {
                            history.push({
                              pathname: "/jobs/show",
                              state: { id: item.id },
                            });
                          }}
                        >
                          {item.title}
                        </a>
                      }
                      avatar={
                        <Avatar
                          src={item?.profile?.logo || item?.profile?.name}
                        />
                      }
                      description={item.sub_title}
                    />
                  </List.Item>
                )}
              />
            </div>
          </div>
        ) : (
          <div className="collection">
            <div className="collection-name">Jobs</div>
            <div className="collection-info">
              <div className="info-desc">0 Jobs</div>
            </div>
            <div className="collection-looks-nothing">
              <div className="noting-img">
                <div className="noting-img-arrow"></div>
              </div>
              <div className="noting-text">
                Your job page is empty, <br />
                Please create an job here!
              </div>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};

const mapActionsToProps = {
  fetchJobs,
  deleteJob,
};

export default connect(mapStateToProps, mapActionsToProps)(Jobs);
