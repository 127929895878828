import { Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Layout from '../../components/Layout'
import UploadFiles from '../../components/UploadFiles'
import { getAllCollections, getAllSeasons, getColectionAgencyBrand } from '../../redux/actions/brand'
import { locationJSON } from '../../utility'
import './style.scss'



const BrandCollections = props => {
    const {common:{userInfo}}=props
    const history=props.history
    const [type, setType]=useState()
    const [collections, setCollections]=useState([])
    const router=locationJSON(window.location)
    const [seasons, setSeasons] = useState([])
    const brandManage = localStorage.getItem('infoBrand')
    const convertBrandManage = JSON.parse(brandManage)

    const getCollections=()=>{
        let season_type='all'
        const router=locationJSON(window.location)
        if(router.queryObject.type){
            season_type=router.queryObject.type
        }
        setType(season_type)
        
        props.getAllCollections(season_type, router.queryObject.filter)
        .then(result=>{ 
            setCollections(result)
        })
        .catch(err=>{
            setCollections([])
        })
    }
    const getAllSeasons = () => {
        props.getAllSeasons().then(result => {
            setSeasons(result)
            
        })
      }
    const getColectionAgencyBrand = () => {
        let season_type='all'
        const router=locationJSON(window.location)
        if(router.queryObject.type){
            season_type=router.queryObject.type
        }
        setType(season_type)
        if (localStorage.getItem('infoBrand')) {
            const infoBrand = localStorage.getItem('infoBrand')
            const convertInfoBrand = JSON.parse(infoBrand)
            const data = {
                id: convertInfoBrand.id,
            }
            props.getColectionAgencyBrand(data).then((result) => {
                setCollections(result.brand_collections)
            }).catch(err=>{
                setCollections([])
            })
        }
    }
    
    useEffect(()=>{
        console.log(userInfo)
        // if (userInfo.business_type === "agency") {
        //     getColectionAgencyBrand()
        // } else {
            getCollections();
        // }
        getAllSeasons();
        const listen=history.listen((arg)=>{
            if(arg.pathname==='/brandCollections' && userInfo.business_type === "brand"){
                getCollections()
            }
        })
        return listen
    },[])

    const toDetail=(collection)=>{
        let id=collection.id
        history.push(`/brandCollectionDetail/${id}${type!=='all'?`?type=${type}`:''}${paramsFilter()}`)
    }

    const paramsFilter = () => {
        let filter = '';
        if (router.queryObject.filter === 'sample') {
            filter = '?sample'
        } else if (router.queryObject.filter === 'clipping') {
            filter = '?clipping'
        } else {
            filter = '';
        }

        return filter
    }
    const getLooks=(looks, id)=>{
        return looks.map((look, index)=>(
            index > 4 ? '' : index === 4 && looks.length >= 6?(
                <div className="look" key={look.id}>
                    <Link to={`/brandCollectionDetail/${id}${type!=='all'?`?type=${type}`:''}${paramsFilter()}`}>
                        <div className="look-img view-more" style={{backgroundImage:`url(${look.file.url})`}}>
                            {/* <img className="img" src={look.url} /> */}
                            <div className="look-more">View More</div>
                        </div>
                        
                        <Tooltip  color="gray" placement="bottom" title={look.name}> <div className="look-name-more">{look.name}</div></Tooltip>
                    </Link>
                </div>
            ):(
                <div className="look" key={look.id}>
                    <Link to={`/brandLookDetail/${id}/${look.id}`}>
                        <div className="look-img" style={{backgroundImage:`url(${look.file.url})`}}>
                            {/* <img className="img" src={look.file.url} /> */}
                        </div>
                        <div className="look-name"><Tooltip placement="bottomLeft" color="gray" title={look.name}>{look.name}</Tooltip></div>
                    </Link>
                </div>
            )
        ))
    }

    const toEditLink = (collection) => {
        if (!collection.link) {
            history.push({
                pathname: `/brandCollectionDetail/${collection.id}`,
                params: 'picture',
            });
        }
    }

    const getSeasonCollection = (id) => {
        let name = ''
        let index = seasons.findIndex(item => item.id === id)
        if (index !== -1){
            name = seasons[index].name + ' ' + seasons[index].year
        }
        return name;
    }

    const getClickableLink = link => {
        return link.startsWith("http://") || link.startsWith("https://") ?
          link
          : `http://${link}`;
    };
    
    

    return (
      <Layout
        rightButton={
          <UploadFiles type="brand">Create A Collection</UploadFiles>
        }
      >
        <div className="brand-collections">
          {collections.length > 0 ? (
            collections.map((collection, index) => (
              <div className="collection" key={index}>
                <div
                  className="collection-name"
                  onClick={() => toDetail(collection)}
                >
                  {/* {
                                userInfo.business_type === 'agency' ? (
                                    <span>{convertBrandManage.name}</span>
                                ) : (
                                    <span>{userInfo.business_name}</span>
                                )
                            } */}
                  <span>{userInfo.business_name}</span>·{" "}
                  {getSeasonCollection(collection.season_id)}
                </div>
                <div className="collection-info">
                  <div className="info-desc">
                    {collection.collection_looks
                      ? collection.collection_looks.length
                      : 0}{" "}
                    Looks ·{" "}
                    {collection.collection_clippings
                      ? collection.collection_clippings.length
                      : 0}{" "}
                    Clippings ·{" "}
                    {collection.collection_samples
                      ? collection.collection_samples.length
                      : 0}{" "}
                    Samples ·&nbsp;
                    {!collection.link ? (
                      <span
                        onClick={() => toEditLink(collection)}
                        className="link-download"
                      >
                        Download High Resolution
                      </span>
                    ) : (
                      <a
                        href={getClickableLink(collection.link)}
                        target="_blank"
                      >
                        Download High Resolution
                      </a>
                    )}
                  </div>
                </div>
                <div className="collection-looks">
                  {/* {
                                getLooks(collection.collection_looks, collection.id)
                            } */}
                  {router.queryObject.filter === "sample"
                    ? getLooks(collection.collection_samples, collection.id)
                    : router.queryObject.filter === "clipping"
                    ? getLooks(collection.collection_clippings, collection.id)
                    : getLooks(collection.collection_looks, collection.id)}
                </div>
              </div>
            ))
          ) : (
            <div className="collection">
              <div className="collection-name">Upload Files</div>
              <div className="collection-info">
                <div className="info-desc">0 Pictures</div>
              </div>
              <div className="collection-looks-nothing">
                <div className="noting-img">
                  <div className="noting-img-arrow"></div>
                </div>
                <div className="noting-text">
                  Your collection is empty, <br />
                  Please create a collection here!
                </div>
              </div>
            </div>
          )}
        </div>
      </Layout>
    );
}


const mapStateToProps=(state)=>{
    return {
        common:state.common,
        brand:state.brand
    }
}

const mapActionsToProps={
    getAllCollections,
    getAllSeasons,
    getColectionAgencyBrand,
}

export default connect(mapStateToProps, mapActionsToProps)(BrandCollections)