import React, { useState, useEffect } from 'react'
import './style.scss'
import {images} from '../../utility/const'

const LoadAnimation = props => {
    const {done}=props
    const [progress, setProgress]=useState(0)
    const [finish, setFinish]=useState(false)

    useEffect(()=>{
        setProgress(90)
        
        if(done){
            setProgress(100)
            setTimeout(() => {
                setFinish(true)
            }, 600);
        }
    },[done])


    return finish?'':<div className="load-fixed-bg">
                <div className="progress-bar" style={{width:`${progress}%`}}></div>
                <div className="load-wraper">
                    <div className="logo">
                        <img src={images.logo} />
                    </div>
                    <div className="company-name">
                        <img src={images.ASB} />
                    </div>
                </div>
            </div>
}

export default LoadAnimation
