import { Skeleton } from 'antd'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import InviteButton from '../../components/InviteButton'
import Layout from '../../components/Layout'
import listCountry from '../../country'
import { getAllBrands, getMyBrands } from '../../redux/actions/media'
import { createFollower } from '../../request'
import { locationJSON } from '../../utility'
import { images } from '../../utility/const'
import './style.scss'


const MediaBrands = props => {
    const {common:{userInfo}}=props
    const [brandList, setBrandList]=useState([])
    const history=props.history
    const [brandType, setBrandType]=useState()
    const [lastId, setLastId]=useState('')
    const [isDataEmpty, setIsDataEmpty]=useState(false)
    const limit = 8
    const [data,setData] = useState([]);
    const [total,setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [current, setCurrent] = useState(1);
    const [search, setSearch] = useState('');

    const getMediaBrands = () => {
        if(loading){
            return
        }
        setLoading(true)
        const router=locationJSON(window.location)
        let brand_type='allbrand'
        if(router.queryObject.type){
            brand_type=router.queryObject.type
        }
        setBrandType(brand_type)

        if (brand_type === 'allbrand'){
            getAllBrands();
        } else{
            getMyBrands();
        }
    }

    const getCountry = (index) => {
        const parseIndex = parseInt(index)
        if (parseIndex || parseIndex === 0) {
            return listCountry[parseIndex].name
        } else {
            return ''
        }
    }

    const getAllBrands = () => {
        const params = {
            page: current,
            search
        }
        props.getAllBrands(params)
        .then(result=>{
            
            const rows = data.concat(
              result.data.filter((row) => !data.some((r) => r.id === row.id))
            );
            console.log(data,result.data)
            setData(rows);
            setTotal(result.count)
            setLoading(false);
        })
        .catch(err=>{
            setLoading(false);
        })
    }

    const getMyBrands = () => {
        props.getMyBrands(current)
        .then(result=>{
            const rows = data.concat(
              result.data.filter((row) => !data.some((r) => r.id === row.id))
            );
            setData(rows);
            setTotal(result.count)
            setLoading(false);
        })
        .catch(err=>{
            setBrandList([])
        })
    }

    const sortAlphabe = (char) => {

        setData([]);
        setTotal(0)
        setCurrent(1);
        if(search === char || char == '#'){
            setSearch('')
        }else{
            setSearch(char)
        }
    }


    const loadMoreMedia = () => {
        getMediaBrands();
    }

    useEffect(()=>{
        getMediaBrands()
    },[current])

    useEffect(()=>{
        getMediaBrands()
    },[search])

    useEffect(()=>{
        setData([]);
        setTotal(0)
        setCurrent(1);
        getMediaBrands()
    },[history.location,search])


    const showProfile = (brand) => {
        history.push(`/profile/${brand.business_user_mapping_id}`)
    }

    const connectBrand= (brand) =>{
            createFollower(userInfo.business_type, brand.id, brand.user_business_id)
            .then(res=>{
                const index = brandList.findIndex(item => item.id === brand.id)
                let dataTemp = [...brandList]
                if (index !== -1) {
                    dataTemp[index].status = 'Pending'
                    setBrandList(dataTemp)
                }
            })
        }
    const renderItem = (brand,index) =>
    <Skeleton avatar title={false} loading={loading} active><div className="media" key={index}>
                <div className="media-avatar" onClick={() => showProfile(brand)}>
                    <img alt="" src={brand?.avatar || images.avatar_default}/>
                </div>
                <div className="media-info">
                    <div className="info1 info-main" onClick={() => showProfile(brand)}>   
                        <div className="info-name">
                            {brand.name}
                            <div className="info-agent"></div>
                        </div>
                        <div className="info-desc">{brand.name} · {brand.city} · {getCountry(brand.country)}</div>
                    </div>
                    <div className="info2">
                        {
                            brand.status==='Connected'?(
                                <div className="info-connect connected">
                                    Connected
                                    <i className="icon-right"></i>
                                </div>
                            ):brand.status==='Pending'?(
                                <div className="info-connect pending">Pending</div>
                            ):(
                                <div className="info-connect" onClick={()=>connectBrand(brand)}>Connect</div>
                            )
                        }
                       
                    </div>
                </div>
            </div>
    </Skeleton>

    const getHeadMenus=()=>{
        if(brandType!=='allbrand') return ''
        
        const list="ABCDEFGHIJKLMNOPQRSTUVWXYZ#".split('')
        return <div className="az-list">
            {
                list.map((char,index)=>(
                    <span className={`az-item ${search === char ? 'active' : ''}`} key={index} onClick={() => sortAlphabe(char)}>{char}</span>
                ))
            }
        </div>
    }


    return <Layout 
                headMenus={getHeadMenus()} 
                rightButton={<InviteButton type="brand">Invite Brand</InviteButton>}>
        <div className="media-brands">
            <div className="info">
                <div className="info-name">
                    {brandType==='allbrand'?'All Brands':'My Brands'}
                </div>
                <div className="info-desc">{total} Brands</div>
            </div>
            <div className="medias">
                {
                    data.map(renderItem)
                }
            </div>
            {
                data.length < total ? (        
                <div className="button-load-mode">
                    <span  style={{userSelect: 'none'}} onClick={() => setCurrent(current+1)}>Unlock More Media</span>
                </div>
                ) : null
             }
        </div>
    </Layout>
}

const mapStateToProps=(state)=>{
    return {
        common:state.common,
        brand:state.media
    }
}

const mapActionsToProps={
    getAllBrands,
    getMyBrands,
    createFollower,
}

export default connect(mapStateToProps, mapActionsToProps) (MediaBrands)
