import { Card, InputNumber, List, message, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Layout from '../../components/Layout'
import { getBannerSorts, updateBannerSort } from '../../redux/actions/common'
import { locationJSON } from '../../utility'
import './style.scss'


const BannerSort = props => {
    const {common:{userInfo}}=props
    const history=props.history
    const [type, setType]=useState()
    const [collections, setCollections]=useState([])
    const [total, setTotal]=useState(0)
    const router=locationJSON(window.location)
    const [current, setCurrent] = useState(1)

    const getCollections=()=>{
        props.getBannerSorts({page: current})
        .then(result=>{ 
            setCollections(result.data)
            setTotal(result.total)
        })
        .catch(err=>{
            setCollections([])
        })
    }
    useEffect(()=>{
         getCollections();
    },[current])
    
    const handleChange = (id,value) =>{
        props.updateBannerSort({banner_sort:{id,sort: value}}).then(res=>{
            // getCollections();
        }).catch(err=>{
            message.error('sort number change error')
        })
    }

    return <Layout>
        <div className="banner-sorts-collections">
        {
            collections.length>0?(
                <div className="collection">
                    <div className="collection-name">Banner Sorts</div>
                    <div className="collection-info">
                        <div className="info-desc">
                        {total} Banner Sorts
                        </div>
                    </div>
                    <div>
                        <List
                        grid={{
                            gutter: 16, column: 4
                        }}
                    size="large"
                    pagination={{
                        simple: true,
                    onChange: page => {
                        setCurrent(page);
                    },
                    pageSize: 20,
                    total,
                    showQuickJumper:true
                    }}
                    dataSource={collections}
                    renderItem={item => (
                    <List.Item
                        key={item.id}
                    >
                        <Card title={item.desc}>
                            <Tooltip
                                    trigger={['focus']}
                                    title="banner sorts number"
                                    placement="topLeft"
                                    overlayClassName="numeric-input"
                                >
                            <InputNumber onChange={e=>{handleChange(item.id,e)}} min={1} max={100} keyboard={true} defaultValue={item.sort} /></Tooltip>
                            </Card>
                    </List.Item>
                    )}
                />
                    </div>
                </div>
                
            ):(
                <div className="collection">
                    <div className="collection-name">Banner Sorts</div>
                    <div className="collection-info">
                        <div className="info-desc">
                        0 Banner Sorts
                        </div>
                    </div>
                    <div className="collection-looks-nothing">
                        <div className="noting-img">
                            {/* <div className="noting-img-arrow"></div> */}
                        </div>
                        <div className="noting-text">
                            Your banner sorts page is empty, <br/>
                            Please create an banner sort here!
                        </div>
                    </div>
                </div>
            )
        }
            
        </div>
    </Layout>
}


const mapStateToProps=(state)=>{
    return {
        common:state.common
    }
}

const mapActionsToProps={
    getBannerSorts,
    updateBannerSort
}

export default connect(mapStateToProps, mapActionsToProps)(BannerSort)