const brand = (state = {}, action) => {
    switch (action.type) {
        case "getCollections":
            return {
                ...state,
                ...action.payload
            };

        case "getCollectionDetail":
            return {
                ...state,
                collectionDetail:action.payload
            };
        
        case "getSeasons":
            return {
                ...state,
                seasons:action.payload
            };
            
        case "getBrandLookDetail":
            return {
                ...state,
                brandLookDetail:action.payload
            }; 

        default:
            return {
                ...state
            };
    }
}


export default brand