import React,{useState} from 'react';
import './index.scss'


export default function CustomAnchor(props){
  const {links=[]} = props
  const [value,setValue] = useState(0) 

  const scrollToAnchor = (name) =>{
    if(name){
      let el = document.getElementById(name)
      if(el){
        el.scrollIntoView({
          behavior: 'smooth', // smooth scroll
          block: 'center',
          inline: 'center'
        })
        // window.scrollTo(el.offsetTop)
        // el.scrollTop(120);
        // el.scrollIntoView({block: 'start', behavior: 'smooth',top: el.offsetTop + 120});
        // el.scrollTop = el.offsetTop + 120
      }
    }
  }

  return <div className={`anchor-group`}>
    {
      links.map((link,index)=>{


        return <div className="anchor-item">
          <div key={link.id}>
            <div className={`title ${index === value ? 'active' : ''}`} onClick={()=>{
              scrollToAnchor(link.id)
              setValue(index)
            }}>{link.title}</div>
            <div className={`bottom-line ${index === value ? 'active-line' : ''}`}></div>
          </div>
        </div>
      })
    }
  </div>
}