import React, { useState, useEffect } from 'react'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import './style.scss'

import Layout from '../../components/Layout'
import UploadFiles from '../../components/UploadFiles'
import {locationJSON} from '../../utility'
import {getAllWorks} from '../../redux/actions/media'
import {Tooltip } from "antd";

const MediaWorks = props => {
    const history=props.history
    const {common:{userInfo}}=props
    const [works, setWorks]=useState([])
    const obj=locationJSON(window.location)
    const getWorks=()=>{    
        props.getAllWorks()
        .then(result=>{
            setWorks(result)
        })
        .catch(err=>{
            setWorks([])
        })
    }

    useEffect(()=>{
        getWorks()
        const listen=history.listen((arg)=>{
            if(arg.pathname=='/mediaWorks'){
                getWorks()
            }
        })
        return listen
    },[])

    const toDetail=(work)=>{
        let id=work.id
        history.push(`/mediaWorkDetail/${id}`)
    }

    const toEditLink = (work) => {
        if (!work.link) {
            history.push({
                pathname: `/mediaWorkDetail/${work.id}`,
                params: 'picture',
            });
        }
    }

    const getLooks=(looks, id)=>{
        return looks.map((look, index)=>(
            index>4?'':index===4&&looks.length>5?(
                <div className="look" key={look.id}>
                    <Link to={`/mediaLookDetailByMedia/${id}/${look.id}`}>
                    <div className="look-img view-more" style={{backgroundImage:`url(${look.file.url})`}}>
                        {/* <img className="img" src={look.url} /> */}
                        <div className="look-more">View More</div>
                        
                    </div>     
                    <Tooltip color="gray" placement="bottom" title={look.name}><div className="look-name">{look.name}</div></Tooltip>
                    </Link>
                </div>
            ):(
                <div className="look" key={look.id}>
                    <Link to={`/mediaLookDetailByMedia/${id}/${look.id}`}>
                    <div className="look-img" style={{backgroundImage:`url(${look.file.url})`}}>
                            {/* <img className="img" src={images.look_demo} /> */}
                        </div>
                        <Tooltip color="gray" placement="bottomLeft" title={look.name}><div className="look-name">{look.name}</div></Tooltip>
                    </Link>
                </div>
            )
        ))
    }
    const getClickableLink = link => {
        return link.startsWith("http://") || link.startsWith("https://") ?
          link
          : `http://${link}`;
    };
    return <Layout rightButton={<UploadFiles type="media">Create A Story</UploadFiles> }>
        <div className="media-works">
            {
                works.map((work, index)=>(
                    <div className="collection" key={index}>
                        <div className="collection-name" onClick={()=>toDetail(work)}>{ work.name }</div>
                        <div className="collection-info">
                            <div className="info-desc">
                                {
                                    work.description ? (
                                        <span>
                                            { work.description } ·
                                        </span>
                                    ) : null
                                }

                                {
                                    work.publication ? (
                                        <span>
                                            { work.publication } ·&nbsp;
                                        </span>
                                    ) : null
                                }

                                {
                                    work.month ? (
                                        <span>
                                            { work.month } &nbsp;
                                        </span> 
                                    ) : null
                                }

                                {
                                    work.year ? (
                                        <span>
                                            { work.year } ·&nbsp;
                                        </span>
                                    ) : null
                                }

                                {
                                    work.type_photographer_name ? (
                                        <span>
                                            { work.type_photographer_name } ·&nbsp;
                                        </span> 
                                    ) : null
                                }

                                {
                                    !work.link ? (
                                        <span onClick={() => toEditLink(work)} className="link-download">
                                        Download High Resolution</span>
                                    ) : (
                                        <a href={getClickableLink(work.link)} target="_blank">
                                        Download High Resolution</a>
                                    )
                                }
                            </div>
                        </div>
                        <div className="collection-looks">
                            {
                                getLooks(work.story_board_looks, work.id)
                            }
                        </div>
                    </div>
                ))
            }
        </div>
    </Layout>
}

const mapStateToProps=(state)=>{
    return {
        common:state.common,
        media:state.media
    }
}

const mapActionsToProps={
    getAllWorks
}

export default connect(mapStateToProps, mapActionsToProps)(MediaWorks)