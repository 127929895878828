import { ExclamationCircleOutlined } from '@ant-design/icons';
import { message, Modal, Progress, Tooltip, Upload } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import FormSelect from "../../components/FormSelect";
import Layout from "../../components/Layout";
import ModalAdd from "../../components/Modal";
import UploadFiles from "../../components/UploadFiles";
import { deleteAllLooks, deleteCollection, getAllSeasons, getCollectionDetail, getCollectionDetailClipping, getCollectionDetailSample } from '../../redux/actions/brand';
import {
  addLooks, addSamples, changePublic, deleteClippings, deleteLooks, deleteSamples, putShowLooks, updateCollection
} from "../../request";
import { images } from "../../utility/const";
import "./style.scss";
const { confirm } = Modal;


const BrandCollectionDetail = (props) => {
  const history = useHistory();
  const {
    common: { userInfo },
  } = props;
  const params = useParams();
  const [editorType, setEditorType] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [collectionDetail, setCollectionDetail] = useState([]);
  const [lookSelectName, setLookSelectName] = useState([])
  const [looks, setLooks] = useState([]);
  const [lookSamples, setLookSamples] = useState([]);
  const [lookClippings, setLookClippings] = useState([]);
  const [collectionEditors, setCollectionEditors] = useState({ name: "", link: "", });
  const [percent, setPercent] = useState(0);
  const [status, setStatus] = useState("init");
  const [filelist, setFilelist] = useState();
  const [listUpdatedLooks, setListUpdatedLooks] = useState([]);
  const [listRemovedLooks, setListRemovedLooks] = useState([]);
  const [listSelectedSample, setListSelectedSample] = useState([]);
  const accept = ["image/png", "image/jpeg", "image/jpg"];
  const [years, setYears] = useState([])
  const [seasonsOfYear, setSeasonsOfYear] = useState([])
  const [seasons, setSeasons] = useState([])
  const [currentSeason, setCurrentSeason] = useState()
  const [currentYear, setCurrentYear] = useState()
  const [dataLooks, setDataLooks] = useState([]);
  const [allSeasons, setAllSeasons] = useState([])
  const brandManage = localStorage.getItem('infoBrand')
  const convertBrandManage = JSON.parse(brandManage)
  const [isPublic, setIsPublic] = useState(false)

    const [currentMonth, setCurrentMonth] = useState();
    const newMonths = [
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
    ];

    const mapping = {
      "Fall/Winter": "12",
      "Pre-Fall": "08",
      "Spring/Summer": "05",
      Resort: "01",
    };

  let countTemp = 0
  useEffect(() => {
    const { type, router } = props
    if (sample !== -1){
      getCollectionDetailSample()
    } else if (clipping !== -1) {
      getCollectionDetailClipping()
    } else {
      getCollectionDetail()
    }
    
    if (history.location.params) {
      setEditorType(history.location.params)
    }
  }, []);

  const sample = history.location.search.indexOf('sample')
  const clipping = history.location.search.indexOf('clipping')

  const getCollectionDetail = () => {

    let season_id = ''
    props
    .getCollectionDetail(params.collectionId)
    .then((result) => {
      setCollectionDetail(result);
      setLooks(result.collection_looks);
      setDataLooks(result.collection_looks)
      season_id = result.season_id;
      setIsPublic(result.is_mp)
      let dataTemp = [];
      result.collection_looks.forEach(element => {
        if (element.published) {
          dataTemp.push(element)
        }
      });
      getAllSeasons(season_id)

      setCollectionEditors({
        ...result,
      });
      setListUpdatedLooks(dataTemp)

    })
    .catch((err) => {
      setCollectionDetail([]);
      setLooks([]);
      setDataLooks([])
    });
  }

  const getCollectionDetailSample = () => {
    let season_id = ''
    props
    .getCollectionDetailSample(params.collectionId)
    .then((result) => {
 
      setCollectionDetail(result);
      setLookSamples(result.collection_samples);
      setDataLooks(result.collection_samples)
      season_id = result.season_id;
      let dataTemp = [];
      result.collection_samples.forEach(element => {
        if (element.published) {
          dataTemp.push(element)
        }
      });
      getAllSeasons(season_id)
      setCollectionEditors({
        ...result 
      });

      setListUpdatedLooks(dataTemp)
    })
    .catch((err) => {
      setCollectionDetail([]);
      setLookSamples([]);
      setDataLooks([])
    });
  }

  const getCollectionDetailClipping = () => {
    let season_id = ''
    props
    .getCollectionDetailClipping(params.collectionId)
    .then((result) => {
      setCollectionDetail(result);
      setLookClippings(result.collection_clippings);
      setDataLooks(result.collection_clippings)
      season_id = result.season_id;
      let dataTemp = [];
      result.collection_clippings.forEach(element => {
        if (element.published) {
          dataTemp.push(element)
        }
      });
      getAllSeasons(season_id)
      setCollectionEditors({
        link: result.link,
        name: result.name,
      });

      setListUpdatedLooks(dataTemp)
    })
    .catch((err) => {
      setCollectionDetail([]);
      setLookClippings([]);
      setDataLooks([])
    });
  }

  const getAllSeasons = (season_id) => {
    props.getAllSeasons().then(result => {
        let seasons = {}
        result.forEach(item => {
            if (seasons[item.year]) {
                if (item.id === season_id) {
                    setCurrentSeason({ value: item.id, year: item.year, name: item.name })
                    setCurrentYear({value: item.year})
                    setSeasonsOfYear(seasons[item.year])
                }
                seasons[item.year].push({ value: item.id, year: item.year, name: item.name })
            } else {
                seasons[item.year] = [{ value: item.id, year: item.year, name: item.name }]

                if (item.id === season_id) {
                  setCurrentSeason({ value: item.id, year: item.year, name: item.name })
                  setCurrentYear({value: item.year})
                  setSeasonsOfYear(seasons[item.year])
                }
            }
        })
        let years = Object.keys(seasons).map(key => ({ value: key, value: key }))
        years.sort((pre, next) => next.value - pre.value)
        setSeasons(seasons)
        setAllSeasons(result)
        setYears(years)
    })
  }

  const clickShowModal = (bool) => {
    setShowModal(bool);
    setFilelist();
    setPercent(0);
    setStatus("init");
  };

  const handleInputChangeName = (e, index) => {
    let dataTempLooks = looks
    let dataTempUpdate = [];
    let newData = [...lookSelectName]
    dataTempLooks[index].name = e.target.value

    const findIndex = newData.findIndex(item => item.id === looks[index].id)

    if (findIndex === -1) {
      newData.push(dataTempLooks[index])
    } else {
      newData[findIndex].name = e.target.value
    }
    setLookSelectName(newData)
    setLooks(dataTempLooks)
    setDataLooks(dataTempLooks)
    listUpdatedLooks.forEach(element => {
      if (element.published) {
        dataTempUpdate.push(element)
      }
    });
    setListUpdatedLooks(dataTempUpdate)
  }

  const getLooks = (looks) => {
    const removeSelectedLook = (look) => { 
      confirm({
        title: `Are you sure you want to delete Look?`,
        icon: <ExclamationCircleOutlined />,
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk() {
          setListRemovedLooks([...listRemovedLooks, look]);
          if (sample !== -1){
            setLookSamples(lookSamples.filter((item) => item.id !== look.id))
            setDataLooks(lookSamples.filter((item) => item.id !== look.id));
            let dataTemp = [...listUpdatedLooks];
            const index = listUpdatedLooks.findIndex(item => item.id === look.id);
            dataTemp.splice(index, 1)
            setListUpdatedLooks(dataTemp)
            deleteSamples(params.collectionId, look.id)
            .then(
            setEditorType('picture'),
            setCollectionEditors(collectionEditors),
            message.success('Detete Success'),
            )
          } else if (clipping !== -1){
            setLookClippings(lookClippings.filter((item) => item.id !== look.id))
            setDataLooks(lookClippings.filter((item) => item.id !== look.id));
            let dataTemp = [...listUpdatedLooks];
            const index = listUpdatedLooks.findIndex(item => item.id === look.id);
            dataTemp.splice(index, 1)
            setListUpdatedLooks(dataTemp)
            deleteClippings(params.collectionId, look.id)
            .then(
            setEditorType('picture'),
            setCollectionEditors(collectionEditors),
            message.success('Detete Success'),
            ) 
          } else {
            setLooks(looks.filter((item) => item.id !== look.id))
            setDataLooks(looks.filter((item) => item.id !== look.id));
            let dataTemp = [...listUpdatedLooks];
            const index = listUpdatedLooks.findIndex(item => item.id === look.id);
            dataTemp.splice(index, 1)
            setListUpdatedLooks(dataTemp)
            deleteLooks(collectionDetail.id, look.id).then(
            setEditorType('picture'),
            setCollectionEditors(collectionEditors),
            message.success('Detete Success')
          )}
        },
        onCancel() {
          setEditorType('picture')
        }
      });
    };

    const paramsFilter = () => {
      let filter = '';
      if (sample === 1) {
          filter = '?sample'
      } else if (clipping === 1) {
          filter = '?clipping'
      } else {
          filter = '';
      }

      return filter
  }

    // Show Look
    const setSelected = (look, index) => {
      setListUpdatedLooks([...listUpdatedLooks, look]);
      if (sample !== -1){
        let newArr = [...lookSamples];
        if (look.selected) {
          newArr[index].selected = false;
          const findIndex = listSelectedSample.findIndex(item => item.id === look.id)
          if (findIndex !== -1) {
            listSelectedSample.splice(findIndex, 1);
            setListSelectedSample(listSelectedSample)
          }
        } else {
          newArr[index].selected = true;
          setListSelectedSample([...listSelectedSample, look])
        }
        setLookSamples(newArr);
        setDataLooks(newArr);
        
      } else if (clipping !== -1){
        let newArr = [...lookClippings];
        if (look.selected) {
          newArr[index].selected = false;
          const findIndex = listSelectedSample.findIndex(item => item.id === look.id)
          if (findIndex !== -1) {
            listSelectedSample.splice(findIndex, 1);
            setListSelectedSample(listSelectedSample)
          }
        } else {
          newArr[index].selected = true;
          setListSelectedSample([...listSelectedSample, look])
        }
        setLookClippings(newArr);
        setDataLooks(newArr);
      }else{
        let newArr = [...looks];
        if (look.selected) {
          newArr[index].selected = false;
          const findIndex = listSelectedSample.findIndex(item => item.id === look.id)
          if (findIndex !== -1) {
            listSelectedSample.splice(findIndex, 1);
            setListSelectedSample(listSelectedSample)
          }
        } else {
          newArr[index].selected = true;
          setListSelectedSample([...listSelectedSample, look])
        }
        setLooks(newArr);
        setDataLooks(newArr);
      }
    };



    return dataLooks.map((look, index) => { 
      return (
        <div className="look" key={index}>
          {editorType === "picture" ? (
            <>
              <div
                className="look-img"
                style={{ backgroundImage: `url(${look.file.url})` }}
              ></div>
              <div className="look-editor">
                <div
                  className={`editor-checkbox ${
                    look.selected ? "checked" : ""
                  }`}
                  onClick={() => setSelected(look, index)}
                ></div>
                {
                  sample === 1 ? (
                    <div className="editor-name">
                     <input style={{border: 'none', backgroundColor: "white"}} value={look.name} onChange={(value) => handleInputChangeName(value, index)} disabled/>
                    </div>
                  ) : (
                    <div className="editor-name">
                     <input value={look.name} onChange={(value) => handleInputChangeName(value, index)} />
                    </div>
                  )
                }

                <div
                  className="icon-close"
                  onClick={() => removeSelectedLook(look)}
                ></div>
              </div>
            </>
          ) : (
              <Link to={`/brandLookDetail/${params.collectionId}/${look.id}${paramsFilter()}`}>
                <div
                  className="look-img"
                  style={{ backgroundImage: `url(${look.file.url})` }}
                ></div>
                <div className="look-name"><Tooltip color="gray" placement="bottomLeft" title={look.name}>{look.name}</Tooltip></div>
              </Link>
          )} 
        </div>
      );
    });
  };

  const cancel = () => {
    setEditorType("");
  };

  const changNameLook = () => {
    const params = {
      conditions: lookSelectName
    }
    if (lookSelectName.length > 0) {
      putShowLooks(collectionDetail.id, params).then(() => {
        setLookSelectName([])
      })
    }
  }

  const confirmInfoEditor = () => {
    const changeNameLook = new Promise((resolve, reject) => {
      changNameLook();
      resolve()
    })
    const changeInfo = new Promise ((resolve, reject) => {
      console.log(collectionEditors);
      console.log(currentMonth,currentSeason);
      const params = {
        ...collectionEditors,
        link: collectionEditors.link,
        name: `${currentSeason.name} ${currentSeason.year}`,
        season_id: currentSeason.value,
        v_date: `${currentSeason.year}-${currentMonth}`,

        // flag: true
      }; 
      if (sample !== -1){

      } else if (clipping !== -1){

      } else {
        updateCollection(collectionDetail.id, params).then(
          (res) => {
            setEditorType("");
            setCollectionEditors(collectionEditors);
            
          }
        );
      }
      resolve()
    })
    
    Promise.all([changeInfo, changeNameLook]).then(() => {
      setEditorType('')
      setCollectionEditors(collectionEditors)
      message.success('Change sussess!')
      getCollectionDetail()
    });
   
  };
  const getInfoEditor = () => {
    const onChange = (name, value) => {
        console.log(name,value)
        if (name === "years") {
          setSeasonsOfYear(seasons[value]);
          setCurrentYear({ value: Number(value) });
          setCurrentSeason(seasons[value][0]);
          if (Object.keys(mapping).includes(seasons[value][0])) {
            setCurrentMonth(mapping[seasons[value][0].name]);
          }
        } else if (name === "seasonsOfYear") {
          setCurrentSeason(value);
          if(Object.keys(mapping).includes(value.name)){
            setCurrentMonth(mapping[value.name]);
          }
        } else if (name === "month") {
          setCurrentMonth(value);
        }
    };

    const handleInputChange = (e, index) => {
      const { name, value } = e.target;
      setCollectionEditors({ ...collectionEditors, [name]: value });
    };

    return (
      <div className="info-editor">
        <div>
          {/* <div className="form-input info-editor-linkinput">
            <input
              className="input-text"
              name="name"
              type="text"
              value={collectionEditors.name}
              onChange={handleInputChange}
              placeholder="Name"
            />
          </div> */}
          <div className="form-input info-editor-linkinput">
            <input
              className="input-text"
              name="link"
              type="text"
              value={collectionEditors.link}
              onChange={handleInputChange}
              placeholder="Add high resolution link here"
            />
          </div>
          <div className="form-input info-editor-linkinput">
            <input
              className="input-text"
              name="tags"
              type="text"
              value={collectionEditors.tags}
              onChange={handleInputChange}
              placeholder="Add tags"
            />
          </div>
        </div>

        <div className="info-editor-form">
          <div className="form-input">
            <FormSelect
              placeholder="select season"
              value={currentSeason?.value || ""}
              options={seasonsOfYear}
              onChange={(value, option) => onChange("seasonsOfYear", option)}
            />
          </div>
          <div className="form-input">
            <FormSelect
              placeholder="select year"
              value={currentYear?.value || ""}
              options={years}
              onChange={(value) => onChange("years", value)}
            />
          </div>
          {
            !Object.keys(mapping).includes(currentSeason.name) ?
            <div className="form-input">
              <FormSelect
                placeholder="select month"
                value={currentMonth || ""}
                options={newMonths.map(v=>({value: v,label: v}))}
                onChange={(value) => onChange("month", value)}
              />
            </div> : null
          }
          {editorType === "info" ? (
            <>
              <div className="info-editor-cancel" onClick={cancel}>
                Cancel
              </div>
              <div className="info-editor-confirm" onClick={confirmInfoEditor}>
                Confirm
              </div>
            </>
          ) : null}
        </div>
      </div>
    );
  };
  const getPictureEditor = () => {
    const deleteSelected = () => {
      const params = {
        conditions: listSelectedSample.map(i => {
          return {
            id: i.id
          }
        })
      }
      if (listSelectedSample.length === 0){
        message.warning('You must choose pictures')
      } else {
        confirm({
          title: `Are you sure want to delete ${listSelectedSample.length} pictures?`,
          icon: <ExclamationCircleOutlined />,
          okText: 'Yes',
          okType: 'danger',
          cancelText: 'No',
          onOk(){
            props.deleteAllLooks(collectionDetail.id, params)
            .then(
              (res) => {
                setEditorType("");
                getCollectionDetail();
                setListSelectedSample([]);
                message.success('Delete success')
              }
            )
          },
          onCancel() {
            setEditorType('picture')
          }
        })
      }
    }
    const toSample = () => {   
      
      const params = listSelectedSample.map(i => i.id)
      
      addSamples(collectionDetail.id, params)
      .then(
        (res) => {
          history.push('/brandCollections?filter=sample')
        }
     )

    };

    const chooseAll = (status) => {
      const arr = dataLooks.map(i=>{
        i.selected = !status
        return i
      })
      if (looks.filter(i=>i.selected).length === looks.length) {
        setListSelectedSample(arr)
      } else {
        setListSelectedSample([])
      }
      setListUpdatedLooks(arr)
      setLooks(arr)
      setDataLooks(arr)
    };

    return (
          (looks.length) > 0 ? (
            <div className="picture-editor-bottom">
              <div className="picture-editor-choose-all" onClick={() => chooseAll(dataLooks.filter(i=>i.selected).length === dataLooks.length)}>
                <div
                  className={`editor-checkbox ${
                    dataLooks.filter(i=>i.selected).length === dataLooks.length && "checked" 
                  }`}
                ></div>
                  Choose All
              </div>
              <div className="picture-editor-operates">
                <div className="operate-btn" onClick={() => deleteSelected()}>
                  Delete Selected
                </div>
                <div className="operate-btn" onClick={() => deleteColection()}>
                  Delete Collection
                </div>
                <div className="operate-btn" onClick={toSample}>
                  Set as Sample
                </div>
              </div>
            </div>
          ) : null
    );
  };

  const getConfirmToEditPictureEditor = () => {
    return (
      <div className="info-editor">
        {getInfoEditor()}
        <div className="info-editor-form">
          <div className="info-editor-cancel" onClick={cancel}>
            Cancel
          </div>
          <div className="info-editor-confirm" onClick={confirmToEditPicture}>
            Confirm
          </div>
        </div>
      </div>
    );
  };

  //Confirm Edit Picture
  const confirmToEditPicture = () => {
    confirmInfoEditor();
  };

  // AddPicture
  const confirmToAddPicture = () => {
    const formData = new FormData();

    // upload progress
    const updateProgress = (percent) => {
      if (percent < 93) {
        setPercent(percent + 1);
        setTimeout(() => {
          updateProgress(percent + 1);
        }, 10);
      }
    };

    if (!filelist) {
      return message.warn({
        content: `Please select your pictures.`,
      });
    }
    
   
    {
      updateProgress(percent);
      filelist.forEach((file) => {
        formData.append("collection_looks[file][]", file);
      });
      addLooks(collectionDetail.id, formData).then((res) => {
        setCollectionDetail(res);
        setLooks(res.collection_looks);
        setDataLooks(res.collection_looks)
        if (res.link) {
          setCollectionEditors({
            link: res.link,
          });
        }
        setPercent(100);
        setStatus("success");
        clickShowModal(false);
        setLooks(res.collection_looks);
        setDataLooks(res.collection_looks)
        setEditorType("");
        getCollectionDetail()
      });
    }
   
  };

  const isDisable = () => {
      if (filelist && filelist.length > 0) {
        return false;
    }
    return true;
  };

  const closeAdd = () => {
    return clickShowModal(false), setEditorType("");
  };

  
  const toEditLink = (collectionDetail) => {
    if(!collectionDetail.link) {
      setEditorType('picture')
    }
  }

  const getConfirmToAddPictureEditor = () => {
    return (
      <>
        {showModal ? (
          <ModalAdd title={"Add Picture"} onClose={() => closeAdd()}>
            <div className="form-wraper">
              {status == "success" ? (
                <div className="form-upload">
                  <div className="upload-img">
                    <img src={images.icon_upload_success}></img>
                  </div>
                  <div className="upload-text">Upload Success!</div>
                </div>
              ) : (
                getUploadPictures()
              )}
            </div>
            <div
              className={`upload-modal-btn ${isDisable() ? "disable" : ""}`}
              onClick={() => confirmToAddPicture()}
            >
              Confirm
            </div>
          </ModalAdd>
        ) : (
          ""
        )}
        <div className="collection-info">
          <div className="info-desc">
            {looks.length} Looks · 5 Clippings · 4 Samples · 
            {
              !collectionDetail.link ? (
                <span onClick={() => toEditLink(collectionDetail)} className="link-download">
                Download High Resolution</span>
            ) : (
                <a href={getClickableLink(collectionDetail.link)} target="_blank">
                Download High Resolution</a>
            )
            }
          </div>
          <div className="info-editors">
            <div className="editor-btn">Edit</div>
            <div className="editor-btn">Add Picture</div>
          </div>
        </div>
      </>
    );
  };

  const addPicture = () => {
    if (sample !== -1){
      return null;
    } else if ( clipping !== -1){
      return null;
    }else {
      return setEditorType("add"), setShowModal(true), setPercent(0);
    }
    
  };

  const deleteColection = () => {
    confirm({
      title: `Are you sure to delete Collection?`,
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        props.deleteCollection(collectionDetail.id).then(() => {
          message.success('Detete Success');
          history.push('/brandCollections')
        })
      }
    });
  }

  const getUploadPictures = () => {
    const uploadProps = {
      action: "/",
      accept: accept.join(","),
      disabled: false,
      multiple: true,
      showUploadList: false,
      beforeUpload: (file, filelist) => {
        if(countTemp > filelist.length) {
          countTemp = 1
      } else {
          countTemp = countTemp + 1
      }

      if (countTemp === 1 && filelist.length > 15) {
          message.warn({
              content:`No more than 15 photos for each time`
          })
          setFilelist([])
          return false
      } else if (!accept.includes(file.type)) {
          message.warn({
              content: `Only jpeg & png files less than 1MB`
          })
          return false
      } else if (file.size > 1024 * 1024) {
          message.warn({
              content: `Only jpeg & png files less than 1MB`
          })
          return false
      } else if (countTemp === 1 && filelist.length <= 15) {
          setFilelist(filelist)
          setStatus('selected')
          setPercent(0)
          return false
      }
      },
    };

    return (
      <Upload {...uploadProps}>
        <div className="form-upload">
          <div className="upload-img">
            <Progress
              type="circle"
              percent={percent}
              showInfo={false}
              strokeLinecap="square"
              strokeWidth="5"
              strokeColor="#F58C78"
              trailColor="transparent"
            ></Progress>
            <img src={images.icon_upload_img}></img>
          </div>
          <div className="upload-text">
          Please <span className="underline"> Browse & Upload </span> your photos here
          <br />
          *No more than 15 photos for each time
          <br/>
          *Only jpeg & png files less than 1MB
          </div>
        </div>
      </Upload>
    );
  };

  const getSeasonCollection = (id) => {
    let name = ''
    let index = allSeasons.findIndex(item => item.id === id)
    if (index !== -1){
        name = allSeasons[index].name + ' ' + allSeasons[index].year
    }
    return name;
  }

  const setPublic = () => {
    const params = {
      is_mp: !isPublic
    }
    changePublic(collectionDetail.id, params).then(
      (res) => {
        setEditorType("");
        getCollectionDetail();
        message.success('Update complete')
      }
   )
  }

  const toPublic = () => {
    confirm({
      title: `Are you sure to ${!isPublic ? 'Enable ' : 'Disable'}?`,
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        setPublic()
      }
    });
  };

  const getClickableLink = link => {
    return link.startsWith("http://") || link.startsWith("https://") ?
      link
      : `http://${link}`;
  };

  return (
    <Layout
      showBack={true}
      rightButton={<UploadFiles type="brand">Create A Collection</UploadFiles>}
    >
      <div className="collection-detail">
        {editorType === "" ? (
          <div className="collection-name">
            <span>{userInfo.business_name}</span>·{" "}
            {getSeasonCollection(collectionDetail.season_id)}
            {/* · {collectionDetail.name} */}
          </div>
        ) : null}

        <div className="collection-info-wraper">
          {editorType === "info" ? (
            getInfoEditor()
          ) : editorType === "picture" ? (
            getConfirmToEditPictureEditor()
          ) : editorType === "add" ? (
            getConfirmToAddPictureEditor()
          ) : (
            <div className="collection-info">
              <div className="info-desc">
                {looks.length} Looks · {lookClippings.length} Clippings ·{" "}
                {lookSamples.length} Samples ·&nbsp;
                {!collectionDetail.link ? (
                  <span
                    onClick={() => toEditLink(collectionDetail)}
                    className="link-download"
                  >
                    Download High Resolution
                  </span>
                ) : (
                  <a
                    href={getClickableLink(collectionDetail.link)}
                    target="_blank"
                  >
                    Download High Resolution
                  </a>
                )}
              </div>
              <div className="info-editors">
                <div
                  className="editor-btn"
                  onClick={() => setEditorType("picture")}
                >
                  Edit
                </div>
                <div className="editor-btn" onClick={() => addPicture()}>
                  Add Picture
                </div>
                <div className="editor-btn" onClick={toPublic}>
                  {
                    !isPublic ? 'Enable' : 'Disable'
                  }
                </div>
              </div>
            </div>
          )}
        </div>
        {editorType === "add"}
        <div className="collection-looks">{getLooks(looks)}</div>
      </div>
      {editorType === "picture" && getPictureEditor()}
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    common: state.common,
    brand: state.brand,
  };
};

const mapActionsToProps = {
  getCollectionDetail,
  getAllSeasons,
  getCollectionDetailSample,
  getCollectionDetailClipping,
  deleteCollection,
  deleteAllLooks,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(BrandCollectionDetail);
