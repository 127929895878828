import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import './style.scss'

import { images } from '../../utility/const'
import LayoutSimple from '../../components/Layout/simple'
import { getNotifications } from '../../redux/actions/common'
import { decideFollower } from '../../request'
import { message } from 'antd'

const Notification = props => {
    const { common: { userInfo }, brand } = props
    const [notificationList, setNotificationList] = useState([])
    const [myNotificationList, setMyNotificationList] = useState([])

    const getMyNotifications = () => {
        const params = {
            seen: true
        }
        props.getNotifications(params)
            .then(result => {
                setNotificationList(result)
                const dataTemp = result.filter(item => {
                    if (item.status === 'connected'||  (item.status === 'pending' && (item.created_by !== userInfo.business_type && (item.created_by === 'agency' && userInfo.business_type === 'media')))) {
                        return true
                    } else if (((item.created_by === 'media' || item.created_by === 'brand') && (item.status === 'pending' && item.created_by !== userInfo.business_type)) || item.status === 'connected'){
                        return true
                    } else return false
                })
                setMyNotificationList(dataTemp)
            })
            .catch(err => {
                console.log(err)
                setNotificationList([])
                setMyNotificationList([])
            })
    }
    const messageTest = (created_by) => {
        if ((created_by === userInfo.business_type || (created_by ==='agency' && userInfo.business_type === 'brand'))) {
            return 'SUCCESSFULLY JOINED!' 
        } else {
            return 'WANTS TO CONNECT WITH YOU!' 
        }
    }

    const decideStatus = (notification, status) => {
        decideFollower(notification.id, status)
            .then(res => {
                getMyNotifications()
            })
    }

    const showAction = (notification) => {
        if (notification.created_by === userInfo.business_type || (notification.created_by ==='agency' && userInfo.business_type === 'brand')) {
            
            return ''
        } else if (notification.created_by !== userInfo.business_type) {
            
            return (
                <div className="info2">
                        {
                            notification.status === 'connected' ? (
                                <div className="info-connect accepted">
                                    Accepted
                                    <i className="icon-right"></i>
                                </div>
                            ) : notification.status === 'pending' ? (
                                    <>
                                        <div className="info-connect" onClick={() => decideStatus(notification, 'Ignored')}>Ignore</div>
                                        <div className="info-connect" onClick={() => decideStatus(notification, 'Connected')}>Accept</div>
                                    </>
                                ) : (<>

                                </>)
                        }
                    </div>
            )
        }
    }

    useEffect(() => {
        getMyNotifications()
    }, [])

    const getNotificationPartial = () => {
        return myNotificationList.map((notification, index) => (
            <div className="notification" key={index}>
                <div className="notification-avatar">
                    <img src={notification.avatar ? notification.avatar : images.avatar_default}></img>
                </div>
                <div className="notification-info">
                    <div className="info1">
                        <div className="info-name">
                            {notification.name}
                        </div>
                        
                        <div className="info-desc">
                            {
                                messageTest(notification.created_by)
                            }
                        </div>
                    </div>
                    {
                        showAction(notification)
                    }
                </div>
            </div>
        ))
    }

    return <LayoutSimple>
        <div className="notification-wraper">
            <div className="info">
                <div className="info-name">
                    Notification Center
                </div>
                <div className="info-desc">{myNotificationList.length} Notifications</div>
            </div>
            <div className="notifications">
                {
                    getNotificationPartial()
                }
            </div>
        </div>
    </LayoutSimple>
}

const mapStateToProps = (state) => {

    return {
        common: state.common,
    }
}
const mapDispatchToProps = {
    getNotifications
}


export default connect(mapStateToProps, mapDispatchToProps)(Notification)
