import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import './style.scss';

import { getNotifications } from '../../redux/actions/common';
import { images } from '../../utility/const';
import { useIntervel } from './useIntervel';


const Simple = (props) => {
  const { children } = props;
  const [notificationList, setNotificationList] = useState([]);
  const history = useHistory();
  const {
    common: { userInfo },
    style = {}
  } = props;

  let oldTotal = 0;
  let totalNoti = 0;
  const initData = () => {
    props
      .getNotifications()
      .then((result) => {
        setNotificationList(result);
        countNoti();
      })
      .catch((err) => {
        setNotificationList([]);
      });
  };

  useIntervel(async () => {
    await initData();
  });

  const countNoti = () => {
    if (notificationList.length === 0) {
      return 0;
    } else if (notificationList.length > 0) {
      notificationList.reduce((total, currentValue) => {
        if (
          !currentValue.seen &&
          (currentValue.status === 'connected' ||
            (currentValue.status === 'pending' &&
              currentValue.created_by !== userInfo.business_type))
        ) {
          totalNoti++;
          if (totalNoti >= oldTotal) {
            oldTotal = totalNoti;
          }
        }
      }, 0);
    }
    return oldTotal;
  };
  // back
  const goBack = () => {
    history.goBack();
  };

  const backHome = () => {
    // if (userInfo.business_type === 'media') {
    //   history.push(`/mediaWorks`);
    // } else if (userInfo.business_type === 'brand') {
    //   history.push(`/brandCollections`);
    // } else {
    //   history.push(`/agentBrands?type=allClientBrands`);
    // }

    history.push(`/`);
  };

  // goNotification
  const goNotification = () => {
    history.push('/notification');
  };

  return (
    <div className="simple-frame" style={style}>
      <div className="simple-head">
        <div className="logo-asb" onClick={backHome}>
          <img className="img-logo" alt="" src={images.home.logo_asb} />
        </div>
        <div className="menu-back cursor-pointer" onClick={goBack}>
          <i className="icon-back"></i>Back
        </div>
        <div className="head-right">
          <div className="notification" onClick={goNotification}>
            <span className="notification-count">{countNoti()}</span>
          </div>
        </div>
      </div>

      <div className="simple-content">{children}</div>
    </div>
  );
}


const mapStateToProps = (state) => {

  return {
    common: state.common,
  }
}
const mapDispatchToProps = {
  getNotifications,
};

export default connect(mapStateToProps, mapDispatchToProps)(Simple)
