import { Upload } from 'antd';
import React, { memo, useEffect, useState } from 'react';
import { images } from '../../utility/const';
import './index.scss';


function CustomAvatar(props){
  const {onChange,beforeUpload,action,headers,data,defaultImage="" } = props;
  const [imageUrl,setImageUrl] = useState(defaultImage)
  const [loading,setLoading] = useState(false);
    const uploadButton = (
      <div className="upload-button">
        {loading ? <div>uploading</div> : <div />}
        <img className="default" src={images.default_avatar}>
        </img>
        <img className="camera" src={images.camera}></img>
      </div>
    );

    const previewImage =  (
      imageUrl ? <div className="upload-button"><img src={imageUrl} alt="avatar"  style={{ width: '100%',height: '100%',border: '1.5px solid #E5E5E5',borderRadius: '50%',objectFit: 'cover', }} /></div> : uploadButton
    )

    const handleChange = (info) =>{
      onChange && onChange(info)

      if(info.file.status === 'uploading'){
        setLoading(true)
      }

      if(info.file.status === 'done'){
        setLoading(false)
        setImageUrl(info.file.response.avatar)
      }
    }

    useEffect(()=>{
      setImageUrl(defaultImage)
    },[defaultImage])

  return <Upload
        className="custom-upload"
        name="avatar"
        listType="picture-card"
        showUploadList={false}
        action={action}
        beforeUpload={beforeUpload}
        onChange={handleChange}
        headers={headers}
        data={data}
      >
        {previewImage}
      </Upload>
}


export default memo(CustomAvatar)