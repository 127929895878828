import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Form, Input, message, Row, Select, Space, Typography } from 'antd';
import React,{ useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import LayoutSimple from '../../components/Layout/simple';
import {
  addBrand, addUser,
  getAllBusinesses,
  getRoles
} from "../../redux/actions/common";
import './style.scss';

import {showUser,updateUser} from '../../request'

const EditUser = (props) => {
    const params = useParams();
    const location = useLocation();
    const history=props.history
    const [user,setUser] = useState({
      data_rights: 1
    })
    const [edited,setEdited] = useState(['new','edit'].includes(params.type))

    const [form] = Form.useForm();
    const [businesses, setBusinesses] = useState([]);
    const [roles, setRoles] = useState([]);
    const [brand,setBrand] = useState({});
    const [visible, setVisible] = useState(false);
    const [open,setOpen] = useState(false);
    let lock = null;
    useEffect(()=>{
      if(params.type === 'edit' && location.state && location.state.id){
        showUser({id: location.state.id}).then(res=>{
          setUser(res)
        })
      }
    },[location.state])


    useEffect(() => {
        fetchAllBusinesses();
         props.getRoles().then((res) => {
           setRoles(res.data);
         });
    }, [])

    useEffect(()=>{
      if(user.id){
        form.setFieldsValue({...user})
      }
    },[user,form])


    const fetchAllBusinesses=()=>{
        props.getAllBusinesses().then((res) => {
            console.log(res)
          setBusinesses(res);
        });
    }

    const saveUser = async () =>{
        await form.validateFields();
        const business_id = form.getFieldValue('business_id');
        const business = businesses.find(
          (row) => (row.business_user_mapping_id === business_id)
        );
        const data = {
          user: {
            ...user,
            ...form.getFieldValue(),
            user_type: "user",
            business_type: business.business_type,
            business_name: business.name,
            role: business.business_type,
          },
        };

        console.log(user, form.getFieldValue(), data);
        switch(params.type){
            case 'new':
                props.addUser(data).then(() => {
                            history.push(`/users`);
                            message.success({ content:"Create success new account" })
                        })
            break;
            case 'edit':
                console.log(data)
                updateUser(data).then(() => {
                  history.push(`/users`);
                  message.success({
                    content: "Updated success  account",
                  });
                });
            break;
            default:
                console.log('nothing to do')
        }

        
    }

    const renderButtons = () => {
        return  <div>
                        <Button shape="round" style={{borderColor: '#68C89E',color: '#68C89E',right: 15}} onClick={saveUser}>Save</Button> 
                        <Button type="default" style={{borderColor: '#595959',color: '#595959',}} shape="round" onClick={()=>{
                             history.push(`/users`);
                        }}>Cancel</Button>
                    </div>
    }   
    const statusMap = { editing: 'Editing', opened: 'Opened', closed: 'Closed' }

    const lockClose = e => {
    clearTimeout(lock);
    lock = setTimeout(() => {
      lock = null;
    }, 100);
  };

  const  onDropdownVisibleChange = open => {
    if (lock) return;
    console.log(">>>", open);
    setOpen(open);
  };
    
    return (
      <LayoutSimple>
        <div className="banners-body">
          <Form
            form={form}
            layout="vertical"
            noStyle
            initialValues={{ ...user }}
          >
            <div className={`main-content`} id="main-content">
              <div className="basic item-relative" id="basic">
                <div className="title">Account Info</div>
                <div className="form-items">
                  <Row gutter={[16, 0]}>
                    <Col span={10}>
                      <Form.Item
                        label="User Name"
                        name="username"
                        rules={[
                          {
                            required: true,
                            message: "Please input your username!",
                          },
                        ]}
                      >
                        <Input placeholder="Please input your username"></Input>
                      </Form.Item>
                    </Col>
                    <Col span={10} offset={2}>
                      <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: "Please input your email!",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Please input your email"
                          type="email"
                          autoComplete="off"
                        ></Input>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[16, 0]}>
                    <Col span={10}>
                      <Form.Item
                        label="Password"
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: "Please input your password!",
                          },
                        ]}
                      >
                        <Input.Password
                          placeholder="Please input your password"
                          type="password"
                          autoComplete="new-password"
                        ></Input.Password>
                      </Form.Item>
                    </Col>
                    <Col span={10} offset={2}>
                      <Form.Item
                        label="Account"
                        name="business_id"
                        rules={[
                          {
                            required: true,
                            message: "Please select your account!",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Please select your account"
                          style={{ width: "100%" }}
                          showSearch
                          open={open}
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          onDropdownVisibleChange={onDropdownVisibleChange}
                          dropdownRender={(menu) => (
                            <>
                              <Space
                                align="center"
                                style={{ padding: "0 8px 4px" }}
                              >
                                <Form
                                  noStyle
                                  layout="inline"
                                  onValuesChange={(values, allValues) => {
                                    setBrand(allValues);
                                  }}
                                  onFinish={(e) => {
                                    console.log();
                                    props
                                      .addBrand({
                                        ...e,
                                        name: form.getFieldValue("username"),
                                      })
                                      .then((res) => {
                                        setBrand({});
                                        fetchAllBusinesses();
                                      });
                                  }}
                                >
                                  <Form.Item
                                    name="type"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Please select your type!",
                                      },
                                      ({ getFieldValue }) => ({
                                        validator(_, value) {
                                          console.log(
                                            value,
                                            form.getFieldValue("username")
                                          );
                                          if (
                                            value &&
                                            form.getFieldValue("username")
                                          ) {
                                            return Promise.resolve();
                                          }
                                          return Promise.reject(
                                            new Error(
                                              "User Name cannot be mepty!"
                                            )
                                          );
                                        },
                                      }),
                                    ]}
                                  >
                                    <Select
                                      placeholder="Please select your type"
                                      onMouseDown={lockClose}
                                      onMouseUp={lockClose}
                                    >
                                      {roles
                                        .filter(
                                          (role) =>
                                            !role.name.startsWith("admin") &&
                                            !role.name.startsWith("wechat")
                                        )
                                        .map((role) => (
                                          <Select.Option
                                            key={role.id}
                                            value={role.name}
                                          >
                                            {role.name}
                                          </Select.Option>
                                        ))}
                                    </Select>
                                  </Form.Item>
                                  <Form.Item>
                                    <Button
                                      type="link"
                                      icon={<PlusOutlined />}
                                      htmlType="submit"
                                    >
                                      Add Account
                                    </Button>
                                  </Form.Item>
                                </Form>
                              </Space>
                              <Divider style={{ margin: "8px 0" }} />
                              {menu}
                            </>
                          )}
                        >
                          {businesses.map((business) => (
                            <Select.Option
                              key={business.business_user_mapping_id}
                              value={business.business_user_mapping_id}
                            >
                              {business.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>

                  {/* <Form.Item label="Data Rights" name="data_rights">
                    <Radio.Group
                      buttonStyle="solid"
                      style={{ borderColor: "#595959", color: "#595959" }}
                    >
                      <Radio.Button value={1}>Brand</Radio.Button>
                      <Radio.Button value={2}>Self</Radio.Button>
                    </Radio.Group>
                  </Form.Item> */}
                  <Form.Item>{renderButtons()}</Form.Item>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </LayoutSimple>
    );
}

const mapStateToProps = (state) => {
    return {
        common: state.common
    }
}

const mapActionsToProps = {
  addUser,
  getAllBusinesses,
  getRoles,
  addBrand,
};

export default connect(mapStateToProps, mapActionsToProps)(EditUser)