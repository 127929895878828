import React, { useState } from 'react'
import './style.scss'
import { connect } from 'react-redux'
import Modal from '../../components/Modal'
import { createInvite } from '../../request/'
import { message } from 'antd'

const InviteButton = props => {
    const {children, type}=props
    const [showModal, setShowModal]=useState(false)

    const [name, setName]=useState('')
    const [email, setEmail]=useState('')
    const [messageInvite, setMessage]=useState('')
    const { common: { userInfo} } = props
    const invite=()=>{
        const business_user_mapping_id = userInfo.business_id
        const created_by = userInfo.business_type
        let business_type = '';
        if (userInfo.business_type === 'media'){
            business_type = 'brand';
        } else{
            business_type =  'media';
        }
        if (name){
            if(email){
                if(messageInvite){
                    createInvite({email, name, business_type, messageInvite, business_user_mapping_id, created_by})
                    .then(res=>{
                        if(!res.status){
                            message.success(' The invitation has been sent successfully !')
                            setShowModal(false)
                            setName('')
                            setEmail('')
                            setMessage('')
                        }else{
                            message.error(' Another user with this email already exists !')
                        }
                    })
                    .catch(err=>{
                        console.log(err)
                    }) 
                }else{
                    userInfo.business_type === 'brand' ?  message.warning('Please input Media Message') : message.warning('Please input Brand Message')
                }
            }else{
                userInfo.business_type === 'brand' ?  message.warning("Please input Media Email") : message.warning("Please input Brand Email")
            }
        }else{
            userInfo.business_type === 'brand' ?  message.warning("Please input Media Name") : message.warning("Please input Brand Name")
        }
       
    }

    const onChange=(event)=>{
        const field=event.target.name
        const value=event.target.value

        switch(field){
            case 'name':setName(value); break;
            case 'email':setEmail(value); break;
            case 'messageInvite':setMessage(value); break;
        }
    }

    return <>
        <div className="invite-button" onClick={()=>setShowModal(true)}>
            <i className="icon-add"></i>{children}
        </div>
        {
            showModal?
            <Modal title={type=='brand'?"Invite Brand":"Invite Media"} onClose={()=>setShowModal(false)}>
                <div className="form-wraper">
                    <div className="form-input">
                        <input className="input-text" 
                            onChange={onChange}
                            name="name"
                            value={name}
                            type="text" 
                            placeholder={type=='brand'?"Brand Name":'Media Name'} />
                        <span className="input-required">*</span>
                    </div>
                    <div className="form-input">
                        <input className="input-text" 
                            onChange={onChange}
                            name="email"
                            value={email}
                            type="text" 
                            placeholder={type=='brand'?"Brand Work Email":"Media Work Email"} />
                        <span className="input-required">*</span>
                    </div>
                    <div className="form-textarea">
                        <textarea className="input-text" 
                            onChange={onChange}
                            name="messageInvite"
                            value={messageInvite}
                            placeholder="Message" />
                        <span className="input-required">*</span>
                    </div>
                </div>
                <div className="invite-modal-btn" onClick={invite}>Invite</div>
            </Modal>:''
        }
    </>
}

const mapStateToProps = (state) => {

    return {
        common: state.common,
        brand: state.brand
    }
}

export default connect(mapStateToProps, null)(InviteButton)
